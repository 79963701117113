import { useLazyQuery } from "@apollo/client";
import {
  Alert,
  Box,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import BigNumber from "bignumber.js";
import { useContext, useEffect, useMemo, useState } from "react";
import { api, BaseTransactionFullFields } from "src/api";
import {
  AssetTypeEnum,
  GetEntryLinksResponse,
  IsDirtyEnum,
  LedgerTransactionReviewStatusEnum,
  QueryGetEntryLinksArgs,
} from "src/api/generated/types";
import { ActiveTransactionContext } from "src/context";
import { Maybe } from "src/core";
import { colors } from "src/theme";
import { D } from "src/utils/helpers";
import { EditTransferPopover } from "../Transfers/EditTransferPopover";
import {
  TransferBreakdown,
  TransferWithExtraInfo,
} from "../Transfers/TransferForm/utils";
import { LinksTable } from "./EntryLinksTable";
import { BaseFullTransactionFields } from "src/api/fragments";
import { truncate } from "lodash";
import StatusTag from "src/components/styled/StatusTag";
import { Currency } from "dinero.js";
import { useTheme } from "src/hooks/useTheme";

type InternalTransferLinksProps = {
  assetCreditId: Maybe<string>;
  transfer: Maybe<TransferWithExtraInfo>;
  transaction: Maybe<BaseFullTransactionFields>;
  timezone: string;
};

export const InternalTransferLinks = ({
  assetCreditId,
  transfer,
  transaction,
  timezone,
}: InternalTransferLinksProps) => {
  const [isOpen, setOpen] = useState(false);
  const _onOpen = () => setOpen(true);
  const { transfers } = useContext(ActiveTransactionContext);
  const symbol = transfer?.fullAsset?.symbol;

  // === api stuff === //
  const [fetchLinks, { data }] = useLazyQuery<
    { getEntryLinks: GetEntryLinksResponse },
    QueryGetEntryLinksArgs
  >(api.ledgerEntries.links, {
    // Note: we want this so it doesn't override ledger entry links or other elements of the cache which causes issues
    fetchPolicy: "no-cache",
  });

  // get the links for the asset credit
  useEffect(() => {
    if (!assetCreditId) {
      return;
    }

    void fetchLinks({
      variables: { ledgerEntryId: assetCreditId },
    });
  }, [assetCreditId]);

  const links = data?.getEntryLinks?.fromLinks || [];
  const amount = useMemo(
    () =>
      links.reduce(
        (a, l) => a.plus(new BigNumber(l.cryptoAmountUsed || 0)),
        new BigNumber(0)
      ),
    [links]
  );

  const transferredValue = useMemo(
    () =>
      links.reduce(
        (a, l) => a.plus(new BigNumber(l.fiatAmountUsed || 0)),
        new BigNumber(0)
      ),
    [links]
  );

  const isMissingBasis = transfer?.isMissingBasis;
  const theme = useTheme();

  // don't show if no links
  if (!links.length) {
    return null;
  }

  return (
    <Box padding="0.25rem 0">
      <Popover
        trigger="hover"
        onOpen={_onOpen}
        onClose={() => setOpen(false)}
        isOpen={isOpen}
        placement="bottom"
      >
        <PopoverTrigger>
          <HStack margin="0.25rem 0">
            <Text cursor="default" fontSize="sm" color={theme.header}>
              Transferred:{" "}
              {D(
                transferredValue.toNumber(),
                (transaction?.fiatCurrency || "USD") as Currency
              ).toFormat()}{" "}
            </Text>
            <StatusTag
              type="none"
              boxProps={{
                cursor: "pointer",
                style: {
                  padding: "0.25rem 0.5rem",
                },
              }}
              // infoMessage="How did we calculate this?"
              iconName="fa-sharp fa-info-circle"
              labelStyle={{
                fontSize: 10,
              }}
            />
            {isMissingBasis && (
              <StatusTag
                boxProps={{
                  onTouchMove: (e) => e.stopPropagation(),
                }}
                // label="Missing Cost Basis"
                infoMessage="This usually means Awaken is missing previous transactions. If you uploaded a spreadsheet, you may need to upload more past data so Awaken can properly carry over cost basis."
                type="error"
                iconName="fa-sharp fa-exclamation-triangle"
              />
            )}
          </HStack>
        </PopoverTrigger>
        <PopoverContent
          width="800px" // if change this change entry links table it is hardcoded atm
          maxHeight="400px"
          left="5px"
          overflowY="scroll"
          bg={theme.background}
          zIndex={1000}
          border={`1px solid ${theme.border} !important`}
        >
          <Box>
            <HStack padding="0.75rem 1rem" alignItems="center">
              <Flex display="flex" flexDir="column" flex={1}>
                <Text color={theme.header} fontSize="md" fontWeight="semibold">
                  How we calculated this 🕵️‍♂️
                </Text>
                <Text color={theme.text} fontSize="xs" fontWeight="normal">
                  There are {links.length} linked references
                </Text>
              </Flex>
              <VStack alignItems="flex-end">
                <Text
                  color={theme.header}
                  margin="0 !important"
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Total:{" "}
                  {D(
                    transferredValue.toNumber(),
                    (transaction?.fiatCurrency || "USD") as Currency
                  ).toFormat()}
                </Text>
                <Text
                  color={theme.header}
                  fontSize="xs"
                  margin="0 !important"
                  fontWeight="normal"
                >
                  {amount.toFormat()}{" "}
                  {truncate(symbol || "", { length: 5, omission: ".." })}
                </Text>
              </VStack>
            </HStack>
            <LinksTable
              timezone={timezone}
              links={links}
              assetCreditId={assetCreditId}
            />
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
