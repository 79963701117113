import { truncate } from "lodash";
import { BaseAssetFields } from "src/api/fragments";
import { AssetTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";
import truncateMiddle from "truncate-middle";

export const isUnnamedAsset = (asset: BaseAssetFields) => {
  const lower = asset?.name?.toLowerCase();
  return !!asset && (lower === "nft" || lower === "token");
};

export const _getAssetSymbolOrName = (asset: BaseAssetFields) => {
  if (isUnnamedAsset(asset)) {
    return `${asset?.name} (${asset?.contractAddress || ""})`;
  }

  return (
    (asset?.type === AssetTypeEnum.Nft ? asset?.name : asset?.symbol || "") ||
    ""
  );
};

export const getAssetSymbolOrName = (
  asset: Maybe<BaseAssetFields>,
  truncateLength?: number,
  shouldTruncateMiddle?: boolean
): string => {
  if (!asset) return "";
  const symbolOrName = _getAssetSymbolOrName(asset);

  if (truncateLength) {
    if (shouldTruncateMiddle) {
      return truncateMiddle(
        symbolOrName,
        truncateLength,
        truncateLength,
        "..."
      );
    }

    return truncate(symbolOrName, { length: truncateLength });
  }
  return symbolOrName;
};
