import { BaseAssetFields, BaseFullTransactionFields } from "src/api/fragments";
import { AccountProviderEnum, AssetTypeEnum } from "src/api/generated/types";
import { hasValue, Maybe } from "src/core";

const baseIPFSUrl = "https://ipfs.io";
const isImageUrl = (url: string): boolean =>
  /\.(jpeg|jpg|gif|png|svg|webp)$/i.test(url);
const isVideoUrl = (url: string): boolean => /\.(mp4|webm|ogg)$/i.test(url);

export const getAssetUrls = (txn: BaseFullTransactionFields) => {
  const urls = txn.assets.map((a) => a.imageUri || a.logoUri);

  return urls.filter(hasValue).map((u) => {
    if (u.slice(0, 7) === "ipfs://") {
      return `${baseIPFSUrl}/ipfs/` + urls.slice(7);
    }

    return u;
  });
};

const _mightBeSpam = (url: string) =>
  url.includes(".gift") || url.includes(".tech");

export const getAssetUrl = (
  asset?: Maybe<
    Pick<
      BaseAssetFields,
      | "imageUrl"
      | "iconImageUrl"
      | "symbol"
      | "type"
      | "contractAddress"
      | "coinGeckoTokenId"
    >
  >
): Maybe<string> => {
  if (!asset) {
    return null;
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "usd"
  ) {
    return "https://assets.awaken.tax/icons/usa.png";
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "eur"
  ) {
    return "https://assets.awaken.tax/icons/euro.png";
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "cad"
  ) {
    return "https://assets.awaken.tax/icons/cad.jpeg";
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "aud"
  ) {
    return "https://assets.awaken.tax/icons/aud.png";
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "gbp"
  ) {
    return "https://assets.awaken.tax/icons/gbp.png";
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "inr"
  ) {
    return "https://assets.awaken.tax/icons/inr.svg";
  }

  if (
    asset.type === AssetTypeEnum.FiatCurrency &&
    asset.symbol?.toLowerCase() === "jpy"
  ) {
    return "https://assets.awaken.tax/icons/jpy.svg";
  }

  const url = asset.imageUrl || asset.iconImageUrl;

  if (!url) {
    // if no url but is a fungible token with a symbol, return a url to our s3 bucket. note: it might not exist
    // (it is the job of outside code to handle images that don't exist and render nothing)
    // also make sure not a contract address (aka it isn't on a blockchain where anyone can name anything anything)
    if (
      // if the token is fungible and it has a coin gecko or it just doesn't have a contract address, return the image
      // no contract address covers the exchange case (so we render the image), and coingecko covers the case
      // where we don't have an image for a token but do have pricing data -> so it is pretty reliable we can use the symbol to look it up
      // given we are careful in assigning coingecko by contract address
      asset.type === AssetTypeEnum.FungibleToken &&
      asset.symbol &&
      (!asset.contractAddress || !!asset.coinGeckoTokenId)
    ) {
      // first part (remove dollar) bc for kraken and some others we have decimals in the name
      const [symbol, _other] = asset.symbol.split(".");

      return `https://assets.awaken.tax/icons/crypto-png/${symbol.toLowerCase()}.png`;
    }

    return null;
  }

  if (url.slice(0, 7) === "ipfs://") {
    return `${baseIPFSUrl}/ipfs/` + url.slice(7);
  }

  // don't render for things that might be spam
  if (_mightBeSpam(url)) {
    return null;
  }

  // if (!isImageUrl(url) && !isVideoUrl(url)) {
  //   return null;
  // }

  return url;
};

export const getOpenSeaUrl = (
  chain: string, // WARNING: idk the enum for this
  contractAddress: Maybe<string>,
  tokenId: Maybe<string>
): string => {
  if (!contractAddress || !tokenId) return "";
  return `https://opensea.io/assets/${chain}/${contractAddress}/${tokenId}`;
};

export const getMagicEdenUrl = (mint: Maybe<string>): string => {
  if (!mint) return "";
  return `https://magiceden.io/item-details/${mint}`;
};

export const getAssetOnMarketplaceOrCoingecko = (
  asset: Maybe<BaseAssetFields>
) => {
  if (!asset) return null;

  if (asset?.coinGeckoTokenId) {
    return {
      url: `https://www.coingecko.com/en/coins/${asset?.coinGeckoTokenId}`,
      type: "token",
      name: "Coingecko",
      imageUrl: "https://assets.awaken.tax/icons/coingecko.png",
    };
  }

  const isNFT = asset?.type === AssetTypeEnum.Nft;

  if (isNFT) {
    const isEth = asset.provider === "ethereum";
    const isPoly = asset.provider === "polygon";
    const isSol = asset.provider === "solana";
    const isBTC = asset.provider === "bitcoin";

    if (isEth) {
      const url = getOpenSeaUrl(
        "ethereum",
        asset.contractAddress || "",
        asset.tokenId || ""
      );

      return {
        url: url,
        type: "nft",
        name: "OpenSea",
        imageUrl: "https://assets.awaken.tax/icons/opensea.png",
      };
    }

    if (isPoly) {
      const url = getOpenSeaUrl(
        "matic",
        asset.contractAddress || "",
        asset.tokenId || ""
      );

      return {
        url: url,
        type: "nft",
        name: "OpenSea",
        imageUrl: "https://assets.awaken.tax/icons/opensea.png",
      };
    }

    if (isSol && asset.contractAddress) {
      const url = getMagicEdenUrl(asset.contractAddress);

      return {
        url: url,
        type: "nft",
        name: "Magic Eden",
        imageUrl: "https://assets.awaken.tax/icons/magiceden.png",
      };
    }

    // ordinals we show on magic eden
    if (isBTC && asset.contractAddress) {
      const url = `https://ordinalswallet.com/inscription/${asset.contractAddress}`;

      return {
        url: url,
        type: "nft",
        name: "Ordinals",
        imageUrl: "https://assets.awaken.tax/icons/ordinals.png",
      };
    }

    return null;
  }

  return null;
};
