import {
  Box,
  Container,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import { colors, other } from "src/theme";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { compose } from "lodash/fp";
import { setDrawerIsOpen } from "src/redux/reducers/active";
import { useTheme } from "src/hooks/useTheme";

export const NavigationLink = ({
  label,
  iconName,
  link,
  links,
  notification,
  notificationLink,
  notificationMessage,
  extraComponent,
  onClick,
  isActive: _isActive,
}: {
  label: string | JSX.Element;
  links?: string[];
  iconName: string;
  link?: string;
  tag?: string | JSX.Element;
  notification?: number | null;
  notificationLink?: string;
  notificationMessage?: string | JSX.Element;
  onClick?: () => void;
  extraComponent?: JSX.Element;
  isActive?: boolean;
}) => {
  const location = useLocation();
  const dipatch = useDispatch();
  const setDrawer = compose(dipatch, setDrawerIsOpen);
  const { background, border, secondaryBackground, header } = useTheme();

  const _setClosed = () => {
    setTimeout(() => {
      setDrawer(false);
      if (onClick) onClick();
    }, 250);
  };
  const isActive =
    _isActive ||
    (links ? links.includes(location.pathname) : location.pathname === link);

  return (
    <Link to={link || "#"} onClick={_setClosed}>
      <NavigationContainer
        margin="0"
        boxSizing="border-box"
        width="auto !important"
        _hover={{
          bg: secondaryBackground,
        }}
        bg={isActive ? secondaryBackground : undefined}
      >
        <HStack alignItems="center" h="2rem">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            textAlign="center"
            color={header}
          >
            <i className={iconName}></i>
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color={header} fontWeight="medium" fontSize="sm">
              {label}
            </Text>
            {extraComponent}
          </Box>
          {notification ? (
            <Link to={notificationLink || link || "#"}>
              <Popover trigger={isMobile ? "click" : "hover"} placement="top">
                <PopoverTrigger>
                  <Box
                    width="1.3rem"
                    height="1.3rem"
                    borderRadius="50%"
                    bgColor={colors.red}
                    display="flex"
                    marginRight="5px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text
                      fontSize={10}
                      textAlign="center"
                      color={colors.white}
                      fontWeight="bold"
                    >
                      {notification}
                    </Text>
                  </Box>
                </PopoverTrigger>
                <PopoverContent
                  bg={background}
                  borderRadius={5}
                  border={`1px solid ${border} !important`}
                  boxShadow={other.boxShadow}
                >
                  <PopoverArrow />
                  <Box bg={background} padding="1rem">
                    <Text color={header} fontSize="sm" whiteSpace="initial">
                      {notificationMessage}
                    </Text>
                  </Box>
                </PopoverContent>
              </Popover>
            </Link>
          ) : (
            <div />
          )}
        </HStack>
      </NavigationContainer>
    </Link>
  );
};

const NavigationContainer = styled(Container)`
  cursor: pointer;
  padding: 0.4rem 0.5rem;
  width: 100%;
`;
