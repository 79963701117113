import {
  Box,
  Container,
  Heading,
  Text,
  ListItem,
  OrderedList,
  UnorderedList,
  BoxProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { NavBarPageTemplate } from "src/components";
import { colors } from "src/theme";

function LegalContainer({
  children,
  ...props
}: BoxProps & { children: ReactNode }) {
  return (
    <Container marginLeft="0" paddingLeft="0" marginBottom="1rem" {...props}>
      {children}
    </Container>
  );
}

function Terms() {
  return (
    <NavBarPageTemplate bgColor={colors.white}>
      <Box w="96%" maxW="50rem" margin="4rem auto">
        <Heading size="xl">Terms of Service</Heading>
        <LegalContainer>
          <i>Last modified: October 29th, 2022</i>
        </LegalContainer>
        <LegalContainer>
          These Terms of Service (these “Terms”) of Accrue Finance Inc. (dba
          Awaken) (“Awaken,” “we,” or “us”) describe your rights and
          responsibilities when accessing and/or using our website, including
          www.awaken.tax and any successor URL(s), and any and all related
          software, documentation, and online, mobile-enabled, and/or digital
          services provided by Awaken (collectively, the “Service”). By
          accessing or using the Service, or by clicking a button or checking a
          box marked “I Agree” (or something similar), you signify that you have
          read, understood, and agree to be bound by these Terms, and you
          acknowledge that you have read and understood our Privacy Policy[,
          available at www.awaken.tax/legal/privacy] (as it may be updated from
          time to time, our “Privacy Policy”). Awaken reserves the right to
          modify these terms and will provide notice of material changes as
          described below. These Terms apply to all visitors, users, and others
          who access the Service (“Users”).
        </LegalContainer>
        <LegalContainer>
          PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH
          PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION
          PROVISION IN SECTION 14.2 (THE “ARBITRATION AGREEMENT”) AND CLASS
          ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 14.3 (THE “CLASS
          ACTION/JURY TRIAL WAIVER”) THAT REQUIRE, UNLESS YOU OPT OUT PURSUANT
          TO THE INSTRUCTIONS IN SECTION 14.2, THE EXCLUSIVE USE OF FINAL AND
          BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES BETWEEN
          YOU AND US, INCLUDING ANY CLAIMS THAT AROSE OR WERE ASSERTED BEFORE
          YOU AGREED TO THESE TERMS. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU
          EXPRESSLY WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO
          HAVE A JURY TRIAL ON YOUR CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE
          AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS, COLLECTIVE, PRIVATE
          ATTORNEY GENERAL OR REPRESENTATIVE ACTION OR PROCEEDING.
        </LegalContainer>
        <Heading size="lg">1. Our Service</Heading>
        <Heading size="sm">1.1 Eligibility</Heading>
        <LegalContainer>
          This is a contract between you and Awaken. You must read and agree to
          these terms before using the Awaken Service. If you do not agree, you
          may not use the Service. You may use the Service only if you have a
          valid email address, are eighteen (18) years of age or older, and can
          form a binding contract with Awaken, and only in compliance with these
          Terms and all applicable local, state, national, and international
          laws, rules and regulations (“Applicable Law”). The Service is not
          available to any Users previously removed from the Service by Awaken.
        </LegalContainer>
        <LegalContainer>
          Without limiting the generality of the foregoing, any access to, or
          use of, the Service by non-U.S. residents is strictly prohibited and
          in violation of these Terms. Awaken makes no representations that the
          Service is appropriate or available for use in other locations. By
          using the Service, you represent that you are a U.S. resident.
        </LegalContainer>
        <Heading size="sm">1.2 Limited License</Heading>
        <LegalContainer>
          Subject to the terms and conditions of these Terms, you are hereby
          granted a non-exclusive, limited, non-transferable, and freely
          revocable license to use the Service for your personal, noncommercial
          use only, strictly as permitted by the features of the Service. Awaken
          may terminate this license at any time for any reason or no reason.
          Awaken reserves all rights not expressly granted herein in the Service
          and the Awaken Content (as defined below).
        </LegalContainer>
        <Heading size="sm">1.3 User Accounts</Heading>
        <LegalContainer>
          Your account on the Service (“User Account”) gives you access to
          certain services and functionalities that we may establish and
          maintain as part of the Service from time to time, in our sole
          discretion. We may maintain different types of User Accounts for
          different types of Users. If you open a User Account on behalf of a
          company, organization, or other entity, then (a) “you” includes you
          and that entity, and (b) you represent and warrant that you are an
          authorized representative of that entity with the authority to bind
          that entity to these Terms, and that you agree to these Terms on the
          entity’s behalf. By connecting to the Service with a third-party
          service, you give us permission to access and use your information
          from that service as permitted by that service, and to store your
          log-in credentials for that service.
        </LegalContainer>
        <LegalContainer>
          You may never use another User’s User Account without permission. When
          creating your User Account, you must provide accurate and complete
          information, and you must keep this information up to date. You are
          solely responsible for the activity that occurs on your User Account,
          and you must keep your User Account password secure. You must notify
          Awaken immediately of any breach of security or unauthorized use of
          your User Account. Awaken will not be liable for, and expressly
          disclaims any responsibility for, any losses caused by any
          unauthorized use of your User Account.
        </LegalContainer>
        <LegalContainer>
          By providing Awaken your email address, you consent to our using that
          email address to send you Service-related notices, including any
          notices required by Applicable Law, in lieu of communication by postal
          mail. We may also use your email address to send you other messages,
          such as messages notifying you of changes to features of the Service
          and special offers. If you do not want to receive such email messages,
          you may opt out of receiving such email messages by clicking on the
          “unsubscribe” link in the email message received and/or change your
          preferences in your settings page. Opting out may prevent you from
          receiving email messages regarding updates, improvements, or offers.
        </LegalContainer>
        <LegalContainer>
          By creating a User Account and providing Awaken your mobile phone
          number, you consent to receive auto-dialed and/or pre-recorded calls
          and text messages from us at the mobile phone number provided by you
          to us. We may place such calls or texts to (i) help keep your User
          Account secure through the use of two-factor authentication; (ii) help
          you access your User Account; and/or (iii) as otherwise necessary to
          service your User Account and/or to enforce these Terms, our policies,
          Applicable Law, and/or any other agreement we may have with you.
        </LegalContainer>
        <Heading size="sm">1.4 Service Rules</Heading>
        <LegalContainer>
          You agree not to engage in any of the following prohibited activities:
          (a) engaging in the preparation and/or filing of any false or
          fraudulent tax documentation or for any other improper purpose,
          whether for yourself, your business, or a third party; (b) copying,
          distributing, making available, providing access to, or disclosing any
          part of the Service in any medium, including, without limitation, by
          any automated or non-automated “scraping”; (c) using any automated
          system, including, without limitation, “robots,” “spiders,” “offline
          readers,” etc., to access the Service in a manner that sends more
          request messages to the servers running the Service than a human can
          reasonably produce in the same period of time by using a conventional
          on-line web browser (except that Awaken grants the operators of public
          search engines revocable permission to use spiders to copy publicly
          available materials from the Service for the sole purpose of, and
          solely to the extent necessary for, creating publicly available
          searchable indices of the materials, but not caches or archives of
          such materials); (d) transmitting spam, chain letters, or other
          unsolicited email; (e) attempting to interfere with, compromise the
          system integrity or security of, or decipher any transmissions to or
          from the servers running the Service; (f) taking any action that
          imposes, or may impose (as determined by us, in our sole discretion),
          an unreasonable or disproportionately large load on our
          infrastructure; (g) uploading invalid data, viruses, worms, or other
          software agents through the Service; (h) collecting or harvesting any
          personal information, including account names, from the Service; (i)
          using the Service for any commercial solicitation purposes or other
          commercial purposes, including but not limited to, preparing tax
          documentation or filings for others in exchange for payment; (j)
          impersonating another person or otherwise misrepresenting your
          affiliation with a person or entity, conducting fraud, or hiding or
          attempting to hide your identity; (k) interfering with the proper
          working of the Service; (l) accessing any content on the Service
          through any technology or means other than those provided or
          authorized by the Service; or (m) bypassing the measures we may use to
          prevent or restrict access to the Service, including, without
          limitation, features that prevent or restrict use or copying of any
          content or enforce limitations on the use of the Service or the
          content therein.
        </LegalContainer>
        <Heading size="sm">1.5 Changes to the Service</Heading>
        <LegalContainer>
          We may, with or without prior notice, change the Service; stop
          providing the Service or features of the Service, to you or to Users
          generally; or create usage limits for the Service. We may permanently
          or temporarily terminate or suspend your access to your User Account
          and/or the Service with or without notice and without liability, for
          any reason, including if, in our sole determination, you violate any
          provision of these Terms, or for no reason. Upon their termination for
          any reason or no reason, you continue to be bound by these Terms.
        </LegalContainer>
        <Heading size="sm">1.6 Disputes with Other Users</Heading>
        <LegalContainer>
          You are solely responsible for your interactions with other Users. We
          reserve the right, but have no obligation, to monitor disputes between
          you and other Users. Awaken shall have no liability for your
          interactions with other Users, or for any User’s action or inaction.
        </LegalContainer>
        <Heading size="lg">2. User Content</Heading>
        <LegalContainer>
          The Service may allow you to submit, post, display, provide, or
          otherwise make available content such as images, comments, questions,
          and other content or information (“User Content”).
        </LegalContainer>
        <LegalContainer>
          We claim no ownership rights over your User Content, and, as between
          you and us, it remains yours. Awaken has the right (but not the
          obligation), in its sole discretion, to remove any User Content that
          is shared via the Service.
        </LegalContainer>
        <LegalContainer>
          By submitting, posting, displaying, providing, or otherwise making
          available any User Content on or through the Service, you hereby
          expressly grant, and you represent and warrant that you have all
          rights necessary to grant, to Awaken a royalty-free, transferable,
          perpetual, irrevocable, non-exclusive, and worldwide license, with the
          right to grant and authorize sublicenses, to use, reproduce, modify,
          publish, list information regarding, edit, translate, distribute,
          syndicate, publicly perform, publicly display, and make derivative
          works of all such User Content and your name, voice, and/or likeness
          as contained in your User Content, in whole or in part, and in any
          form, media, or technology, whether now known or hereafter developed,
          for use in connection with the Service and Awaken’s (and its
          successors’) business, including, without limitation, for promoting
          and redistributing part or all of the Service (and derivative works
          thereof) in any media formats and through any media channels.
        </LegalContainer>
        <LegalContainer>
          For the purposes of these Terms, “Intellectual Property Rights” means
          all patent rights, copyright rights, mask work rights, moral rights,
          rights of publicity, trademark, trade dress, and service mark rights,
          goodwill, trade secret rights, and any and all other intellectual
          property rights as may now exist or hereafter come into existence, and
          all applications therefore and registrations, renewals, and extensions
          thereof, under the laws of any state, country, territory, or other
          jurisdiction.
        </LegalContainer>
        <LegalContainer>
          In connection with your User Content, you affirm, represent, and
          warrant the following:
        </LegalContainer>
        <UnorderedList
          style={{
            marginLeft: "1rem",
          }}
        >
          <Text>
            <ListItem>
              you have the written consent of each and every identifiable
              natural person referred to or mentioned in the User Content, if
              any, to use such person’s name or likeness in the manner
              contemplated by the Service and these Terms, and each such person
              has released you from any liability that may arise in relation to
              such use;
            </ListItem>
            <ListItem>
              you have obtained and are solely responsible for obtaining all
              consents as may be required by Applicable Law to post any User
              Content relating to third parties;
            </ListItem>
            <ListItem>
              your User Content and Awaken’s use thereof as contemplated by
              these Terms and the Service will not violate any Applicable Law or
              infringe any rights of any third party, including, but not limited
              to, any Intellectual Property Rights and privacy rights;
            </ListItem>
            <ListItem>
              Awaken may exercise the rights to your User Content granted under
              these Terms without liability for payment of any guild fees,
              residuals, payments, fees, or royalties payable under any
              collective bargaining agreement or otherwise;
            </ListItem>
            <ListItem>
              your User Content does not include nudity or other sexually
              suggestive content; hate speech, threats, or direct attacks on an
              individual or group; abusive, harassing, tortious, defamatory,
              vulgar, obscene, libelous, invasive of another’s privacy, hateful,
              or racially, ethnically, or otherwise objectionable content;
              content that contains self-harm or excessive violence; illegal
              content or content in furtherance of harmful or illegal
              activities; malicious programs or code; any person’s personal
              information without such person’s consent; and/or spam,
              machine-generated content, or unsolicited messages; and
            </ListItem>
            <ListItem>
              to the best of your knowledge, all of your User Content and all
              other information that you provide to us is truthful and accurate.
            </ListItem>
          </Text>
        </UnorderedList>
        <LegalContainer>
          Awaken takes no responsibility and assumes no liability for any User
          Content that you or any other User or third party submits, posts,
          displays, provides, or otherwise makes available on or through the
          Service. You will be solely responsible for your User Content and the
          consequences of submitting it, posting it, displaying it, providing
          it, or otherwise making it available on or through the Service, and
          you agree that we are acting only as a passive conduit for your online
          distribution and publication of your User Content. You understand and
          agree that you may be exposed to User Content that is inaccurate,
          objectionable, inappropriate for children, or otherwise unsuited to
          your purpose, and you agree that Awaken will not be liable for any
          damages you allege to incur as a result of or relating to any User
          Content.
        </LegalContainer>
        <Heading size="lg">3. Our Proprietary Rights</Heading>
        <Heading size="sm">3.1 Awaken Content</Heading>
        <LegalContainer>
          Except for your User Content, the Service and all materials therein or
          transferred thereby, including, without limitation, any software,
          images, text, graphics, illustrations, logos, patents, trademarks,
          service marks, copyrights, photographs, audio, videos, music, and User
          Content belonging to other Users (the “Awaken Content”), and all
          Intellectual Property Rights related thereto, are the exclusive
          property of Awaken and its licensors, providers, distributors, and
          suppliers (collectively, “Suppliers”). Except as explicitly provided
          herein, nothing in these Terms will be deemed to create a license in
          or under any such Intellectual Property Rights, and you will not sell,
          license, rent, modify, distribute, copy, reproduce, transmit, publicly
          display, publicly perform, publish, adapt, edit, or create derivative
          works of any Awaken Content. Use of the Awaken Content for any purpose
          not expressly permitted by these Terms is strictly prohibited.
        </LegalContainer>
        <Heading size="sm">3.2 Usage Data</Heading>
        <LegalContainer>
          Awaken may collect, maintain, process, and use, or you may provide to
          Awaken, diagnostic, technical, usage, and related information,
          including information about your computers, mobile devices, systems,
          and software (collectively, “Usage Data”). You agree that all Usage
          Data is owned solely and exclusively by Awaken, and, to the extent any
          ownership rights in or to the Usage Data vest in you, you hereby
          assign to Awaken all rights, title, and interest in and to the same.
          Accordingly, Awaken may use the Usage Data or any portion thereof for
          any lawful purpose, including, without limitation: (a) to provide and
          maintain the Service; (b) to develop and improve the Service; (c) to
          monitor your usage of the Service; (d) for research and analytics and
          for Awaken’s other business purposes; and (e) to share analytics and
          other derived Usage Data with third parties, solely in deidentified or
          aggregated form. The Service may contain technological measures
          designed to prevent unauthorized or illegal use of the Service. You
          acknowledge and agree that Awaken may use these and other lawful
          measures to verify your compliance with the terms of these Terms and
          to enforce Awaken’s rights, including all Intellectual Property
          Rights, in and to the Service.
        </LegalContainer>
        <Heading size="sm">3.3 Feedback</Heading>
        <LegalContainer>
          To the extent you provide any suggestions, recommendations, or other
          feedback relating to the Service or any other Awaken products or
          services, (collectively, “Feedback”), such Feedback is
          non-confidential, and you hereby grant, and you represent and warrant
          that you have all rights necessary to grant, to Awaken a
          non-exclusive, perpetual, irrevocable, transferable, royalty-free, and
          worldwide license, with the right to grant and authorize sublicenses,
          to implement, use, modify, and otherwise exploit, in any way without
          restriction, the Feedback, without any fees, attribution, or other
          obligations to you or any third party.
        </LegalContainer>
        <Heading size="lg">4. Paid Services</Heading>
        <Heading size="sm">4.1 Billing Policies</Heading>
        <LegalContainer>
          Certain aspects of the Service may be provided for a fee or other
          charge. If you elect to use paid aspects of the Service, you agree to
          the pricing and payment terms applicable to you and available at [URL]
          (as we may update them from time to time, the “Pricing and Payment
          Terms”). The Pricing and Payment Terms are hereby incorporated by this
          reference herein. You acknowledge and agree that any offers made
          available through the Service are subject to change at any time and
          from time to time. Without limiting the generality of the foregoing,
          Awaken may add new services for additional fees and charges, and may
          add or amend fees and charges for existing services, at any time, in
          its sole discretion. Any change to the Pricing and Payment Terms will
          become effective in the billing cycle following notice of such change
          to you as provided in these Terms.
        </LegalContainer>
        <Heading size="sm">4.2 Payment Information; Taxes</Heading>
        <LegalContainer>
          In connection with payments of any fees and/or any other monetary
          transaction interaction with the Service, you may be asked to provide
          customary billing information such as name, billing address, and
          credit card information to us or to the applicable third-party payment
          processor. All information that you provide in connection with a
          monetary transaction interaction with the Service must be accurate,
          complete, and current. You agree to pay all charges incurred by users
          of your payment method used in connection with a monetary transaction
          interaction with the Service at the prices in effect when such charges
          are incurred. You hereby authorize the collection of such amounts by
          charging your designated payment method or credit or debit card,
          either directly by us, or indirectly via a third-party payment
          processor. We cannot control any fees that may be charged to you by
          your bank in connection with the collection of fees, and we disclaim
          any and all liability in this regard. If you are directed to a
          third-party payment processor, you will be subject to terms and
          conditions governing the use of that third party’s service and to that
          third party’s personal information collection practices. Please review
          such terms and conditions and privacy notice before using such third
          party’s services. Without limiting the generality of the foregoing,
          you acknowledge and understand that, by using the Service, you agree
          to be bound by Stripe, Inc.’s Services Agreement, and by the terms of
          service of any other third-party payment processor(s) that we may
          engage from time to time in our sole discretion. You acknowledge and
          understand that third-party payment processors may collect and retain
          Third-Party Fees whenever you pay fees. Except as otherwise provided
          herein, fees are non-refundable. If you believe you have been
          improperly charged and would like to request a refund, please contact
          us at team@awaken.tax. You will pay, and indemnify Awaken for, all
          applicable taxes, if any, relating to any monetary transaction
          interaction with the Service. As used herein, “Third-Party Fees” means
          the portion of the fee(s) retained by third parties such as Stripe,
          Inc. and other third-party payment processors that we may engage from
          time to time, in our sole discretion.
        </LegalContainer>
        <Heading size="lg">No Professional Advice</Heading>
        <LegalContainer>
          TO THE EXTENT PROFESSIONAL INFORMATION (FOR EXAMPLE, LEGAL, FINANCIAL,
          ACCOUNTING, TAX, OR INVESTMENT) IS PROVIDED THROUGH OR IN CONNECTION
          WITH THE SERVICE, SUCH INFORMATION IS FOR INFORMATIONAL PURPOSES ONLY,
          SHOULD NOT BE CONSTRUED AS PROFESSIONAL ADVICE, AND IS NOT A
          SUBSTITUTE FOR INDIVIDUALIZED PROFESSIONAL ADVICE. THE SERVICE IS NOT
          INTENDED TO PROVIDE SPECIFIC TAX ADVICE TO ANY INDIVIDUAL OR BUSINESS.
          YOU ARE ENCOURAGED TO CONSULT YOUR OWN LEGAL AND/OR TAX PROFESSIONAL
          OR THE INTERNAL REVENUE SERVICES (“IRS”) OR OTHER RELEVANT TAXING
          AUTHORITY WITH ANY SPECIFIC QUESTIONS ABOUT YOUR OWN TAX DOCUMENTATION
          AND/OR POSITIONS TAKEN IN SUCH TAX DOCUMENTATION. NO ACTION SHOULD BE
          TAKEN BASED UPON ANY INFORMATION CONTAINED IN THE SERVICE. YOU SHOULD
          SEEK INDEPENDENT PROFESSIONAL ADVICE FROM A COMPETENT PROFESSIONAL WHO
          IS LICENSED AND/OR QUALIFIED IN THE APPLICABLE AREA. AWAKEN IS NOT,
          AND IS NOT PROVIDING SERVICES IN ANY CAPACITY AS, A FINANCIAL PLANNER,
          INVESTMENT ADVISOR, BROKER, ACCOUNTANT, PAID TAX PREPARER, OR TAX
          ADVISOR, NOR A CREDIT REPAIR OR CREDIT SERVICES ORGANIZATION AS
          DEFINED UNDER FEDERAL OR STATE LAW, INCLUDING ANY CREDIT REPAIR OR
          CREDIT SERVICES ORGANIZATION ACTS.
        </LegalContainer>
        <LegalContainer>
          YOU ACKNOWLEDGE AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR OWN
          FINANCIAL AND TAX-RELATED DETERMINATIONS AND DECISIONS, INCLUDING
          THOSE RELATING TO TAX RETURN REPORTING POSITIONS, THAT YOU WILL NOT
          RELY ON THE SERVICE AS THE BASIS OF YOUR FINANCIAL AND/OR TAX-RELATED
          DECISIONS OR TAX RETURN REPORTING POSITIONS, AND THAT AWAKEN AND ITS
          SUPPLIERS WILL NOT BE LIABLE FOR DECISIONS OR ACTIONS YOU TAKE OR
          AUTHORIZE THIRD PARTIES TO TAKE ON YOUR BEHALF BASED ON INFORMATION
          YOU OBTAIN FROM YOUR ACCESS TO AND/OR USE OF THE SERVICE. AWAKEN DOES
          NOT DETERMINE IF THE TOOLS AND/OR RESOURCES MADE AVAILABLE IN
          CONNECTION WITH THE SERVICE ARE SUITABLE FOR YOUR FINANCIAL OBJECTIVES
          OR TAX-RELATED DECISIONS OR TAX RETURN REPORTING POSITIONS. AWAKEN
          MAKES NO WARRANTIES WITH RESPECT TO, AND IS NOT OTHERWISE RESPONSIBLE
          FOR, THE SERVICES OR PRODUCTS OF ANY OF ITS SUPPLIERS OR THE ACTIONS
          OR INACTIONS OF ANY OTHER THIRD PARTY.
        </LegalContainer>
        <Heading size="lg">6. Preparation of Tax Documentation</Heading>
        <Heading size="sm">6.1 Responsibility</Heading>
        <LegalContainer>
          Tax documentation prepared through the Service will be prepared solely
          based on information you provide to Awaken. You agree to provide all
          the necessary information required for preparing your tax
          documentation. You have the final responsibility for the accuracy and
          completeness of each item on any tax documentation, as well as the
          overall accuracy, completeness, and correctness of the tax
          documentation. Awaken is not, and does not hold itself out as, a
          professional tax advisor or paid tax return preparer and does not
          provide any specific tax advice or guidance. Neither Awaken nor our
          Suppliers will independently audit or otherwise verify the tax-related
          information you submit. If the IRS or other taxing authority should
          later contest the position taken on your tax documentation, there may
          be an assessment of additional tax, plus interest and penalties. You
          acknowledge and agree that any such understated tax and imposed
          interest and penalties are your responsibility, and that Awaken
          assumes no liability for any such additional tax, interest, penalties,
          or other assessments. You are responsible for ensuring that your tax
          documentation is timely filed and for confirming that your tax
          documentation is/are accepted by the relevant taxing authorities.
          Awaken cannot guarantee that the taxing authority(ies) will accept tax
          documentation prepared using the Service.
        </LegalContainer>
        <Heading size="sm">6.2 Tax-related Data and Documentation</Heading>
        <LegalContainer>
          To the extent required by Applicable Law, Awaken stores and maintains
          tax-related data and information that you provide to us. However, you
          are responsible for maintaining and retaining information
          substantiating the accuracy and completeness of your tax documentation
          and compliance with all governmental recordkeeping requirements. You
          agree to hold us harmless from any liability, including but not
          limited to, additional tax, penalties, interest, and professional fees
          associated with inadequate documentation or maintenance of
          documentation related to your taxes.
        </LegalContainer>
        <Heading size="sm">
          6.3 Foreign Bank Accounts or Financial Assets
        </Heading>
        <LegalContainer>
          Under United States law, reporting is required with respect to persons
          having certain direct and indirect interests in a foreign bank account
          or other foreign financial asset. Failure to comply with these
          reporting requirements may result in substantial civil and criminal
          penalties. You are responsible for foreign bank account and foreign
          financial asset reporting requirements with the U.S. Department of the
          Treasury and/or the IRS. You agree to hold us harmless from any
          liability, including but not limited to, additional tax, penalties,
          interest, and professional fees associated with the failure to file,
          or untimely filing, of any of these forms.
        </LegalContainer>
        <Heading size="sm">6.4 Role of the Service</Heading>
        <LegalContainer>
          Awaken is designed to permit you to input information and data about
          your tax situation necessary to prepare certain tax documentation and
          to provide you with access to information and materials to assist you
          in forming your own determinations as to your tax-related positions.
          We are not responsible for inaccuracies in services provided directly
          or through our Suppliers, or for future changes in the law that may
          affect services we have already provided. You acknowledge and agree
          that you have final responsibility for ensuring the completeness and
          accuracy of all tax documentation prepared using the Service.
        </LegalContainer>
        <Heading size="lg">7. Privacy</Heading>
        <LegalContainer>
          We care about the privacy of our Users. By using the Service, you
          understand and acknowledge that your personal information will be
          collected, used, and disclosed as set forth in our Privacy Policy.
        </LegalContainer>
        <Heading size="lg">8. Security</Heading>
        <LegalContainer>
          We care about the integrity and security of your personal information.
          However, we cannot guarantee that unauthorized third parties will
          never be able to defeat our security measures or use your personal
          information for improper purposes. You acknowledge that you provide
          your personal information at your own risk.
        </LegalContainer>
        <Heading size="lg">
          Third-Party Links, Information, and Services
        </Heading>
        <LegalContainer>
          The Service may contain links to third-party materials and services
          that are not owned or controlled by Awaken. Further, to use the
          Service, you may be required to register for third-party services
          and/or to otherwise enable various third-party services that may
          directly integrate with the Service (collectively, “Third-Party
          Services”). By enabling a Third-Party Service within the Service, you
          allow Awaken to pass your log-in credentials and/or other information
          (e.g., tokenized authentication information) to such Third-Party
          Service. Awaken does not endorse or assume any responsibility for any
          third-party sites, nor for any information, materials, products, or
          services (including, without limitation, Third-Party Services)
          available on or through third-party sites. If you access a third-party
          website or service (including, without limitation, a Third-Party
          Service) from the Service or share your User Content on or through any
          third-party website or service (including, without limitation, a
          Third-Party Service), you do so at your own risk, and you understand
          that these Terms and our Privacy Policy do not apply to your access to
          or use of such sites or services. You acknowledge that a Third-Party
          Service may revoke your right to access and/or authenticate to such
          Third-Party Service at any time. As such, Awaken is not liable for any
          damage or loss caused or alleged to be caused by or in connection with
          your use of or reliance on any Third-Party Service. Awaken enables
          Third-Party Services merely as a convenience to Users, and the
          inclusion of Third-Party Services does not imply an endorsement or
          recommendation of them. You expressly relieve Awaken from any and all
          liability arising from your access to and/or use of any third-party
          website, service, or content, including, without limitation, User
          Content submitted by other Users.
        </LegalContainer>
        <LegalContainer>
          Additionally, your dealings with or participation in promotions of
          advertisers found on the Service, including payment and delivery of
          goods, and any other terms (such as warranties) are solely between you
          and such advertisers. You agree that Awaken will not be responsible
          for any loss or damage of any sort relating to your dealings with such
          advertisers.
        </LegalContainer>
        <Heading size="lg">10. Cooperation</Heading>
        <LegalContainer>
          If requested, you agree to diligently assist Awaken in responding to
          requests and inquiries with respect to your User Account, User
          Content, and/or material or transactions associated with you or your
          User Account, including, without limitation, by providing us with all
          information and assistance we may reasonably require, and/or
          responding promptly and accurately to any such requests and inquiries,
          should we connect between you and the inquirer.
        </LegalContainer>
        <Heading size="lg">11. Release and Indemnity</Heading>
        <LegalContainer>
          You hereby release Awaken from all damages (whether direct, indirect,
          incidental, consequential, or otherwise), losses, liabilities, costs,
          and expenses of every kind and nature, known and unknown, arising out
          of a dispute between you and a third party (including any other User)
          in connection with the Service. In addition, you waive any applicable
          law or statute, which says, in substance: “A GENERAL RELEASE DOES NOT
          EXTEND TO CLAIMS WHICH THE RELEASING PARTY DOES NOT KNOW OR SUSPECT TO
          EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH
          IF KNOWN BY HIM OR HER WOULD HAVE MATERIALLY AFFECTED HIS OR HER
          SETTLEMENT WITH THE RELEASED PARTY.”
        </LegalContainer>
        <LegalContainer>
          You agree to defend, indemnify, and hold harmless Awaken and its
          Suppliers, employees, contractors, agents, attorneys, accountants,
          officers, and directors from and against any and all claims, damages,
          obligations, losses, liabilities, costs, debts, and expenses
          (including, but not limited to, attorneys’ fees) arising from: (a)
          your access to and use of the Service, including any data or content
          transmitted or received by you; (b) your violation of any term of
          these Terms, including, without limitation, your breach of any of the
          representations and warranties herein contained; (c) your violation of
          any third-party right, including, without limitation, any right of
          privacy or Intellectual Property Right; (d) your violation of any
          Applicable Law; (e) User Content or any content that is submitted via
          your User Account, including, without limitation, misleading, false,
          or inaccurate information; (f) your willful misconduct; or (g) any
          other party’s access to and/or use of the Service with your
          credentials. Awaken reserves the right, in its sole discretion and at
          its expense, to assume the exclusive defense and control of any such
          claims, in which case, you agree to cooperate as reasonably requested
          by Awaken in the defense of such claims.
        </LegalContainer>
        <Heading size="lg">12. No Warranty</Heading>
        <LegalContainer>
          The Service is provided on an “as is” and “as available” basis. Use of
          the Service is at your own risk. To the maximum extent permitted by
          Applicable Law, the Service, the Awaken Content, and any other
          information available on or through the Service are provided without
          warranties of any kind, whether express or implied, including, but not
          limited to, implied warranties of merchantability, fitness for a
          particular purpose, or non-infringement. No advice or information,
          whether oral or written, obtained by you from Awaken or through the
          Service will create any warranty not expressly stated herein. Without
          limiting the generality of the foregoing, Awaken and its Suppliers do
          not warrant that the Awaken Content or any other information contained
          in the Service are accurate, comprehensive, reliable, useful, or
          correct; that the Service will meet your requirements or satisfy or
          ensure compliance with any legal obligations or Applicable Law; that
          the Service will be available at any particular time or location,
          uninterrupted, or secure; that any defects or errors in the Service
          will be corrected; or that the Service is free of viruses or other
          harmful components. Any content downloaded or otherwise obtained
          through the use of the Service is downloaded at your own risk, and you
          will be solely responsible for any damage to your computer system(s)
          or mobile device(s) or for loss of data that results from such
          download or from your use of the Service.
        </LegalContainer>
        <LegalContainer>
          Awaken is not responsible for your financial and/or tax-related
          decisions, nor for any damages or other losses resulting from the
          reliance on or use of information provided by Awaken and/or through
          the Service. Further, Awaken does not warrant, endorse, guarantee,
          recommend, or assume responsibility for any product or service
          advertised or offered by any third party through the Service or any
          hyperlinked website or service, and Awaken will not be a party to, or
          in any way monitor, any transaction between you and third-party
          providers of products or services.
        </LegalContainer>
        <LegalContainer>
          Federal law and some states, provinces, and other jurisdictions do not
          allow the exclusion and limitations of certain implied warranties, so
          the above exclusions may not apply to you. These Terms give you
          specific legal rights, and you may also have other rights, which vary
          from jurisdiction to jurisdiction. The disclaimers and exclusions
          under these Terms will not apply to the extent prohibited by
          Applicable Law.
        </LegalContainer>
        <Heading size="lg">13. Limitation of Liability</Heading>
        <LegalContainer>
          To the maximum extent permitted by Applicable Law, in no event will
          Awaken or its Suppliers, employees, contractors, agents, attorneys,
          accountants, officers, or directors be liable for any indirect,
          punitive, incidental, special, consequential, or exemplary damages,
          including, without limitation, damages for loss of profits, goodwill,
          use, or data, or other intangible losses, arising out of or relating
          to the use of, or inability to use, the Service, and/or to the Awaken
          Content or any other information contained in the Service. Under no
          circumstances will Awaken be responsible for any damage, loss, or
          injury resulting from hacking, tampering, or other unauthorized access
          to or use of the Service or your User Account or the information
          contained therein.
        </LegalContainer>
        <LegalContainer>
          To the maximum extent permitted by Applicable Law, Awaken assumes no
          liability or responsibility for any (a) errors, mistakes, or
          inaccuracies of content; (b) personal injury or property damage, of
          any nature whatsoever, resulting from your access to or use of the
          Service; (c) any unauthorized access to or use of the servers running
          the Service and/or any and all personal information stored therein;
          (d) any interruption or cessation of transmission to or from the
          Service; (e) any bugs, viruses, trojan horses, or the like that may be
          transmitted to or through the Service by any third party; (f) any
          errors or omissions in any Awaken Content, or any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through the Service; and/or
          (g) User Content or the defamatory, offensive, or illegal conduct of
          any third party. In no event will Awaken or its Suppliers, employees,
          contractors, attorneys, accountants, agents, officers, or directors be
          liable to you for any claims, proceedings, liabilities, obligations,
          damages, losses, or costs in an amount exceeding the amount you paid
          to Awaken hereunder during the twelve (12) months preceding the date
          on which the liability arose or $50.00, whichever is greater.
        </LegalContainer>
        <LegalContainer>
          This limitation of liability section applies whether the alleged
          liability is based on contract, tort, negligence, strict liability, or
          any other basis, even if Awaken has been advised of the possibility of
          such damage.
        </LegalContainer>
        <LegalContainer>
          Some jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitations or
          exclusions may not apply to you. These Terms give you specific legal
          rights, and you may also have other rights, which vary from
          jurisdiction to jurisdiction. The disclaimers, exclusions, and
          limitations of liability under these Terms will not apply to the
          extent prohibited by Applicable Law.
        </LegalContainer>
        <Heading size="lg">
          14. Governing Law, Arbitration, and Class Action/Jury Trial Waiver
        </Heading>
        <Heading size="sm">14.1 Governing Law</Heading>
        <LegalContainer>
          Governing Law. You agree that: (a) the Service will be deemed solely
          based in Delaware; and (b) the Service will be deemed a passive one
          that does not give rise to personal jurisdiction over us, either
          specific or general, in jurisdictions other than Delaware. These Terms
          will be governed by the internal substantive laws of the State of
          Delaware, without respect to its conflict of laws principles. The
          parties acknowledge that these Terms evidence a transaction involving
          interstate commerce. Notwithstanding the preceding sentences with
          respect to the substantive law governing these Terms, the Federal
          Arbitration Act (9 U.S.C. §§ 1-16) (“FAA”) governs the interpretation
          and enforcement of the Arbitration Agreement below and preempts all
          state laws (and laws of other jurisdictions) to the fullest extent
          permitted by Applicable Law. If the FAA is found to not apply to any
          issue that arises from or relates to the Arbitration Agreement, then
          that issue will be resolved under and governed by the law of the U.S.
          state where you reside or the jurisdiction mutually agreed upon in
          writing by you and us. The application of the United Nations
          Convention on Contracts for the International Sale of Goods is
          expressly excluded. You agree to submit to the exclusive personal
          jurisdiction of the federal and state courts located in Delaware for
          any actions for which we retain the right to seek injunctive or other
          equitable relief in a court of competent jurisdiction to prevent the
          actual or threatened infringement, misappropriation, or violation of
          our Intellectual Property Rights or other proprietary rights, as set
          forth in the Arbitration Agreement below, including any provisional
          relief required to prevent irreparable harm. You agree that Delaware
          is the proper and exclusive forum for any appeals of an arbitration
          award or for trial court proceedings in the event that the Arbitration
          Agreement below is found to be unenforceable.
        </LegalContainer>
        <Heading size="sm">14.2 Arbitration</Heading>
        <LegalContainer>
          Read this Section carefully because it requires the parties to
          arbitrate their disputes and limits the manner in which you can seek
          relief from AWAKEN. This Arbitration Agreement applies to and governs
          any dispute, controversy, or claim between you and Awaken that arises
          out of or relates to, directly or indirectly: (a) these Terms,
          including the formation, existence, breach, termination, enforcement,
          interpretation, validity, or enforceability thereof; (b) access to or
          use of the Service, including receipt of any advertising or marketing
          communications; (c) any transactions through, by, or using the
          Service; or (d) any other aspect of your relationship or transactions
          with Awaken, directly or indirectly, as a consumer (each, a “Claim,”
          and, collectively, “Claims”). This Arbitration Agreement will apply,
          without limitation, to all Claims that arose or were asserted before
          or after your agreement to these Terms.
        </LegalContainer>
        <LegalContainer>
          If you are a new User, you can reject and opt-out of this Arbitration
          Agreement within thirty (30) days of accepting these Terms by emailing
          Awaken at team@awaken.tax with your full legal name and stating your
          intent to opt-out of this Arbitration Agreement. Note that opting out
          of this Arbitration Agreement does not affect any other part of these
          Terms, including the provisions regarding controlling law or the
          courts in which any disputes must be brought.
        </LegalContainer>
        <LegalContainer>
          For any Claim, you agree to first contact us at team@awaken.tax and to
          attempt to resolve the Claim with us informally. In the unlikely event
          that we have not been able to resolve a Claim after sixty (60) days,
          we each agree to resolve such Claim exclusively through binding
          arbitration by the American Arbitration Association (“AAA”) before a
          single arbitrator (the “Arbitrator”), under the Expedited Procedures
          then in effect for AAA (the “Rules”), except as provided herein. In
          the event of any conflict between the Rules and this Arbitration
          Agreement, this Arbitration Agreement will control. AAA may be
          contacted at www.adr.org, where the Rules are also available. The
          arbitration will be conducted in the U.S. county where you reside or
          Sussex County, Delaware, unless you and Awaken agree otherwise. You
          acknowledge and understand that, depending on the kind of User you
          are, either (i) each of you and us will be responsible for paying any
          AAA filing and administrative and Arbitrator fees in accordance with
          the Rules, and the award rendered by the Arbitrator will include costs
          of arbitration, reasonable attorneys’ fees, and reasonable costs for
          expert and other witnesses, or (ii) AAA may require you to pay a fee
          for the initiation of your case, unless you apply for and successfully
          obtain a fee waiver from AAA; the award rendered by the Arbitrator may
          include your costs of arbitration, your reasonable attorneys’ fees,
          and your reasonable costs for expert and other witnesses; and you may
          sue in a small claims court of competent jurisdiction without first
          engaging in arbitration, but this would not absolve you of your
          commitment to engage in the informal dispute resolution process. Any
          judgment on the award rendered by the Arbitrator may be entered in any
          court of competent jurisdiction. You and Awaken agree that the
          Arbitrator, and not any federal, state, or local court or agency, will
          have exclusive authority to resolve any disputes relating to the
          scope, interpretation, applicability, enforceability, or formation of
          this Arbitration Agreement, including any claim that all or any part
          of this Arbitration Agreement is void or voidable. The Arbitrator will
          also be responsible for determining all threshold arbitrability
          issues, including issues relating to whether these Terms are, or any
          provision of these Terms is, unconscionable or illusory, and any
          defense to arbitration, including waiver, delay, laches,
          unconscionability, and/or estoppel.
        </LegalContainer>
        <LegalContainer>
          Nothing in this Arbitration Agreement will be deemed as: preventing
          Awaken from seeking injunctive or other equitable relief from the
          courts as necessary to prevent the actual or threatened infringement,
          misappropriation, or violation of its data security, Intellectual
          Property Rights, or other proprietary rights; or preventing you from
          asserting claims in small claims court, provided that your claims
          qualify, and so long as the matter remains in such court and advances
          on only an individual (non-class, non-representative) basis.
        </LegalContainer>
        <LegalContainer>
          If this Arbitration Agreement is found to be void, unenforceable, or
          unlawful, in whole or in part, the void, unenforceable, or unlawful
          provision, in whole or in part, will be severed. Severance of the
          void, unenforceable, or unlawful provision, in whole or in part, will
          have no impact on the remaining provisions of this Arbitration
          Agreement, which will remain in force, or on the parties’ ability to
          compel arbitration of any remaining Claims on an individual basis
          pursuant to this Arbitration Agreement. Notwithstanding the foregoing,
          if the Class Action/Jury Trial Waiver below is found to be void,
          unenforceable, or unlawful, in whole or in part, because it would
          prevent you from seeking public injunctive relief, then any dispute
          regarding the entitlement to such relief (and only that relief) must
          be severed from arbitration and may be litigated in a civil court of
          competent jurisdiction. All other claims for relief subject to
          arbitration under this Arbitration Agreement will be arbitrated under
          its terms, and the parties agree that litigation of any dispute
          regarding the entitlement to public injunctive relief will be stayed
          pending the outcome of any individual claims in arbitration.
        </LegalContainer>
        <Heading size="sm">14.3 Class Action/Jury Trial Waiver</Heading>
        <LegalContainer>
          Regardless of the type of user you are, including whether you are a
          natural person or an entity, any claim must be brought in the your
          individual capacity, and not as a plaintiff or class member in any
          purported class action, collective action, private attorney general
          action, or other representative proceeding. This Class Action/Jury
          Trial Waiver applies to class arbitration, and, unless we agree
          otherwise, the arbitrator may not consolidate more than one person’s
          Claims. you and Awaken agree that the arbitrator may award relief only
          to an individual claimant and only to the extent necessary to provide
          relief on your individual claim(s). Any relief awarded may not affect
          other users. You and Awaken further agree that, by entering into these
          Terms, you and awaken are each waiving the right to a trial by jury or
          to bring, join, or participate in a class action, collective action,
          private attorney general action, or other representative proceeding of
          any kind as a plaintiff or class member.
        </LegalContainer>
        <Heading size="lg">15. California Residents</Heading>
        <LegalContainer>
          The provider of services is set forth herein. If you are a California
          resident, in accordance with Cal. Civ. Code §1789.3, you may report
          complaints to the Complaint Assistance Unit of the Division of
          Consumer Services of the California Department of Consumer Affairs by
          contacting it in writing at 1625 North Market Blvd., Suite N 112
          Sacramento, CA 95834, or by telephone at (800) 952-5210 or (916)
          445-1254.
        </LegalContainer>
        <Heading size="lg">16. General</Heading>
        <Heading size="sm">16.1 Assignment</Heading>
        <LegalContainer>
          These Terms, and any rights and licenses granted hereunder, may not be
          transferred or assigned by you, but may be assigned by Awaken without
          restriction. Any attempted transfer or assignment in violation hereof
          will be null and void.
        </LegalContainer>
        <Heading size="sm">
          16.2 Notification Procedures and Changes to these Terms
        </Heading>
        <LegalContainer>
          Awaken may provide notifications, whether such notifications are
          required by Applicable Law or are for marketing or other business
          related purposes, to you via email notice, or written or hard copy
          notice, or through posting of such notice on the Awaken website, as
          determined by Awaken, in its sole discretion. Awaken reserves the
          right to determine the form and means of providing notifications to
          Users, provided that you may opt out of certain means of notification
          as described in these Terms. Awaken is not responsible for any
          automatic filtering you or your network provider may apply to email
          notifications we send to the email address you provide us. Awaken may,
          in its sole discretion, modify or update these Terms from time to
          time, and so you should review this page periodically. When we change
          these Terms in a material manner, we will update the ‘last modified’
          date at the top of this page and notify you that material changes have
          been made to these Terms. Your continued use of the Service after any
          such change constitutes your acceptance of the new Terms of Service.
          If you do not agree to any of the terms of these Terms or to any
          future Terms of Service, do not access or use (or continue to access
          or use) the Service.
        </LegalContainer>
        <Heading size="sm">16.3 Entire Agreement/Severability</Heading>
        <LegalContainer>
          These Terms, together with any amendments and any additional
          agreements you may enter into with Awaken in connection with the
          Service, will constitute the entire agreement between you and Awaken
          concerning the Service. Except as otherwise stated in the Arbitration
          Agreement, if any provision of these Terms is deemed invalid by a
          court of competent jurisdiction, the invalidity of such provision will
          not affect the validity of the remaining provisions of these Terms,
          which will remain in full force and effect.
        </LegalContainer>
        <Heading size="sm">16.4 No Waiver</Heading>
        <LegalContainer>
          No waiver of any term of these Terms will be deemed a further or
          continuing waiver of such term or of any other term, and Awaken’s
          failure to assert any right or provision under these Terms will not
          constitute a waiver of such right or provision.
        </LegalContainer>
        <Heading size="sm">16.5 Contact</Heading>
        <LegalContainer>
          Please contact us at team@awaken.tax with any questions regarding
          these Terms.
        </LegalContainer>

        <Heading size="lg">17. Referrals</Heading>
        <Heading size="sm">17.1 Eligibility</Heading>
        <LegalContainer>
          You will not get referral credit or referral payouts/commission unless
          you are referring a new user. You cannot refer yourself. If you refer
          yourself, we have the right to remove any credit or referral
          commissions owed to your account. We can do this at any time, we have
          the right to remove credit/commissions where we deem fit if we have
          any proof that suggests you are taking advantage of our referral
          program. If you have questions you can email team@awaken.tax.
        </LegalContainer>
      </Box>
    </NavBarPageTemplate>
  );
}

export default Terms;
