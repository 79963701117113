import {
  HStack,
  Box,
  Tooltip,
  Divider,
  Spinner,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { BaseAssetFields, BaseUserFields } from "src/api/fragments";
import WhiteBox from "src/components/styled/WhiteBox";
import {
  Copy,
  Input,
  Select,
  Option,
  Info,
  Button,
  ActionSheet,
} from "src/components/styled";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioResponse,
  PortfolioSortColumnEnum,
} from "src/api/generated/types";
import _, { debounce, isEmpty, keyBy, truncate } from "lodash";
import { colors, other } from "src/theme";
import { AssetRow } from "./AssetRow";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { PortfolioContext } from "../context";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useTheme } from "src/hooks/useTheme";
import { useMutation } from "@apollo/client";
import { api } from "src/api";

function Assets() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId);

  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const location = useLocation();
  const toast = useMyToast();
  const { me } = useMe();
  const { isLoadingCoins, balances } = useContext(PortfolioContext);
  const { background, header, text, border } = useTheme();
  const isSuperUser = me?.isSuperuser || false;

  const _onAddWallet = () => {
    _showModal("AccountModal", {
      location: "no_transactions_page",
    });
  };

  const [syncMetadata] = useMutation(api.assets.syncMetadata);

  const _hardRefreshAssetImages = async () => {
    try {
      await syncMetadata({
        variables: {
          clientId,
        },
        refetchQueries: [api.clients.assets, api.portfolio.get],
      });
    } catch (err) {
      console.error(err);
      toast.show({
        status: "error",
        message: "Error syncing metadata",
      });
    }
  };

  if (!isLoadingCoins && !balances.length) {
    return (
      <Box
        width="100%"
        padding="3rem 1rem"
        textAlign="center"
        style={{
          borderRadius: 15,
          marginTop: "2rem",
          border: `1px solid ${border}`,
        }}
      >
        <Text color={text}>
          No accounts yet. Add an account to view your portfolio 👇
        </Text>
        <Button variant="primary" marginTop="1rem" onClick={_onAddWallet}>
          Add Account
        </Button>

        {isSuperUser && (
          <Button
            bg={colors.gray2}
            marginRight="0.5rem"
            _hover={{ bg: colors.gray80 }}
            padding="0.5rem 1rem"
            borderRadius={other.borderRadius}
            onClick={_hardRefreshAssetImages}
          >
            <i
              style={{ marginRight: 5, color: colors.gray20 }}
              className="fa-sharp fa-refresh"
            />{" "}
            <Text
              color={colors.gray20}
              fontSize="sm"
              padding="0"
              fontWeight="semibold"
            >
              Refresh Asset Metadata{" "}
              <Info message="Updates images and names of assets if they have changed." />
            </Text>
          </Button>
        )}
      </Box>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 0,
        paddingBottom: 100,
        // marginTop: "1.5rem", FIXME: add back when ready
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignSelf="flex-start"
        alignContent="flex-start"
        w="100%"
        // marginTop="2rem" FIXME: add back when ready
      >
        <WhiteBox
          padding="0"
          w="100%"
          marginTop="1rem"
          style={{
            boxShadow: "none",
            //minHeight: LIMIT * 70
          }} // just so less jolty when navigating
          border="none"
          bg={background}
        >
          <AssetHeader />

          {isLoadingCoins ? (
            <div
              style={{
                padding: "10rem 3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner color={header} />
            </div>
          ) : (
            <>
              {balances.map((balance) => (
                <AssetRow
                  key={balance.groupKey}
                  portfolioBalance={balance}
                  warning={null} // warningByAssetId[balance.assetKey] ??
                  clientId={clientId || ""}
                  currency={client?.currency || CurrencyCodeEnum.Usd}
                />
              ))}
            </>
          )}
        </WhiteBox>
      </Box>
      {isSuperUser && (
        <Button
          bg={colors.gray2}
          marginRight="0.5rem"
          _hover={{ bg: colors.gray80 }}
          padding="0.5rem 1rem"
          borderRadius={other.borderRadius}
          onClick={_hardRefreshAssetImages}
        >
          <i
            style={{ marginRight: 5, color: colors.gray20 }}
            className="fa-sharp fa-refresh"
          />{" "}
          <Text
            color={colors.gray20}
            fontSize="sm"
            padding="0"
            fontWeight="semibold"
          >
            Refresh Asset Metadata{" "}
            <Info message="Updates images and names of assets if they have changed." />
          </Text>
        </Button>
      )}
    </div>
  );
}

const AssetHeader = () => {
  const isLarge = useIsLargeScreen();
  const { border, background, text, secondaryBackground, medBackground } =
    useTheme();

  if (!isLarge) {
    return null;
  }

  return (
    <HStack
      width="100%"
      padding="0.75rem 1.25rem 0.75rem 1.25rem"
      style={{
        justifyContent: "space-between",
      }}
      borderRadius={10}
      bg={medBackground}
    >
      {/* <Box width={35} /> */}

      <Box
        display="flex"
        alignItems="center"
        position="relative"
        fontWeight="500"
        fontSize={isLarge ? "sm" : "xs"}
        style={{
          color: text,
          flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
          marginRight: "calc(35px + 0.5rem)", // bc of the width of the image. hacky
        }}
        // marginLeft="1.25rem !important"
      >
        Asset
      </Box>

      {/* <Tooltip label="Your cost basis/purchase price.">
        <Box
          style={{
            fontWeight: "500",
            cursor: "pointer",
            color: colors.gray10,
            flex: VALUE_FLEX,
          }}
          onClick={() => {
            setSortColumn(PortfolioSortColumnEnum.CostBasis);
            setIsAscending(!isAscending);
          }}
        >
          Basis{" "}
          <SortTriangle
            isSorted={PortfolioSortColumnEnum.CostBasis === sortColumn}
            isAscending={
              isAscending && PortfolioSortColumnEnum.CostBasis === sortColumn
            }
          />
        </Box>
      </Tooltip> */}

      <Tooltip label="What the asset is currently worth.">
        <Box
          style={{
            fontWeight: "500",
            cursor: "pointer",
            color: text,
            maxWidth: 175,
            flex: VALUE_FLEX,
            marginLeft: 0,
          }}
          fontSize={isLarge ? "sm" : "xs"}
          // onClick={() => {
          //   setSortColumn(PortfolioSortColumnEnum.CurrentValue);
          //   setIsAscending(!isAscending);
          // }}
        >
          Value{" "}
          <i
            className="fa-sharp fa-info-circle"
            style={{ marginLeft: 5, color: colors.gray40 }}
          />
        </Box>
      </Tooltip>

      <Tooltip label="The change in the price over the last 24 hours. Note: this assumes you have had the asset for 24 hours or more, so if you just acquired it this number won't be your personal gain/loss (for now, we are working on this).">
        <Box
          style={{
            alignItems: "center",
            justifyContent: "flex-end",
            color: text,
            display: "flex",
            fontWeight: "500",
            marginLeft: 0,
            maxWidth: 175,
            cursor: "pointer",
            flex: VALUE_FLEX,
          }}
          fontSize={isLarge ? "sm" : "xs"}
          // onClick={() => {
          //   setSortColumn(PortfolioSortColumnEnum.GainOrLoss);
          //   setIsAscending(!isAscending);
          // }}
        >
          24h Return{" "}
          <i
            className="fa-sharp fa-info-circle"
            style={{ marginLeft: 5, color: text }}
          />
        </Box>
      </Tooltip>

      {/* <Box width="50px" /> */}
    </HStack>
  );
};

export default Assets;
