import { useQuery } from "@apollo/client";
import {
  Box,
  Divider,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Table,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { noop } from "lodash/fp";
import React, { useContext, useEffect, useState } from "react";
import { api, BaseLedgerEntryFullFields } from "src/api";
import { Query } from "src/api/generated/types";
import { LedgerEntriesTable } from "src/components/Ledger/LedgerEntriesTable";
import { Touchable } from "src/components/Touchable";
import { Button, Info, InfoBox } from "src/components/styled";
import { ActiveTransactionContext } from "src/context";
import { useMe } from "src/hooks";
import { colors, other } from "src/theme";

export const EntriesPopover = () => {
  const { me } = useMe();
  const { transaction, ledgerEntries: entries } = useContext(
    ActiveTransactionContext
  );

  const [isOpen, setOpen] = useState(false);
  const transactionId = transaction?.id;

  const _onOpen = () => setOpen(true);

  if (!transaction || !entries.length) {
    return null;
  }

  return (
    <Popover
      trigger="hover"
      onOpen={_onOpen}
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      placement="bottom"
    >
      <PopoverTrigger>
        <div style={{ display: "flex" }}>
          <Touchable
            padding="0.55rem 0.75rem"
            label={`See Ledger Entries`}
            iconName="fa-sharp fa-scroll-old"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        left="5px"
        maxHeight="650px"
        maxWidth="750px"
        overflowY="scroll"
      >
        <PopoverArrow />

        {/* <HStack padding="0.75rem 1rem" alignItems="center">
            <Text flex={1} fontSize="sm" fontWeight="semibold">
              Entries (ADMIN ONLY) 🕵️‍♂️
            </Text>
          </HStack> */}
        <LedgerEntriesTable ledgerEntries={entries} onClickEntry={noop} />
      </PopoverContent>
    </Popover>
  );
};
