import React from "react";
import { BoxProps, Tooltip } from "@chakra-ui/react";
import { colors } from "src/theme";
import { isMobile } from "react-device-detect";

type Props = BoxProps & {
  message?: string;
  children?: JSX.Element | JSX.Element[];
};

const AwakenTooltip = ({ message, children, ...boxProps }: Props) => {
  return (
    <Tooltip
      zIndex={1401}
      trigger={isMobile ? "click" : "hover"}
      bg={colors.black}
      placement="top"
      borderRadius="0.25rem"
      label={message}
      padding={{ base: "0.5rem 1rem" }}
      {...boxProps}
    >
      {children}
    </Tooltip>
  );
};

export { AwakenTooltip };
