import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Textarea } from "src/components/styled";
import {
  Mutation,
  MutationApplyForCreditMatchArgs,
  MutationRequestBookkeepingQuoteArgs,
  MutationUpdateBookkeepingQuoteArgs,
  UploadFileResponse,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { BaseBookkeepingQuoteFields } from "src/api/fragments";
import { useDropzone } from "react-dropzone";
import { Required } from "src/components/styled/Form/Required";
import { isNil } from "lodash";

type Props = InjectedProps & {
  //
};

function _CompetitorCreditModal({ handleHide, show: isVisible }: Props) {
  const toast = useMyToast();
  const theme = useTheme();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [applyForCredit, { loading }] = useMutation<
    Pick<Mutation, "applyForCreditMatch">
  >(api.clients.applyForCredit);

  const [fileUpload, setFileUpload] = useState<UploadFileResponse | null>(null);

  const _onSubmit = async function () {
    try {
      if (!clientId) {
        return;
      }

      const variables: MutationApplyForCreditMatchArgs = {
        clientId: clientId || "",
        creditMatchFileKey: fileUpload?.objectFileKey || "",
      };

      const response = await applyForCredit({
        variables,
        refetchQueries: [
          api.bookkeepingQuotes.listForClient,
          api.bookkeepingQuotes.retrieve,
        ],
      });

      toast.show({
        message: "Credit match applied successfully.",
        status: "success",
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Competitor Credit Match</>}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          marginBottom="1rem"
          onClick={_onSubmit}
        >
          Apply for Credit Match
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text fontWeight="normal" fontSize="md" color={theme.text}>
          Upload a screenshot of your invoice from a competitor.
          <br />
          <br />
          We'll match receipts{" "}
          <b>
            (and give you $75 of credit{" "}
            <i
              className="fas fa-coins"
              style={{
                //silver
                color: colors.yellow50,
              }}
            />
            )
          </b>{" "}
          if you upload a screenshot of Koinly, CryptoTaxCalculator, CoinLedger,
          CoinTracker, etc...
        </Text>

        <br />

        <InvoiceUpload fileUpload={fileUpload} onFileUpload={setFileUpload} />

        <br />

        <Text fontWeight="normal" fontSize="sm" color={theme.text}>
          Note: we store the invoice screenshot. If you upload a screenshot that
          is not a valid invoice, we will remove credit from your account.
        </Text>
        <br />
      </Container>
    </Modal>
  );
}

const InvoiceUpload = ({
  fileUpload,
  onFileUpload,
}: {
  fileUpload: UploadFileResponse | null;
  onFileUpload: (p: UploadFileResponse) => void;
}) => {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();

  const [uploadFile, { loading }] = useMutation<Pick<Mutation, "uploadFile">>(
    api.uploadFile
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file || !clientId) return;

    try {
      const threeYearsInSeconds = 60 * 60 * 24 * 365 * 3;

      // Do something with the files
      const result = await uploadFile({
        variables: { file, clientId, expiresInSeconds: threeYearsInSeconds },
      });

      const fileUpload = result.data?.uploadFile;

      if (!fileUpload) {
        return;
      }

      onFileUpload(fileUpload);
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      // image files. jpg or png or PDF
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    onDropRejected: (errors) => {
      // console.log(err);
      const message = errors
        .map((e) => e.errors.map((e) => e.message))
        .join(" ");
      toast.show({
        message,
        status: "error",
      });
    },
  });

  const hasFile = loading || !!fileUpload;
  const theme = useTheme();

  return (
    <>
      <FormLabel color={theme.text} fontSize={14} marginBottom="6px">
        Invoice Upload <Required />
      </FormLabel>

      {hasFile && (
        <HStack
          style={{
            marginBottom: "1rem",
            padding: "1rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px solid ` + theme.border,
          }}
          bg={theme.secondaryBackground}
        >
          <i
            style={{
              fontSize: 28,
              color: colors.primary,
              marginRight: 5,
            }}
            // screenshot icon fontawesome
            className="fa-sharp fa-camera-viewfinder"
          />

          <Text flex={1} color={theme.header} fontSize="sm">
            {loading ? (
              <>
                Uploading... <Spinner size="xs" />{" "}
              </>
            ) : (
              fileUpload?.fileName || "Your File"
            )}
          </Text>

          <a href={fileUpload?.url} target="_blank" rel="noreferrer">
            <i
              style={{
                fontSize: 20,
                color: colors.primary,
                marginLeft: 5,
              }}
              className="fa-sharp fa-external-link"
            />
          </a>
        </HStack>
      )}

      {!hasFile && (
        <div
          style={{
            padding: "2rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px dashed ` + colors.gray80,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text color={theme.header} fontSize="md" fontWeight="semibold">
              Drop the files here ...
            </Text>
          ) : (
            <Box textAlign="center">
              <i
                style={{ color: colors.primary, fontSize: 40 }}
                className="fa-sharp fa-camera-viewfinder"
              />
              <Text
                color={theme.header}
                marginTop="1rem"
                fontWeight="bold"
                fontSize="md"
              >
                Drop{" "}
                {/* or <span style={{ color: colors.primary }}>browse</span>{" "} */}
                your file.
              </Text>
            </Box>
          )}
        </div>
      )}
    </>
  );
};

export const CompetitorCreditModal = connectModal({
  name: "CompetitorCreditModal",
})(_CompetitorCreditModal);
