import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { config } from "src/config";
import { trackEvent } from "src/utils/analytics";
import _ from "lodash";
import { isBrowser } from "react-device-detect";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { isWhiteLabeledDomain } from "src/utils/whitelabel";
import { AccountsWorkflow } from "./AccountsWorkflow";

type Props = InjectedProps & {
  location: string;
};

function _AccountModal({ handleHide, show: isVisible, location }: Props) {
  const isLarge = useIsLargeScreen();

  const _onSuccess = () => {
    handleHide();
  };

  useEffect(() => {
    if (isVisible) {
      trackEvent("AccountModal", {
        location,
      });
    }
  }, [isVisible]);

  return (
    <>
      {/* other referenced modals */}
      <Modal
        title="Add a Wallet/Exchange"
        isVisible={isVisible}
        handleHide={handleHide}
        maxW="60rem"
        marginTop={isLarge ? undefined : "0 !important"}
        w={isLarge ? "95%" : "100%"}
        contentStyle={{
          borderRadius: isLarge ? undefined : 0,
        }}
        headerProps={{
          style: {
            padding: isLarge ? "2rem 2rem 0 2rem" : "1rem",
          },
        }}
        bodyProps={{
          style: {
            padding: isLarge ? "2rem" : "1rem",
          },
        }}
        trapFocus={false}
      >
        <AccountsWorkflow onSuccess={_onSuccess} />
      </Modal>
    </>
  );
}

export const AccountModal = connectModal({
  name: "AccountModal",
})(_AccountModal);
