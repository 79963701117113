import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Heading,
  ButtonGroup,
  Center,
  Text,
  Table,
  Tr,
  Th,
  TableContainer,
  TableCaption,
  Thead,
  Tbody,
  HStack,
  useToast,
} from "@chakra-ui/react";

import WhiteBox from "src/components/styled/WhiteBox";
import { InjectedProps, show } from "redux-modal";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { compose } from "lodash/fp";
import { Member } from "./Member";
import { OrgMemberModal } from "src/components/modals/OrgMemberModal";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { api } from "src/api";
import { useClientById, useMe, useMyToast } from "src/hooks";
import {
  ClientPermission,
  CostBasisAlgorithmEnum,
  CountryEnum,
  CurrencyCodeEnum,
} from "src/api/generated/types";
import Loading from "src/views/Loading";
import { colors } from "src/theme";
import { Maybe, hasValue } from "src/core";
import { Select } from "src/components";
import StatusTag from "src/components/styled/StatusTag";
import { Button, Info } from "src/components/styled";
import { auth } from "src/utils/firebase";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
  signInWithCustomToken,
} from "firebase/auth";
import { useTheme } from "src/hooks/useTheme";

const COUNTRIES = [
  {
    label: "United States",
    value: CountryEnum.Us,
  },
  // {
  //   label: "United Kingdom",
  //   value: CountryEnum.Uk,
  // },
  {
    label: "Australia",
    value: CountryEnum.Au,
  },
  {
    label: "Germany",
    value: CountryEnum.De,
  },
  // {
  //   label: "Canada (coming soon)",
  //   value: CountryEnum.Ca,
  // },
];

const CURRENCIES = [
  { value: CurrencyCodeEnum.Aud, label: "A$ AUD" },
  { value: CurrencyCodeEnum.Cad, label: "CA$ CAD" },
  { value: CurrencyCodeEnum.Eur, label: "€ EUR" },
  { value: CurrencyCodeEnum.Gbp, label: "£ GBP" },
  { value: CurrencyCodeEnum.Usd, label: "$ USD" },
  // { value: CurrencyCodeEnum.Jpy, label: "¥ JPY" },
  // { value: CurrencyCodeEnum.Inr, label: "₹ INR" },
];

const TIMEZONE_OPTIONS = [
  // utc time
  { label: "UTC", value: "UTC" },
  { label: "Pacific Time (PST)", value: "America/Los_Angeles" },
  { label: "Mountain Time (MST)", value: "America/Denver" },
  { label: "Central Time (CST)", value: "America/Chicago" },
  { label: "Eastern Time (EST)", value: "America/New_York" },
  { label: "Hawaii Time (HST)", value: "Pacific/Honolulu" },
  // UK time
  { label: "London Time (GMT)", value: "Europe/London" },
  // Australia time
  { label: "Sydney Time (AEST)", value: "Australia/Sydney" },
  { label: "Switzerland (CET)", value: "Europe/Zurich" },
];

function Team() {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const { me } = useMe();
  const { client } = useClientById(clientId || "", {
    onlyFetchClient: true,
  });
  const [deleteMe, { loading: loadingDelete }] = useMutation(
    api.users.deleteMe
  );
  const role = me?.role || null;
  const navigate = useNavigate();

  const { data: permissionsData, loading } = useQuery<{
    getClientMembers?: ClientPermission[];
  }>(api.clientMembers.list, {
    notifyOnNetworkStatusChange: true,
    variables: { clientId },
  });

  // Modals
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  const _onClickInviteMember = useCallback(
    () => _showModal("OrgMemberModal"),
    []
  );

  const _deleteMe = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete your account? This action is irreversible."
    );

    // If the user confirms, then delete the user
    try {
      if (userConfirmed) {
        await deleteMe();

        alert(
          "Successfully deleted user account. If you have a minute, we'd love to hear about why Awaken didn't work for you. If you'd like to share your feedback, please email team@awaken.tax."
        );

        navigate("/landing");
      } else {
        // Optionally, handle the case where the user cancels the deletion
        console.log("Account deletion cancelled by the user.");
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        alert(err.message);
      }
    }
  };

  const theme = useTheme();

  const permissions: ClientPermission[] =
    permissionsData?.getClientMembers || [];

  if (loading) {
    return <Loading />;
  }

  // Filter Modal
  return (
    <div
    // style={{
    //   padding: "1rem 2.5rem",
    // }}
    >
      <OrgMemberModal />

      <ButtonGroup
        w="100%"
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Heading color={theme.header} size="md">
          Permissions
        </Heading>
        <Box />
        {/* ^ leave this here since we might want to put button in-line later. */}
        <Button
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={_onClickInviteMember}
          bgColor={theme.secondaryBackground}
          _hover={{ background: theme.ternaryBackground }}
          color={theme.header}
          marginTop="1rem"
          size="sm"
        >
          Add Member
        </Button>
      </ButtonGroup>
      <Text color={theme.text}>
        You can add people to help you label your transactions.
      </Text>

      <WhiteBox border={`1px solid ${theme.border}`} padding="0">
        <TableContainer>
          <Table variant="simple" border={`1px solid ${theme.border}`}>
            <Thead>
              <Tr>
                <Th borderColor={theme.border} color={theme.text}>
                  Name
                </Th>
                <Th borderColor={theme.border}>
                  <Center color={theme.text}>Email</Center>
                </Th>
                <Th borderColor={theme.border}>
                  <Center color={theme.text}>Activated Account</Center>
                </Th>
                <Th borderColor={theme.border}>
                  <Center borderColor={theme.border} color={theme.text}>
                    Actions
                  </Center>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {permissions.map((permission, i) => (
                <Member
                  numberOfPermissions={permissions.length}
                  isClientUser={client?.createdById === permission.userId}
                  isMe={me?.id === permission.userId}
                  isLast={permissions.length - 1 === i}
                  permission={permission}
                  key={permission.id}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </WhiteBox>

      {role !== "accountant" && (
        <Box color={theme.text} w="100%" textAlign="right" marginTop="1rem">
          Are you an accountant?{" "}
          <a
            href="mailto:team@awaken.tax?subject=I'm an accountant and I'd like to use Awaken"
            style={{
              color: colors.primary,
              textDecoration: "underline",
              fontWeight: "bold",
              marginRight: "0.3rem",
            }}
          >
            Message us{" "}
            <Info message="You can use Awaken to collaborate with many different clients. Message us and we'd be happy to share more!" />
          </a>
        </Box>
      )}

      <Security />

      <Box
        style={{
          marginTop: "3rem",
          backgroundColor: theme.background,
          border: "1px solid " + theme.border,
          borderRadius: 5,
          padding: "1rem",
        }}
      >
        <Heading color={theme.header} flex={1} size="md" margin="0">
          Tax Settings
        </Heading>

        <br />

        <SwitchTaxAlgorithm />
        <br />
        <SwitchCountry />
        <br />
        <SwitchTimezone />
        <br />
        <SwitchCurrency />
      </Box>

      <Box style={{ marginTop: 25 }}>
        <Button
          onClick={_deleteMe}
          isLoading={loadingDelete}
          style={{
            backgroundColor: colors.red50,
            color: colors.white,
          }}
        >
          Delete Account
        </Button>
      </Box>
    </div>
  );
}

const Security = () => {
  const { me } = useMe();
  const [hasTwoFactorEnabled, setHasTwoFactorEnabled] = useState(
    me?.hasTwoFactorAuth ?? false
  );
  const dispatch = useDispatch();
  const toast = useMyToast();
  const [unenroll] = useMutation(api.users.unenrollTwoFactor);
  const [updateUser] = useMutation(api.users.update);
  const theme = useTheme();

  const _setHasTwoFactorEnabled = async () => {
    // use firebase to determine if the user has 2FA enabled

    const user = auth.currentUser;

    if (!user) return false;

    const multiFactorUser = multiFactor(user);

    const enrolled = multiFactorUser.enrolledFactors.find(
      (i) => i.factorId === "phone"
    );

    const hasTwoFactorAuth = !!enrolled && enrolled.factorId === "phone";

    setHasTwoFactorEnabled(hasTwoFactorAuth);

    // just send update to backend. doesn't rlly matter just easier to query this way with postgres
    // too see how many people are using this
    void updateUser({ variables: { hasTwoFactorAuth } });
  };

  useEffect(() => {
    _setHasTwoFactorEnabled();
  }, []);

  const _onEnable2FA = async (props: InjectedProps) => {
    toast.show({
      status: "success",
      message: "Successfully enabled two-factor authentication.",
    });

    props.handleHide();

    await _setHasTwoFactorEnabled();
  };

  const _onDisable2FA = async () => {
    toast.show({
      status: "success",
      message: "Successfully disabled two-factor authentication.",
    });

    await _setHasTwoFactorEnabled();
  };

  const _handle2FA = async () => {
    // use firebase to add a 2 factor auth method to the user

    const user = auth.currentUser;

    if (!user) return;

    try {
      if (!hasTwoFactorEnabled) {
        // enable 2 factor. make them login, then open up phone verification in enroll mode

        // dispatch(
        //   show("PhoneVerificationModal", {
        //     flowType: "enroll",
        //     onSuccess: _onEnable2FA,
        //   })
        // );

        dispatch(
          show("LoginModal", {
            flowType: "enroll",
            onSuccess: async () => {
              dispatch(
                show("PhoneVerificationModal", {
                  flowType: "enroll",
                  onSuccess: _onEnable2FA,
                })
              );
            },
          })
        );

        return;
      }

      // disable two factor with firebase
      const multiFactorUser = multiFactor(user);

      const enrolled = multiFactorUser.enrolledFactors.find(
        (i) => i.factorId === "phone"
      );

      if (!enrolled) {
        return;
      }

      const unenrollResponse = await unenroll();
      const token = unenrollResponse.data?.unenrollTwoFactor;

      // resign with the token from the unenroll.
      await signInWithCustomToken(auth, token);

      _onDisable2FA();
    } catch (err) {
      console.error(err);

      toast.show({
        status: "error",
        message:
          (err as any)?.message || "Error enabling two-factor authentication.",
      });
    }
  };

  return (
    <Box
      style={{
        marginTop: "3rem",
        backgroundColor: theme.background,
        border: "1px solid " + theme.border,
        borderRadius: 5,
        padding: "1rem",
      }}
    >
      <Heading color={theme.header} flex={1} size="md" margin="0">
        Security
      </Heading>

      <br />

      {/* two factor authentication */}

      {/* make an interface that shows if two factor is enabled */}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text color={theme.text} style={{ flex: 1, maxWidth: 300 }}>
          Two-factor authentication{" "}
          {hasTwoFactorEnabled && (
            <i
              className="fa-sharp fa-check-circle"
              style={{ color: colors.primary }}
            />
          )}
        </Text>

        <Button
          background={theme.secondaryBackground}
          _hover={{ background: theme.ternaryBackground }}
          color={theme.header}
          size="sm"
          onClick={_handle2FA}
        >
          {hasTwoFactorEnabled ? "Disable" : "Enable"}
        </Button>
      </Box>
    </Box>
  );
};

const SwitchCountry = () => {
  const toast = useMyToast();
  const [updateClient] = useMutation(api.clients.update);
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const country = client?.country || CountryEnum.Us;
  const selectedOption = COUNTRIES.find(
    (a) => a.value.toLowerCase() === country.toLowerCase()
  );

  const _setOption = async (country: Maybe<CountryEnum>) => {
    try {
      await updateClient({
        variables: { clientId, country },
        refetchQueries: [api.transactions.countNotDone, api.clients.retrieve],
      });

      toast.show({
        status: "success",
        message: `Successfully updated country to ${country?.toUpperCase()}`,
      });
    } catch (err) {
      console.error(err);
      toast.show({
        status: "error",
        message:
          "Error updating country! " + (err as any)?.message ||
          "Unknown error.",
      });
    }
  };

  return (
    <Select
      options={COUNTRIES}
      label="Country"
      value={selectedOption}
      selectProps={{
        components: { IndicatorSeparator: null },
        onChange: (o: any) => _setOption(o.value as Maybe<CountryEnum>),
      }}
      containerStyle={{
        width: "15rem",
        marginBottom: 0,
      }}
    />
  );
};

const SwitchCurrency = () => {
  const toast = useMyToast();
  const [updateClient] = useMutation(api.clients.update);
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const currency = client?.currency || CurrencyCodeEnum.Usd;
  const selectedOption = CURRENCIES.find(
    (a) => a.value.toLowerCase() === currency.toLowerCase()
  );

  const _setOption = async (currency: Maybe<CurrencyCodeEnum>) => {
    try {
      await updateClient({
        variables: { clientId, currency },
        refetchQueries: [api.transactions.countNotDone, api.clients.retrieve],
      });

      toast.show({
        status: "success",
        message: `Successfully updated currency to ${currency?.toUpperCase()}`,
      });
    } catch (err) {
      console.error(err);
      const message = (err as any)?.message || "";
      toast.show({
        status: "error",
        message: "Error updating currency! " + message,
      });
    }
  };

  return (
    <Select
      options={CURRENCIES}
      value={selectedOption}
      label="Currency"
      selectProps={{
        components: { IndicatorSeparator: null },
        onChange: (o: any) => _setOption(o.value as Maybe<CurrencyCodeEnum>),
      }}
      containerStyle={{
        width: "15rem",
        marginBottom: 0,
      }}
    />
  );
};

const SwitchTaxAlgorithm = () => {
  const toast = useMyToast();
  const { me } = useMe();
  const theme = useTheme();

  const isSuperUser = me?.isSuperuser || false;
  const ALGOS = [
    { value: CostBasisAlgorithmEnum.Fifo, label: "FIFO" },
    { value: CostBasisAlgorithmEnum.Hifo, label: "HIFO" },
    { value: CostBasisAlgorithmEnum.Lifo, label: "LIFO" },
    // {
    //   value: CostBasisAlgorithmEnum.SharePooling,
    //   label: "Share Pooling (not available)",
    // },
  ].filter(hasValue);

  const [updateAlgo] = useMutation(api.clients.updateClientCostBasisAlgorithm);
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const currentBasisAlgo =
    client?.costBasisAlgorithm || CostBasisAlgorithmEnum.Fifo;
  const selectedOption = ALGOS.find(
    (a) => a.value.toLowerCase() === currentBasisAlgo.toLowerCase()
  );

  const _setOption = async (algo: Maybe<CostBasisAlgorithmEnum>) => {
    try {
      await updateAlgo({
        variables: { clientId, costBasisAlgorithm: algo },
        refetchQueries: [api.transactions.countNotDone, api.clients.retrieve],
      });

      toast.show({
        status: "success",
        message: `Successfully updated tax algorithm to ${algo?.toUpperCase()}`,
      });
    } catch (err) {
      console.error(err);
      toast.show({
        status: "error",
        message: "Error updating tax algorithm!",
      });
    }
  };

  return (
    <HStack display="flex" margin="0" alignItems="center">
      {/* <StatusTag
        label="Tax Algorithm"
        infoMessage="If you used FIFO in previous years, you should use it this year too. The same goes for LIFO and HIFO. Not tax advice. Consult a tax specialist for more information."
        type="info"
        boxProps={{ border: "1px solid " + colors.lightBlue70 }}
        iconName="fa-sharp fa-info-circle"
      /> */}

      <Select
        options={ALGOS}
        value={selectedOption}
        label="Tax Algorithm"
        selectProps={{
          styles: {
            control: (provided) => ({
              ...provided,
              backgroundColor: theme.background,
              borderColor: theme.border,
            }),
            indicatorSeparator: (provider) => ({
              ...provider,
              display: "none",
            }),
            placeholder: (p) => ({
              ...p,
              color: theme.text,
            }),
            singleValue: (p) => ({ ...p, color: theme.header }),
          },
          onChange: (o: any) =>
            _setOption(o.value as Maybe<CostBasisAlgorithmEnum>),
        }}
        containerStyle={{
          width: "15rem",
          marginBottom: 0,
        }}
      />
    </HStack>
  );
};

const SwitchTimezone = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId || "", {
    onlyFetchClient: true,
  });
  const toast = useMyToast();
  const [updateClient] = useMutation(api.clients.update);

  const timezone = client?.timezone;
  const timezoneOption = useMemo(
    () =>
      !timezone
        ? TIMEZONE_OPTIONS[0]
        : TIMEZONE_OPTIONS.find((option) => option.value === timezone) || null,
    [timezone]
  );

  const _updateTimezone = async (timezone: Maybe<string>) => {
    try {
      await updateClient({
        variables: { clientId, timezone },
      });

      toast.show({
        message:
          "Timezone successfully updated! When you pull reports and recalculate all your numbers will be in your specified timezone.",
        status: "success",
      });
    } catch (err) {
      console.error(err);
      toast.show({
        message: "Error updating timezone",
        status: "error",
      });
    }
  };

  return (
    <Select
      options={TIMEZONE_OPTIONS}
      value={timezoneOption}
      placeholder="Timezone"
      label="Timezone"
      selectProps={{
        onChange: (o: any) => _updateTimezone(o.value as Maybe<string>),
      }}
      containerStyle={{
        width: "15rem",
        marginBottom: 0,
      }}
    />
  );
};

export default Team;
