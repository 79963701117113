import React, { useCallback, useEffect } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "src/assets/awaken/logos/logo-name.png";
import WhiteLogo from "src/assets/awaken/logos/white-logo.png";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "src/components";
import { colors, other } from "src/theme";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { MutationCreateUserWithEmailArgs } from "src/api/generated/types";
import { auth } from "src/utils/firebase";
import { BaseUserFields } from "src/api/fragments";
import { sendPasswordResetEmail, signInWithCustomToken } from "firebase/auth";
import { getRedirectPath } from "../utils";
import { GoogleButton } from "../components/Google";
import { useMyToast } from "src/hooks";
import { useLocalStorage } from "src/hooks/common";
import { isNil } from "lodash/fp";
import SecondaryText from "src/components/styled/SecondaryText";
import { useTheme } from "src/hooks/useTheme";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required.").nullable(),
});

type FormValues = {
  email: string;
};

const DEFAULT_VALUES: FormValues = {
  email: "",
};

export function ForgotPassword() {
  const [search] = useSearchParams();
  const toast = useMyToast();
  const navigate = useNavigate();
  const theme = useTheme();

  // Form hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { ...DEFAULT_VALUES },
  });

  const _logAuthError = ({ message }: { message: string }) => {
    toast.show({ message, status: "error" });
  };

  // Functions
  const _submitForgotPassword = useCallback(
    async (
      values: FormValues
    ): Promise<FailureOrSuccess<DefaultErrors, null>> => {
      try {
        await sendPasswordResetEmail(auth, values.email);

        toast.show({
          message:
            "Password reset email sent! If you don't see it, check your spam folder.",
          status: "success",
        });

        return success(null);
      } catch (err) {
        return failure(new UnexpectedError(err));
      }
    },
    []
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      await _submitForgotPassword(values);
    },
    [_submitForgotPassword]
  );

  return (
    <NoNavBarPageTemplate>
      <VStack
        padding="2rem 0"
        flexDir="column"
        minH="100vh"
        display="flex"
        justifyContent="center"
      >
        <FreeTransactions />
        <Box
          display="flex"
          flexDir="column"
          maxWidth="30rem"
          margin="auto"
          padding="2rem"
          w="100%"
          borderRadius={other.borderRadius}
          bg={theme.background}
          border={`1px solid ${theme.border}`}
        >
          <form
            onSubmit={handleSubmit(onSubmit, (e) =>
              toast.show({
                status: "error",
                message: e.email?.message || "",
              })
            )}
          >
            <Link to="/">
              <Center>
                <Image
                  src={theme.theme === "light" ? Logo : WhiteLogo}
                  cursor="pointer"
                  w="10rem"
                  paddingTop="1rem"
                  paddingBottom="2rem"
                />
              </Center>
            </Link>

            <Box width="100%">
              <Input
                label="Email"
                isRequired
                autoComplete="email"
                control={control}
                name="email"
              />
            </Box>
            <br />

            <Button
              isLoading={isSubmitting}
              width="100%"
              variant="primary"
              type="submit"
              padding="1rem 2rem"
            >
              Send Password Reset Email
            </Button>

            <Divider margin="2rem auto" />

            <Text color={theme.text} margin="auto">
              Already have an account?&nbsp;
              <Box display="inline-block" textDecor="underline" color="black">
                <Link to="/login">
                  <Text color={colors.primary}>Log in</Text>
                </Link>
              </Box>
            </Text>
          </form>
        </Box>
      </VStack>
    </NoNavBarPageTemplate>
  );
}

const FreeTransactions = () => {
  const [search] = useSearchParams();
  const refCode = search.get("ref");

  if (!refCode || refCode === "undefined" || refCode === "null") return null;

  return (
    <Box
      w="100%"
      maxWidth="30rem"
      style={{
        color: colors.white,
        background: `linear-gradient(30deg, ${colors.green60} 0%, ${colors.green50} 50%, ${colors.green70} 100%)`,
        padding: "0.75rem 0.5rem",
        borderRadius: 5,
        fontWeight: "600",
        margin: "0.5rem auto",
        fontSize: 15,
        textAlign: "center",
      }}
    >
      You are unlocking <b>FREE</b> credit with code {refCode} 🔑
    </Box>
  );
};
