import { config } from "src/config";
import { Maybe } from "src/core";

export type PriceThreshold = {
  min: Maybe<number>;
  max: Maybe<number>;
  price: Maybe<number>;
  emoji: string;
  gradient: string;
  numIcons: number;
  iconName: string;
};

export const THRESHOLDS: PriceThreshold[] = [
  {
    min: null,
    max: 25,
    emoji: "🥚",
    price: 0,
    gradient: "linear-gradient(45deg, #632706 0%, #b34509 50%, #db4f02 100%)",
    numIcons: 1,
    iconName: "fa-sharp fa-coin-blank",
  },
  {
    min: 26,
    max: 150,
    price: 75,
    emoji: "🐟",
    gradient: "linear-gradient(45deg, #A770EF 0%, #CF8BF3 50%, #fdb99b 100%)",
    numIcons: 1,
    iconName: "fa-sharp fa-coin-blank",
  },
  {
    min: 151,
    max: 1_500,
    price: 199,
    emoji: "🐬",
    gradient: "linear-gradient(45deg, #c71432 0%, #fd1d1d 50%, #fcb045 100%)",
    numIcons: 2,
    iconName: "fa-sharp fa-coin-blank",
  },
  {
    min: 1_501,
    max: 3_000,
    price: 299,
    emoji: "🐳",
    gradient: "linear-gradient(45deg, #1FA2FF 0%, #12D8FA 50%, #A6FFCB 100%)",
    numIcons: 1,
    iconName: "fa-sharp fa-coins",
  },
  {
    min: 3_001,
    max: 5_000,
    price: 399,
    emoji: "🐋",
    gradient: "linear-gradient(45deg, #75F09E 0%, #00CC44 50%, #75F09E 100%)",
    numIcons: 2,
    iconName: "fa-sharp fa-coins",
  },
  // {
  //   min: 5_001,
  //   max: 10_000,
  //   price: 600,
  //   emoji: "🔱",
  //   gradient: "linear-gradient(45deg, #FABC3C 0%, #FACC6B 50%, #F0AC6B 100%)",
  //   numIcons: 3,
  //   iconName: "fa-sharp fa-coins",
  // },
  {
    min: 5_001,
    max: 1_000_000,
    price: 599,
    emoji: "👑",
    // black gradient
    gradient: "linear-gradient(45deg, #000000 0%, #000000 50%, #000000 100%)",
    numIcons: 4,
    iconName: "",
  },
];
