import { useApolloClient, useLazyQuery } from "@apollo/client";
import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import FileIcon from "src/assets/icons/file-icon.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  Query,
  QueryGetReportExportArgs,
  ReportExportTypeEnum,
} from "src/api/generated/types";
import { ActionSheet, Button, Info, Input } from "src/components";
import WhiteBox from "src/components/styled/WhiteBox";
import WhiteBoxImage from "src/components/styled/WhiteBoxImage";
import { Maybe } from "src/core";
import { useMe, useMyToast } from "src/hooks";
import { useDownloadFile } from "src/hooks/common/useDownloadFile";
import { colors, other } from "src/theme";
import Fuse from "fuse.js";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { compose, isNil } from "lodash/fp";
import SecondaryText from "src/components/styled/SecondaryText";
import { BaseUserFields } from "src/api/fragments";
import StatusTag from "src/components/styled/StatusTag";
import { isSafari } from "react-device-detect";
import { Warning } from "src/components/Warning";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { isFree } from "src/utils/free";
import { getAccountsLink } from "src/modules/ledger/transactions";
import { useTheme } from "src/hooks/useTheme";

const CAP_GAINS_REPORT_NAME = "Capital Gains";

type ReportInfoType = {
  type: Maybe<ReportExportTypeEnum>;
  name: string;
  disableExport?: boolean;
  disableMessage?: string;
  onlySuperUser?: boolean;
  demoUrl?: string;
  description?: string;
  isPopular?: boolean;
  useCaseDescription?: string;
};

const REPORTS: ReportInfoType[] = [
  {
    type: ReportExportTypeEnum.CoinBreakdown,
    name: CAP_GAINS_REPORT_NAME,
    description: "A CSV of all of your capital gains/losses.",
    isPopular: true,
    useCaseDescription:
      "Send this to your accountant to give them a detailed breakdown of your trades. Usually people send this and the 8949 to their accountants.",
  },
  {
    type: ReportExportTypeEnum.TurboTax1099B,
    name: "TurboTax 1099-B",
    demoUrl: "https://www.loom.com/share/ef3153dc518a46ec9581484f11d909e0",
    description:
      "This does not include perpetuals/futures trades, so make sure to pull that CSV separately if you have traded futures!",
    isPopular: true,
    useCaseDescription: "You can upload this directly to TurboTax.",
  },
  {
    type: ReportExportTypeEnum.Irs8949,
    name: "Form 8949",
    description:
      "The 8949 is a PDF form that reports any disposals of capital assets. This does not include perpetuals/futures trades, so make sure to pull that CSV separately if you have traded futures!",
    // onlySuperUser: true,
    isPopular: true,
    useCaseDescription:
      "You can send this to your accountant. They'll attach it to your tax return.",
  },

  {
    type: ReportExportTypeEnum.SummaryReport,
    name: "Summary Report",
    isPopular: true,
    description:
      "PDF including income, expenses, and capital gains for the year. This does not include perpetuals/futures trades, so make sure to pull that CSV separately if you have traded futures!",
  },
  {
    type: ReportExportTypeEnum.TurboTaxAggregated1099B,
    name: "TurboTax 1099-B Aggregated",
    demoUrl: "https://www.loom.com/share/7b002865bc834c0aadb9f63e3cdcca02",
    description: "If you have over 4,000 transactions in the year.",
  },
  {
    type: ReportExportTypeEnum.TaxAct1099B,
    name: "TaxAct 1099-B",
    description: "Note: this only works for TaxAct on Microsoft Windows.",
  },
  {
    type: ReportExportTypeEnum.Futures,
    name: "Perpetuals/Futures Report",
    description:
      "We separate out futures trades because they are taxed differently depending on your jurisdiction.",
  },
  {
    type: ReportExportTypeEnum.TransactionHistoryReport,
    name: "Transaction History",
    description: "CSV of all transactions with sent/received transfers.",
  },
  {
    type: ReportExportTypeEnum.AssetRollupBreakdown,
    name: "Capital Gains (Breakdown by Asset)",
    description:
      "A CSV with your total proceeds, basis, and gain/loss per asset.",
  },
  {
    type: ReportExportTypeEnum.AssetBreakdownReport,
    name: "Transactions Per Asset",
    description: "An xlsx file with separate sheets for trades for each asset.",
  },
  {
    type: ReportExportTypeEnum.IncomeReport,
    name: "Income Report",
    description: "CSV of all transactions where you received income.",
  },
];

const options: Fuse.IFuseOptions<ReportInfoType> = {
  includeScore: true,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.4,
  keys: ["name"],
};

const fuse = new Fuse(REPORTS, options);

export const ReportDownloadOptions = ({
  year,
  hasPaidForPeriod,
  totalTxnForYear,
  maxWNum,
  numImportantUnlabeled,
  hasOutOfDateAccounts,
}: {
  year: Maybe<string>;
  hasPaidForPeriod: boolean;
  totalTxnForYear: Maybe<number>;
  maxWNum: number;
  numImportantUnlabeled: number;
  hasOutOfDateAccounts: boolean;
}) => {
  const { border, theme, header, secondaryBackground } = useTheme();
  const [search, setSearch] = useState("");
  const validReports = useMemo(() => fuse.search(search), [search]);
  const _reports = search ? validReports.map((v) => v.item) : REPORTS;
  const { me: _me } = useMe("cache-first");
  const isSuperUser = _me?.isSuperuser || false;
  const isLarge = useIsLargeScreen();

  // gotta disable turbotax because more than 4000 txns
  const reports = _reports.map((r): ReportInfoType => {
    if (r.type === ReportExportTypeEnum.TurboTax1099B) {
      const disabled = totalTxnForYear === null || totalTxnForYear >= 4000;
      return {
        ...r,
        disableExport: disabled,
        disableMessage: disabled
          ? "TurboTax only allows you to upload less than 4,000 transactions for a single tax year. Please download the TurboTax 1099B Aggregated instead!"
          : "",
      };
    }
    return r;
  });

  return (
    <WhiteBox border={`1px solid ${border}`} w="100%">
      <Heading color={header} size="md" marginTop="0.5rem" paddingTop="0">
        Tax Reports ({year})
      </Heading>
      <SecondaryText
        text="Awaken offers U.S. tax forms, but you may be able to modify them to support your country's requirements."
        marginBottom="1rem"
      />

      {isLarge ? (
        <Grid templateColumns={{ base: "repeat(2, 1fr)" }} gap={2}>
          <GridItem colSpan={2}>
            <Input
              // maxW={`${maxWNum / 2 - 2}rem`}
              flex={1}
              containerStyle={{ marginBottom: 0 }}
              value={search}
              bg={secondaryBackground}
              placeholder="Search..."
              border={`1px solid ${border} !important`}
              onChange={(e) => setSearch(e.target.value)}
              focusBorderColor={colors.gray70}
              color={header}
              iconLeft={
                <i
                  style={{ color: colors.gray50, fontSize: 14 }}
                  className="fa-sharp fa-search"
                />
              }
            />
            <Divider style={{ margin: "1.5rem 0", borderColor: border }} />
          </GridItem>

          <GridItem colSpan={2}>
            <Warning
              marginBottom="1.5rem"
              iconName="fa-sharp fa-info-circle"
              bg={theme === "dark" ? colors.lightBlue10 : colors.lightBlue100}
              borderColor={colors.primary}
              iconStyle={{ color: colors.primary }}
              style={{
                color: header,
              }}
              message={
                <>
                  If you use{" "}
                  <img
                    src={require("src/assets/awaken/icons/turbotax.jpeg")}
                    style={{
                      width: 15,
                      display: "inline-block",
                      height: 15,
                      borderRadius: 100,
                      objectFit: "contain",
                    }}
                  />{" "}
                  TurboTax, please watch{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                    href="https://www.loom.com/share/49dafa2133304eff9f98e68cf7db3418"
                  >
                    this tutorial
                  </a>{" "}
                  on how to add your crypto Income.
                </>
              }
            />
          </GridItem>

          {reports.map((report, index) => (
            <GridItem colSpan={{ base: 2, lg: 1 }} key={index}>
              <Report
                year={year}
                hasPaidForPeriod={hasPaidForPeriod}
                _me={_me}
                isAvailable={report.onlySuperUser === true ? isSuperUser : true}
                numImportantUnlabeled={numImportantUnlabeled}
                demoUrl={report.demoUrl || null}
                report={report}
                {...report}
                hasOutOfDateAccounts={hasOutOfDateAccounts}
              />
            </GridItem>
          ))}
        </Grid>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Text fontSize={16}>
            You can only download tax reports on desktop{" "}
            <i
              style={{ fontSize: 16, marginLeft: "0.5rem" }}
              className="fa-sharp fa-desktop"
            />
          </Text>
        </div>
      )}
    </WhiteBox>
  );
};

type ReportProps = BoxProps & {
  name: string;
  type: Maybe<ReportExportTypeEnum>;
  year: Maybe<string>;
  hasPaidForPeriod: boolean;
  _me: Maybe<BaseUserFields>;
  numImportantUnlabeled: number;
  isAvailable: boolean;
  demoUrl: Maybe<string>;
  report: ReportInfoType;
  hasOutOfDateAccounts: boolean;
};

function Report({
  type,
  name,
  year,
  hasPaidForPeriod,
  _me,
  numImportantUnlabeled,
  isAvailable,
  demoUrl,
  report,
  hasOutOfDateAccounts,
  ...boxProps
}: ReportProps) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const { download } = useDownloadFile();
  const navigate = useNavigate();

  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  const { header, text, border } = useTheme();
  const free = isFree();

  const _showPricingModal = () => {
    _showModal("CheckoutModal", { canCheckout: true });
  };

  const [fetchExport, { data }] = useLazyQuery<
    { getReportExport: Query["getReportExport"] },
    QueryGetReportExportArgs
  >(api.reports.export, {
    fetchPolicy: "network-only",
  });

  const [isExporting, setIsExporting] = useState(false);

  const _exportReport = async (
    clientId: string,
    type: ReportExportTypeEnum,
    year: string
  ) => {
    setIsExporting(true);

    // toast.show({
    //   message: "Successfully started export. This may take a minute... ⏱",
    //   status: "success",
    // });

    try {
      const response = await fetchExport({
        variables: { clientId, type, year, forceSendEmail: isSafari },
      });

      const downloadUrl = response.data?.getReportExport?.downloadUrl;
      const message = response.data?.getReportExport?.message;

      if (message) {
        toast.show({
          status: "success",
          message,
        });
      }

      if (downloadUrl) {
        download(downloadUrl);
      }

      apolloClient.refetchQueries({ include: [api.reports.getReports] });

      dispatch(
        show("PleaseShareModal", {
          title:
            "Thank you so much for being an Awaken customer and downloading a tax report!",
        })
      );
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred",
        status: "error",
      });
    } finally {
      setIsExporting(false);
    }
  };

  const _tryExportReport = async (
    type: Maybe<ReportExportTypeEnum>
  ): Promise<void> => {
    // if free is available and haven't paid, open up that modal
    if (free && !hasPaidForPeriod) {
      _showModal("FreeSubscriptionModal");
      return;
    }

    if (!hasPaidForPeriod && !_me?.isSuperuser) {
      _showPricingModal();
      return;
    }

    if (!clientId || !type || !year) {
      return;
    }

    try {
      console.log("hasOutOfDateAccounts", hasOutOfDateAccounts);
      // if (hasOutOfDateAccounts) {
      //   // if (
      //   //   window.confirm(
      //   //     "Some of your accounts are out of date. You must go to your Accounts page, update the out of date ones, and refresh this page to download your tax reports!"
      //   //   )
      //   // ) {
      //   //   navigate(getAccountsLink(clientId));
      //   //   return;
      //   // } else {
      //   //   return;
      //   // }
      // }
      if (numImportantUnlabeled > 0) {
        _showModal("ReportExportWarningModal", {
          numImportantUnlabeled,
          type,
          year,
          exportReport: _exportReport,
        });
      } else {
        return await _exportReport(clientId, type, year);
      }
    } catch (err) {
      console.error(err);
      return toast.show({
        message:
          "Error exporting report! Message support and we'll help you out!",
        status: "error",
      });
    }
  };

  const GenerateButton = () => {
    const { secondaryBackground, header } = useTheme();

    return (
      <Button
        onClick={async () => await _tryExportReport(type)}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={secondaryBackground}
        color={header}
        fontSize="sm"
        isLoading={isExporting}
        padding="0.75rem 1.25rem"
        maxWidth="125px"
        disabled={report.disableExport}
        _hover={{
          bg: secondaryBackground,
          opacity: 0.8,
          color: header,
        }}
        visibility={type ? "visible" : "hidden"}
      >
        Generate
      </Button>
    );
  };

  return (
    <Box
      {...boxProps}
      borderRadius={7}
      h="100%"
      padding="1rem 1rem 0.5rem 1rem"
      display="flex"
      flexDir="column"
      justifyContent="center"
      border={"1px solid " + border}
      // do a slight box shadow to make it look like it's floating
      boxShadow="0 0 0 1px rgba(0,0,0,0.05)"
    >
      <HStack
        h="90px"
        width="100%"
        alignItems="flex-start"
        justifyContent="center"
      >
        {/* <i
          className="fa-sharp fa-file-spreadsheet"
          style={{ color: colors.primary, fontSize: 24, marginLeft: "1rem" }}
        ></i> */}
        <Box flex={1}>
          <HStack>
            <Heading color={header} size="sm" margin="0" padding="0" flex={1}>
              {name}{" "}
            </Heading>
            {report.isPopular && (
              <Tooltip label={report.useCaseDescription}>
                <div>
                  <StatusTag
                    type="beta"
                    label="Popular"
                    iconName="fa-sharp fa-star"
                  />
                </div>
              </Tooltip>
            )}
          </HStack>

          {!isNil(report.description) && (
            <Text
              fontSize="sm"
              marginTop="0.5rem"
              color={text}
              fontWeight="500"
            >
              {report.description}
            </Text>
          )}

          {!isNil(demoUrl) && (
            <Text
              fontSize="sm"
              marginTop="0.25rem"
              color={text}
              fontWeight="500"
            >
              Confused?{" "}
              <a
                style={{
                  textDecoration: "underline",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
                href={demoUrl}
              >
                Watch our video tutorial 🎥
              </a>
            </Text>
          )}
        </Box>
      </HStack>

      <Divider style={{ margin: "1rem 0", borderColor: border }} />

      <HStack>
        <Flex flex={1}>
          <StatusTag
            label="Ready"
            boxProps={{
              style: { marginBottom: "0.5rem" },
            }}
            infoMessage="This report is ready for download!"
            type="success"
            iconName="fa-sharp fa-check-circle"
          />
        </Flex>

        {isAvailable && (
          <VStack alignItems="flex-end">
            {report.disableMessage ? (
              <Tooltip placement="bottom-end" label={report.disableMessage}>
                <div>
                  <GenerateButton />
                </div>
              </Tooltip>
            ) : (
              <GenerateButton />
            )}
          </VStack>
        )}
      </HStack>
    </Box>
  );
}
