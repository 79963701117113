import React, { useCallback } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
  Box,
  Center,
  Text,
  Image,
  HStack,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "src/assets/awaken/logos/logo-name.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "src/components";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AuthenticationService,
  AuthenticationType,
} from "src/modules/authentication";
import { BaseUserFields } from "src/api/fragments";
import { MyToast } from "src/components/MyToast";
import { useMe, useMyToast } from "src/hooks";
import { MutationCreateUserArgs } from "src/api/generated/types";
import { noop } from "lodash/fp";
import { getMetadataInfo, isWhiteLabeledDomain } from "src/utils/whitelabel";
import { InjectedProps, connectModal, show } from "redux-modal";
import { GoogleButton } from "src/views/Authentication/components/Google";
import { Modal } from "src/components/Modal";
import { FirebaseError } from "firebase/app";
import { useDispatch } from "react-redux";
import { MultiFactorError, getMultiFactorResolver } from "firebase/auth";
import { auth } from "src/utils/firebase";
import { AppleButton } from "src/views/Authentication/components/Apple";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required.").nullable(),
  password: yup.string().min(6).required("Password is required.").nullable(),
});

type FormValues = {
  email: string;
  password: string;
};

const DEFAULT_VALUES: FormValues = {
  email: "",
  password: "",
};

type LoginModalProps = InjectedProps & {
  onSuccess: (user?: BaseUserFields) => void;
  flowType: "sign_in" | "enroll" | "unenroll";
};

function _LoginModal(props: LoginModalProps) {
  const toast = useMyToast();
  const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me, {
    fetchPolicy: "no-cache",
  });
  const [createUser] = useMutation<{ createUser?: BaseUserFields }>(
    api.users.create
  );
  const isWhiteLabel = isWhiteLabeledDomain();
  const dispatch = useDispatch();

  // Form hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_VALUES,
  });

  const _onSuccessLogin = async (user?: BaseUserFields) => {
    await props.onSuccess(user);
    props.handleHide();
  };

  const _parseFirebaseError = (message: string, error: any) => {
    if (error instanceof FirebaseError) {
      const { code } = error;

      if (code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(
          auth,
          error as MultiFactorError
        );

        dispatch(
          show("PhoneVerificationModal", {
            flowType: props.flowType || "sign_in",
            multiFactorError: error,
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
            onSuccess: async (props: InjectedProps) => {
              props.handleHide();
              await _onSuccessLogin();
            },
          })
        );
        return;
      }
    }

    toast.show({ message, status: "error" });
  };

  const _logAuthError = ({
    message,
    error,
  }: {
    message: string;
    error: any;
  }) => {
    _parseFirebaseError(message, error);
  };

  const onSubmit = useCallback(async (values: FormValues) => {
    const authResponse = await AuthenticationService.login({
      type: AuthenticationType.EmailAndPassword,
      email: values.email.trim().toLowerCase(),
      password: values.password,
    });

    if (authResponse.isFailure()) {
      console.log(authResponse);
      _parseFirebaseError(authResponse.error.message, authResponse.error);
      return;
    }

    try {
      const authedUser = authResponse.value;
      const res = await getMe();

      // if we are enrolling but the user hasn't verified their email, we need them to do that first
      if (props.flowType === "enroll" && !authedUser.user.emailVerified) {
        dispatch(
          show("VerifyEmailModal", {
            onSuccess: () => _onSuccessLogin(res.data?.me),
          })
        );

        return;
      }

      return _onSuccessLogin(res.data?.me);
    } catch (err) {
      return toast.show({
        status: "error",
        message: (err as Error)?.message || "An unknown error occurred.",
      });
    }
  }, []);

  const metadata = getMetadataInfo();

  return (
    <Modal
      title={<Text fontSize="2xl">Login</Text>}
      isVisible={props.show}
      handleHide={props.handleHide}
    >
      <HStack
        w="100%"
        style={{
          paddingBottom: "2rem",
        }}
      >
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%">
            <Input label="Email" isRequired control={control} name="email" />
            <Input
              label="Password"
              isRequired
              control={control}
              type="password"
              name="password"
            />
          </Box>
          <br />
          <Button
            isLoading={isSubmitting}
            width="100%"
            variant="primary"
            type="submit"
            padding="1rem 2rem"
          >
            Log in
          </Button>
          <br /> <br />
          <GoogleButton
            label="Sign in"
            referralCode={null}
            onSuccess={_onSuccessLogin}
            onError={_logAuthError}
          />
          <br /> <br />
          <AppleButton
            label="Sign in"
            referralCode={null}
            onSuccess={_onSuccessLogin}
            onError={_logAuthError}
          />
        </form>
      </HStack>
    </Modal>
  );
}

export const LoginModal = connectModal({
  name: "LoginModal",
})(_LoginModal);
