import { isNil } from "lodash/fp";
import { Maybe } from "src/core";
import { useURLSearch } from "./useURLSearch";

const DEFAULT_LIMIT = 20;

export type TransactionSearchFilters = {
  page?: Maybe<number>;
  limit?: Maybe<number>;
  search?: Maybe<string>;
  reviewed?: Maybe<string>;
  labeledPriority?: Maybe<number>;
  assetType?: Maybe<string>;
  hasIncome?: Maybe<boolean>;
  hasNotes?: Maybe<boolean>;
  startDate?: Maybe<Date | string>;
  endDate?: Maybe<Date | string>;
  accountIds?: Maybe<string[]>;
  providers?: Maybe<string[]>;
  sortBy?: Maybe<string>;
  ascending?: Maybe<string>;
  assetIds?: Maybe<string[]>;
  labels?: Maybe<string[]>;
  assetSymbolOrName: Maybe<string>;
  isMissingBasis?: Maybe<boolean>;
  assetKey?: Maybe<string>;
  includeSpam?: Maybe<boolean>;
};

export const useTransactionSearch = () => {
  const parseFilters = (
    urlSearch: URLSearchParams
  ): TransactionSearchFilters => {
    const accountIds = urlSearch.get("accountIds");
    const isMissingBasis = urlSearch.get("isMissingBasis");
    const hasIncome = urlSearch.get("hasIncome");
    const hasNotes = urlSearch.get("hasNotes");
    const assetKey = urlSearch.get("assetKey");
    const providers = urlSearch.get("providers");
    const assetIds = urlSearch.get("assetIds");
    const labels = urlSearch.get("labels");
    // only set to true, otherwise make it null no matter what
    const includeSpam = urlSearch.get("includeSpam") === "true";
    const labeledPriority = urlSearch.get("labeledPriority");

    const query = {
      page: parseInt(urlSearch.get("page") || "0"),
      limit: parseInt(urlSearch.get("limit") || DEFAULT_LIMIT.toString()),
      search: urlSearch.get("search"),
      startDate: urlSearch.get("startDate"),
      reviewed: urlSearch.get("reviewed"),
      labeledPriority: labeledPriority ? parseInt(labeledPriority) : null,
      assetType: urlSearch.get("assetType"),
      hasIncome: isNil(hasIncome) ? null : hasIncome === "true",
      hasNotes: isNil(hasNotes) ? null : hasNotes === "true",
      isMissingBasis: isNil(isMissingBasis) ? null : isMissingBasis === "true",
      endDate: urlSearch.get("endDate"),
      accountIds: accountIds ? accountIds.split(",") : null,
      providers: providers ? providers.split(",") : null,
      sortBy: urlSearch.get("sortBy") || null,
      ascending: urlSearch.get("ascending") || null,
      assetSymbolOrName: urlSearch.get("assetSymbolOrName") || null,
      assetIds: assetIds ? assetIds.split(",") : null,
      labels: labels ? labels.split(",") : null,
      assetKey: assetKey || null,
      includeSpam: includeSpam,
    };

    return query;
  };

  const { filters, hasFilters, updateFilters, clearFilters } =
    useURLSearch<TransactionSearchFilters>({
      parseFilters,
    });

  return {
    filters,
    hasFilters,
    updateFilters,
    clearFilters,
  };
};
