import { Button, Container, Text, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useClientById, useMyToast } from "src/hooks";
import { MutationFreeSubscriptionArgs } from "src/api/generated/types";
import { useParams } from "react-router-dom";
import { colors } from "src/theme";
import { useDispatch } from "react-redux";
import { setShowConfetti } from "src/redux/reducers/globalState";
import { trackEvent } from "src/utils/analytics";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  title: string;
};

function _PleaseShareModal({ handleHide, title, show: isVisible }: Props) {
  const toast = useMyToast();
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const theme = useTheme();

  const _copyLink = () => {
    if (!client) return;

    const referralCode = client?.referralCode || "";

    navigator.clipboard.writeText(`https://awaken.tax?ref=${referralCode}`);

    toast.show({
      message:
        "Copied sharable link to clipboard! You can paste it in discord/imessage/twitter or wherever you want.",
      status: "info",
    });

    void trackEvent("Referral Link Copied", { referralCode });
  };

  const onHide = () => {
    handleHide();
  };

  return (
    <Modal
      title="Thanks for using Awaken!"
      titleHeaderProps={{
        fontSize: 24,
        color: theme.header,
      }}
      marginTop="1.5rem"
      isVisible={isVisible}
      handleHide={onHide}
      Footer={
        <Button
          width="100%"
          bg={colors.green50}
          color={colors.white}
          marginBottom="0.5rem"
          marginTop="1.5rem"
          _hover={{ bg: colors.green40 }}
          onClick={_copyLink}
        >
          Share Awaken with friends{" "}
          <i
            style={{
              marginLeft: 15,
              position: "absolute",
              right: 20,
              top: 10,
              fontSize: 20,
            }}
            className="fa-sharp fa-arrow-right"
          />
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Text color={theme.text} fontSize="lg">
          {title}
          <br /> <br />
          If you know anyone who needs help with their crypto taxes, please tell
          them about us.
          <br />
          <br />
          You all are the fuel that helps us grow ❤️
        </Text>

        {/* <Textarea 
          value={notes}
          noOfLines={5}
          onChange={(e) => setNotes(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        /> */}
      </Container>
    </Modal>
  );
}

export const PleaseShareModal = connectModal({
  name: "PleaseShareModal",
})(_PleaseShareModal);
