import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Heading,
  Button,
  ButtonGroup,
  Center,
  Table,
  Tr,
  Th,
  TableContainer,
  TableCaption,
  Thead,
  Tbody,
  HStack,
  Text,
  Spinner,
  Progress,
  Flex,
  Tooltip,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  VStack,
  Checkbox,
  Switch,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import CountUp from "react-countup";

import WhiteBox from "src/components/styled/WhiteBox";
import { show } from "redux-modal";
import { useDispatch, useSelector } from "react-redux";
import { FilterTxnsModal } from "src/components/modals/FilterModal";
import { ToastType, useClientById, useMyToast } from "src/hooks";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Transaction } from "./Transaction";
import { compose, keyBy, noop } from "lodash/fp";
import {
  TransactionSearchFilters,
  useTransactionSearch,
} from "src/hooks/useTransactionSearch";
import {
  ApolloError,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { api, apolloClient } from "src/api";
import {
  AccountTypeEnum,
  AssetTypeEnum,
  NumTxnsResponse,
} from "src/api/generated/types";
import { colors, other } from "src/theme";
import { useDownloadFile, useInterval, usePrevious } from "src/hooks/common";
import Paginate from "src/components/styled/Table/Paginate";
import { CreateTxnModal } from "src/components/modals/CreateTxnModal";
import {
  ActionSheet,
  AwakenTooltip,
  Copy,
  Info,
  Input,
  Option,
  Select,
} from "src/components";
import { Touchable } from "src/components/Touchable";
import { RECALCULATING_HELP_MESSAGE, SYNC_HELP_MESSAGE } from "src/config";
import { hasValue, Maybe } from "src/core";
import { CogsAnimation } from "src/components/CogsAnimation";
import { isMobile } from "react-device-detect";
import {
  BaseAccountFields,
  BaseAssetFields,
  BaseSimpleTransactionFields,
} from "src/api/fragments";
import {
  getGainsLossesLink,
  getLinkForSortByAxis,
  isLabeled,
} from "src/modules/ledger/transactions";
import { RecalculateButton } from "src/components/styled/RecalculateButton";
import SecondaryText from "src/components/styled/SecondaryText";
import { SelectTransactionsContext } from "./context";
import { BatchTransactionsEdit } from "./BatchTransactionsEdit";
import { trackEvent } from "src/utils/analytics";
import { DateTime } from "luxon";
import { getAssetSymbolOrName } from "src/modules/ledger/assets";
import truncateMiddle from "truncate-middle";
import { Dictionary, head, isBoolean, truncate, uniq } from "lodash";
import { AssetIcon } from "src/components/styled/Assets";
import { OnChangeValue } from "react-select";
import StatusTag from "src/components/styled/StatusTag";
import { INTEGRATIONS } from "src/components/modals/AccountModal/constants";
import Helpers from "src/utils/helpers";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { GraphQLError } from "graphql";
import { useTheme } from "src/hooks/useTheme";

const MAX_SYNC_BUFFER_MINUTES = 5;

const PAGE_SIZE_OPTIONS: Option[] = [
  {
    value: 20,
    label: "20",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

function Transactions() {
  const params = useParams<{ clientId: string }>();
  const clientId = params.clientId;
  const toast = useMyToast();

  const { filters, hasFilters, clearFilters, updateFilters } =
    useTransactionSearch();

  const [selectedTxnIds, setSelectedTxnIds] = useState<string[]>([]);
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState((filters.page || 0) + 1);
  const [lastSyncedAt, setLastSyncedAt] = useState<string | null>(null);

  // Mutations
  const [syncAll] = useMutation(api.accounts.syncAll);

  // Modals
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);

  const _onClickFilter = useCallback(
    () => _showModal("FilterTxnsModal", { clientId }),
    [clientId]
  );

  const _onClickHideSpam = (_includeSpam: boolean) => {
    updateFilters({
      includeSpam: _includeSpam,
    });
  };

  // Queries
  const [getNumTxns, { data: numTxnsData, networkStatus: numTxnsStatus }] =
    useLazyQuery<{
      getNumTxns?: NumTxnsResponse;
    }>(api.transactions.countTransactions, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  const {
    transactions,
    totalTransactions,
    getTransactions,
    preFetchTransactions,
    isLoadingTransactions,
    errorGetTransactions,
    client,
  } = useClientById(clientId, {
    skipFetchAssetsOnLoad: true,
  });

  const totalPages = useMemo(
    () =>
      filters.limit
        ? Math.ceil(totalTransactions / filters.limit)
        : Math.ceil(totalTransactions / 20),
    [totalTransactions]
  );

  // console.log(transactions);

  useEffect(() => {
    getNumTxns({
      variables: {
        clientId,
      },
    });
  }, []);

  // const isSortByIncome = filters.sortBy === "incomeSum";
  const filterHasIncome = filters.hasIncome === true;

  const handlePageInput = (e: any) => {
    const selectedPage = e.target.value;
    setCurrentPage(selectedPage);
  };

  const _onFetchTransactions = useCallback(async () => {
    const strToBool = (str?: Maybe<string | boolean>) => {
      if (typeof str === "boolean") return str;
      if (str === "true") return true;
      else if (str === "false") return false;
      else return null;
    };
    const reviewed = strToBool(filters.reviewed);
    const hasIncome = strToBool(filters.hasIncome);
    const hasNotes = strToBool(filters.hasNotes);
    const query = {
      page: filters.page || 0,
      limit: filters.limit,
      // review status could be an empty string which does not satisfy the enum type,
      // so we default to null for the empty case so graphQL doesn't complain
      accountIds: filters.accountIds || null,
      providers: filters.providers || null,
      includeSpam: filters.includeSpam ?? null,
      startDate: filters.startDate ? new Date(filters.startDate) : null,
      endDate: filters.endDate ? new Date(filters.endDate) : null,
      search: filters.search || null,
      reviewed,
      labeledPriority: filters.labeledPriority || null,
      hasIncome,
      hasNotes,
      sortBy: filters.sortBy,
      ascending: filters.ascending === "true",
      // FIXME: this is rlly asset keys. it isn't a specific match on ID atm it is by the coingecko or identifier
      assetIds: filters.assetIds || null,
      labels: filters.labels || null,
      assetSymbolOrName: filters.assetSymbolOrName || null,
      isMissingBasis: filters.isMissingBasis || null,
      assetKey: filters.assetKey || null,
      assetType: filters.assetType || null,
    };

    // console.log(query);

    await getTransactions(query);

    // pre fetch the next page
    void preFetchTransactions({
      ...query,
      page: filters.page ? filters.page + 1 : 1,
    });
    // FIXME: this isn't good to stringify the filters here, should do a string
    // hash here, but for now this hack works okay
  }, [JSON.stringify(filters)]);

  const _onClickSyncTxns = useCallback(async () => {
    try {
      await syncAll({
        variables: {
          clientId,
          isContinuousSync: true,
        },
        refetchQueries: [api.clients.retrieve],
      });
      toast.show({
        message: "We've started to sync your transactions.",
        status: "success",
      });
    } catch (e) {
      toast.show({
        message: (e as ApolloError)?.message || "Error syncing transactions",
        status: "info",
      });
    }
  }, [clientId]);

  // Note: the first page is 0 (just like an array)
  const onPageChange = (p: { selected: number }) => {
    updateFilters({ ...filters, page: p.selected });
  };

  const onSort = (sortBy: Maybe<string>) => {
    if (sortBy === "capGainsSum" && filters.sortBy !== "capGainsSum") {
      updateFilters({
        sortBy,
        ascending: false,
        hasIncome: null,
        hasNotes: null,
      });
    } else if (sortBy === "incomeSum" && filters.sortBy !== "incomeSum") {
      updateFilters({
        sortBy,
        hasIncome: true,
        hasNotes: null,
        ascending: false,
      });
    } else {
      updateFilters({
        ascending: filters.ascending === "true" ? "" : "true",
        sortBy,
        hasIncome: null,
        hasNotes: null,
      });
    }
  };

  // looks like this is a duplicate of above function, with slight differences
  const _onSelectSort = (sortBy: Maybe<string>) => {
    if (sortBy === "capGainsSum" && filters.sortBy !== "capGainsSum") {
      updateFilters({
        sortBy,
        ascending: false,
        hasIncome: null,
        hasNotes: null,
      });
    } else if (sortBy === "incomeSum" && filters.sortBy !== "incomeSum") {
      // we want descending
      updateFilters({
        sortBy,
        hasIncome: true,
        hasNotes: null,
        ascending: false,
      });
    } else {
      updateFilters({
        ascending: null,
        sortBy: null,
        hasIncome: null,
        hasNotes: null,
      });
    }
  };

  const addOrRemoveTxnIds = (txnId: string) => {
    const txnIds = [...selectedTxnIds];
    if (txnIds.includes(txnId)) {
      setSelectedTxnIds(() => txnIds.filter((id) => id !== txnId));
    } else {
      setSelectedTxnIds([...txnIds, txnId]);
    }
  };

  const _toggleSelectAllTxns = (e?: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (selectedTxnIds.length === transactions.length) {
      console.log(`[wiping txns]`);
      setSelectedTxnIds(() => []);
    } else {
      console.log(`[setting txns]`);
      setSelectedTxnIds(() => transactions.map((t) => t.id));
    }
  };

  useEffect(() => void _onFetchTransactions(), [_onFetchTransactions]);

  useEffect(() => setCurrentPage((filters.page || 0) + 1), [filters?.page]);

  // set the last synced at message
  useInterval(async () => {
    if (!client) return;
    if (!client.lastSyncedAt) return setLastSyncedAt(null);

    const lastSyncedAt = DateTime.fromJSDate(new Date(client.lastSyncedAt));

    const lastSyncedAtMessage = lastSyncedAt
      ? lastSyncedAt.toRelative({
          style: "short",
          round: true, // Enable rounding
        })
      : null;

    const displayTime =
      lastSyncedAt.diffNow().as("minutes") < -1
        ? lastSyncedAtMessage
        : "just now";

    setLastSyncedAt(displayTime);
  }, 1 * 1000);

  const [getTransactionHistory] = useLazyQuery(api.reports.transactionHistory);
  const { download } = useDownloadFile();

  const _exportUnlabeledTransactions = async () => {
    try {
      const response = await getTransactionHistory({
        variables: {
          clientId,
        },
        fetchPolicy: "network-only",
      });

      if (response.data?.getTransactionHistoryReport?.downloadUrl) {
        download(response.data.getTransactionHistoryReport.downloadUrl);

        toast.show({
          message: "Exported transactions",
          status: "success",
        });
      }
    } catch (err) {
      // alert error
      alert("Error exporting transactions");
    }
  };

  const {
    background,
    medBackground,
    secondaryBackground,
    border,
    theme,
    text,
    header,
  } = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <SelectTransactionsContext.Provider
      value={{
        selectedTransactionIds: selectedTxnIds,
        wipeTxnIds: () => setSelectedTxnIds([]),
        addOrRemoveTxnIds,
        activeLabel,
        setActiveLabel,
      }}
    >
      <FilterTxnsModal />
      <BatchTransactionsEdit />

      <div
      // style={{
      //   padding: "1rem 2.5rem",
      // }}
      >
        <HStack
          margin={isLarge ? 0 : "25px 0"}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: isLarge ? "row" : "column",
              alignItems: isLarge ? "center" : "flex-start",
            }}
            flex={1}
          >
            <Heading
              color={header}
              padding="0"
              margin={0}
              size="lg"
              marginRight="0.5rem"
            >
              Transactions
            </Heading>
            <HStack w="100%">
              <AwakenTooltip
              //message="Pull in all your recent transactions. This is a new feature, so if you have any problems send us an email to andrew@awaken.tax and we'll help right away :)."
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Touchable
                    cursor="pointer"
                    padding={"0.25rem 0.25rem"}
                    onClick={_onClickSyncTxns}
                    bg={medBackground}
                    _hover={{ bg: secondaryBackground }}
                    border={"1px solid " + border}
                    borderRadius={other.borderRadius}
                  >
                    {/* <StatusTag
                  type="beta"
                  label="Beta"
                  infoMessage="Continuous syncing where we pull in all your transactions when you load Awaken is currently in Beta. If you have any feedback, shoot us a message to andrew@awaken.tax :)."
                /> */}

                    <Text
                      fontSize={"xs"}
                      padding="0"
                      color={text}
                      fontWeight="normal"
                    >
                      {lastSyncedAt
                        ? "Updated " + lastSyncedAt
                        : "Refresh transactions"}
                    </Text>
                    <i
                      className="fa-sharp fa-sync-alt"
                      style={{
                        color: text,
                      }}
                    />
                  </Touchable>
                </div>
              </AwakenTooltip>
              {!isLarge && (
                <>
                  <Touchable
                    padding="0.5rem 1rem"
                    borderRadius={other.borderRadius}
                    onClick={() => dispatch(show("CreateTxnModal"))}
                  >
                    <i style={{ color: text }} className="fa-sharp fa-plus" />
                    <Text
                      color={text}
                      fontSize="sm"
                      padding="0"
                      fontWeight="semibold"
                    >
                      Create
                    </Text>
                  </Touchable>
                  <RecalculateButton />
                </>
              )}
            </HStack>
          </Box>
          {isLarge && (
            <TransactionStats getNumTxnsData={numTxnsData?.getNumTxns} />
          )}
        </HStack>

        {/* <Divider style={{ marginTop: "1rem" }} /> */}

        {/* don't show this because it makes us feel like we shouldn't generate the reports till 100% */}
        <HStack flex={1} alignItems="center" margin="1rem 0" paddingTop="1rem">
          <Box
            flex={1}
            style={{
              display: "flex",
              flexDirection: isLarge ? "row" : "column",
              alignItems: isLarge ? "center" : "flex-start",
            }}
          >
            <HStack
              cursor="text"
              padding="0.5rem 1rem"
              transition="0.5s ease-in-out"
              w="100%"
              marginRight="0.25rem"
              maxW={isLarge ? "20rem" : "inherit"}
              bg={secondaryBackground}
              borderRadius={other.borderRadius}
              // border={"1px solid " + colors.gray3}
              onClick={_onClickFilter}
            >
              <i
                className={"fa-sharp fa-search"}
                style={{
                  color: text,
                }}
              />
              <Text
                fontSize="sm"
                padding="0"
                color={text}
                fontWeight="semibold"
              >
                Search
              </Text>
            </HStack>

            <Popover trigger="hover" placement="top">
              <PopoverTrigger>
                <HStack marginTop={isLarge ? undefined : "1rem"}>
                  <Text
                    color={text}
                    fontSize="xs"
                    padding="0"
                    fontWeight="semibold"
                    marginLeft="0.5rem"
                  >
                    Show small{" "}
                  </Text>
                  <Switch
                    className={theme === "dark" ? "awaken__switch_dark" : ""}
                    isChecked={filters.includeSpam === true}
                    onChange={(e) => _onClickHideSpam(e.target.checked)}
                  />
                </HStack>
              </PopoverTrigger>
              <PopoverContent>
                <Box padding="0.5rem">
                  Show small transactions like spam, negligible interest
                  payments, etc.
                </Box>
              </PopoverContent>
            </Popover>
          </Box>

          {isLarge && (
            <HStack flex={1} alignItems="center" justifyContent="flex-end">
              <Touchable
                padding="0.5rem 1rem"
                borderRadius={other.borderRadius}
                onClick={() => dispatch(show("CreateTxnModal"))}
              >
                <i style={{ color: text }} className="fa-sharp fa-plus" />
                <Text
                  color={text}
                  fontSize="sm"
                  padding="0"
                  fontWeight="semibold"
                >
                  Transaction
                </Text>
              </Touchable>

              <ActionSheet
                content={{
                  maxW: "225px",
                }}
                popover={{ placement: "bottom" }}
                commands={[
                  {
                    label: "Sort by date",
                    iconName: "fa-sharp fa-calendar",
                    onClick: () => _onSelectSort(null),
                  },
                  {
                    label: "Sort by gains/loss",
                    iconName: "fa-sharp fa-chart-line",
                    onClick: () => _onSelectSort("capGainsSum"),
                    infoMessage:
                      "You should be labeling a $50,000 gain transaction before a $10 gain transaction.",
                  },
                  {
                    label: "Sort by income",
                    iconName: "fa-sharp fa-coins",
                    onClick: () => _onSelectSort("incomeSum"),
                    infoMessage:
                      "See which transactions you earned interest or staking/lending income from.",
                  },
                  filters.sortBy
                    ? {
                        label: "Remove sort",
                        iconName: "fa-sharp fa-undo",
                        hasDivider: true,
                        onClick: () => _onSelectSort(null),
                      }
                    : null,
                ].filter(hasValue)}
              >
                <Touchable
                  bg={secondaryBackground}
                  _hover={{ bg: secondaryBackground }}
                  padding="0.5rem 1rem"
                  borderRadius={other.borderRadius}
                >
                  <i style={{ color: text }} className="fa-sharp fa-sort" />
                  <Text
                    color={text}
                    fontSize="sm"
                    padding="0"
                    fontWeight="semibold"
                  >
                    Sort
                  </Text>
                </Touchable>
              </ActionSheet>

              <AwakenTooltip message="This only exports un-reviewed transactions">
                <div style={{ display: "flex" }}>
                  <Touchable
                    bg={secondaryBackground}
                    _hover={{ bg: secondaryBackground }}
                    padding="0.5rem 1rem"
                    borderRadius={other.borderRadius}
                    onClick={_exportUnlabeledTransactions}
                  >
                    <i
                      style={{ color: text }}
                      className="fa-sharp fa-download"
                    />
                    <Text
                      color={text}
                      fontSize="sm"
                      padding="0"
                      fontWeight="semibold"
                    >
                      Export
                    </Text>
                  </Touchable>
                </div>
              </AwakenTooltip>

              <RecalculateButton
                buttonProps={{
                  bg: colors.primary,
                  // color: colors.white,
                }}
                iconStyle={{ color: colors.white }}
                textProps={{ color: colors.white }}
              />

              {/* do not add this button back people are crashing our system with it somehow */}
            </HStack>
          )}

          {/* {hasFilters && (
          <HStack
            cursor="pointer"
            padding="0.5rem 1rem"
            background={`linear-gradient(90deg, #ff5e4d 32%, #ffa763 100%)`}
            transition="0.5s ease-in-out"
            borderRadius={other.borderRadius}
            onClick={() => navigate(getTransactionsLink(clientId || ""))}
            bgColor={colors.gray2}
          >
            <i
              className={"fa-sharp fa-times"}
              style={{
                color: white,
              }}
            ></i>
            <Text
              fontSize="sm"
              padding="0"
              color={colors.white}
              fontWeight="semibold"
            >
              Reset Filters
            </Text>
          </HStack>
        )} */}
        </HStack>

        <FiltersList />

        <WhiteBox border="none" padding="0" marginTop="0">
          <TableContainer>
            <Table border={`1px solid ${border}`} variant="simple">
              <Thead bg={background} border={`1px solid ${border}`}>
                <Tr>
                  <Th borderColor={border} w="20px">
                    <div onClick={_toggleSelectAllTxns}>
                      <Checkbox
                        borderColor={border}
                        isChecked={
                          selectedTxnIds.length === transactions.length
                        }
                      />
                    </div>
                  </Th>
                  <Th
                    borderColor={border}
                    paddingLeft="0rem"
                    cursor="pointer"
                    color={text}
                    onClick={() => onSort("title")}
                  >
                    Name
                    <SortTriangle
                      ascending={
                        filters.sortBy === "title" ? filters.ascending : "false"
                      }
                      sortBy={filters.sortBy}
                      expectedSortBy={"title"}
                    />
                  </Th>
                  <Th borderColor={border} cursor="pointer">
                    <Center color={text}>
                      <Tooltip
                        zIndex={1401}
                        trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                        bg={colors.black}
                        placement="top"
                        borderRadius="0.25rem"
                        padding={{ base: "0.5rem 1rem" }}
                      >
                        Status
                      </Tooltip>
                    </Center>
                  </Th>
                  <Th
                    borderColor={border}
                    cursor="pointer"
                    onClick={() => {
                      // hack for now
                      if (filterHasIncome) {
                        updateFilters({
                          sortBy: "incomeSum",
                          hasIncome: true,
                          ascending:
                            (filters.ascending || "") === "false"
                              ? "true"
                              : "false",
                        });
                      } else {
                        onSort("capGainsSum");
                      }
                    }}
                  >
                    <Center color={text}>
                      <Tooltip
                        zIndex={1401}
                        trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                        bg={colors.black}
                        placement="top"
                        borderRadius="0.25rem"
                        label={
                          filterHasIncome
                            ? "Click here to sort by income. You should be reviewing transactions with $1,000 of income before a $1 of income."
                            : "Click here to sort by gains/losses. You should be reviewing a $50,000 gain before a $100 gain transaction."
                        }
                        padding={{ base: "0.5rem 1rem" }}
                      >
                        {filterHasIncome ? "Income" : "Gains/Losses"}
                      </Tooltip>

                      {filterHasIncome ? (
                        <SortTriangle
                          ascending={
                            filters.sortBy === "incomeSum"
                              ? filters.ascending === "true"
                                ? "true"
                                : "false"
                              : "true"
                          }
                          sortBy={filters.sortBy}
                          expectedSortBy={"incomeSum"}
                        />
                      ) : (
                        <SortTriangle
                          ascending={
                            filters.sortBy === "capGainsSum"
                              ? filters.ascending === "true"
                                ? "false"
                                : "true"
                              : "false"
                          }
                          sortBy={filters.sortBy}
                          expectedSortBy={"capGainsSum"}
                        />
                      )}
                    </Center>
                  </Th>
                  {/* <Th cursor="pointer" onClick={() => onSort("capGainsSum")}>
                  <Center>
                    <Tooltip
                      zIndex={1401}
                      trigger={isMobile ? "hover" : "hover"} // we already have a click handler
                      bg={colors.black}
                      placement="top"
                      borderRadius="0.25rem"
                      label="Click here to sort by your biggest gains/losses. You may want to see which transactions led you to your cap gains total on the Tax Reports page"
                      padding={{ base: "0.5rem 1rem" }}
                    >
                      Gains/Losses
                    </Tooltip>
                    <SortTriangle
                      ascending={
                        filters.sortBy === "capGainsSum"
                          ? filters.ascending
                          : "false"
                      }
                      sortBy={filters.sortBy}
                      expectedSortBy={"capGainsSum"}
                    />
                  </Center>
                </Th> */}
                  <Th borderColor={border}>
                    <Center color={text}>Account</Center>
                  </Th>
                  <Th
                    borderColor={border}
                    isNumeric
                    cursor="pointer"
                    color={text}
                    onClick={() => onSort("createdAt")}
                  >
                    &nbsp;&nbsp;Date &#38; Time{" "}
                    <SortTriangle
                      ascending={
                        filters.sortBy === "createdAt"
                          ? filters.ascending
                          : "false"
                      }
                      sortBy={filters.sortBy}
                      expectedSortBy={"createdAt"}
                    />
                  </Th>
                </Tr>
              </Thead>
              <Tbody border={`1px solid ${border} !important`}>
                {transactions.map((t) => (
                  <Transaction
                    timezone={client?.timezone || "UTC"}
                    transaction={t}
                    key={t.id}
                    // handleContextMenu={handleContextMenu}
                  />
                ))}
              </Tbody>
            </Table>

            <NoTransactionsComponent
              transactions={transactions}
              filters={filters}
              hasFilters={hasFilters}
              errorGetTransactions={errorGetTransactions}
              _onFetchTransactions={_onFetchTransactions}
              isLoadingTransactions={
                isLoadingTransactions && !transactions.length
              }
              clearFilters={clearFilters}
            />
          </TableContainer>
        </WhiteBox>

        <HStack alignItems="flex-start" marginTop="2rem">
          <Paginate
            onPageChange={onPageChange}
            filters={filters}
            currentPage={filters?.page || 0}
            totalPages={totalPages}
            totalTransactions={totalTransactions}
            numPageTransactions={transactions?.length}
          />
        </HStack>

        <HStack
          style={{ marginTop: 0, width: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Input
            // label="Page"
            containerStyle={{ width: 60, marginBottom: 0 }}
            value={currentPage}
            textAlign="center"
            paddingLeft="0.6rem"
            onChange={handlePageInput}
            placeholder="Page"
            fontSize={14}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onPageChange({ selected: currentPage - 1 });
              }
            }}
          />
          <Select
            // label="Per page"
            selectProps={{
              onChange: (o: any) => {
                updateFilters({ ...filters, limit: o?.value || 20 });
              },
              components: {
                IndicatorSeparator: () => null,
              },
            }}
            containerStyle={{ width: 100, marginBottom: 0 }}
            placeholder=""
            value={
              PAGE_SIZE_OPTIONS.find((o) => o.value === filters.limit) || null
            }
            options={PAGE_SIZE_OPTIONS}
          />
        </HStack>
      </div>
    </SelectTransactionsContext.Provider>
  );
}

const EXCLUDE_FILTERS = ["page", "limit"];

const FiltersList = () => {
  const theme = useTheme();
  const { clientId } = useParams<{ clientId: string }>();
  const { filters, updateFilters } = useTransactionSearch();
  const { assets, accounts, getAssets } = useClientById(clientId);
  const assetById = useMemo(() => keyBy((a) => a.id, assets), [assets]);
  // FIXME: hacky on the backend it could change and then this would break. isn't that bad tho just won't show
  // the icon as the filter
  const assetByKey = useMemo(
    (): Dictionary<BaseAssetFields> =>
      keyBy((a: BaseAssetFields): string => {
        if (a.type === AssetTypeEnum.FiatCurrency) return a.symbol ?? a.id;
        if (a.type === AssetTypeEnum.FungibleToken) {
          return a.coinGeckoTokenId ?? a.contractAddress ?? a.id;
        }
        if (a.type === AssetTypeEnum.Nft) {
          return a.contractAddress ?? a.id;
        }
        return a.id;
      }, assets),
    [assets]
  );
  const assetByName = useMemo(
    () => keyBy((a) => getAssetSymbolOrName(a), assets),
    [assets]
  );
  const accountById = useMemo(() => keyBy((a) => a.id, accounts), [accounts]);

  useEffect(() => {
    void getAssets();
  }, [clientId]);

  function convertF0(f0?: any, f1?: any) {
    if (!f0) return "";
    if (f0 === "includeSpam") return "";
    if (f0 === "labeledPriority") {
      return {
        title: "Priority",
        iconName: "fa-sharp fa-exclamation-triangle",
      };
    }
    if (f0 === "accountIds") {
      const accounts = (f1 ?? [])
        .map((id: string): BaseAccountFields => accountById[id])
        .filter(hasValue);
      const allSameProvider =
        new Set(accounts.map((a: BaseAccountFields) => a.provider)).size === 1;
      const imgSrc = allSameProvider ? accounts[0]?.iconImageUrl : null;

      return {
        title: "Account",
        iconName: allSameProvider ? null : "fa-sharp fa-wallet",
        iconComponent: allSameProvider ? (
          <img
            src={imgSrc}
            alt="Icon"
            style={{
              marginRight: "0.5rem",
              width: 17,
              height: 17,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        ) : null,
      };
    }
    if (f0 === "assetId" || f0 === "assetSymbolOrName" || f0 === "assetKey") {
      const asset = assetByName[f1] || assetById[f1] || assetByKey[f1];
      return {
        title: "Asset",
        iconName: asset ? null : "fa-sharp fa-coins",
        iconComponent: asset ? (
          <AssetIcon
            style={{ marginRight: "0.5rem" }}
            size={18}
            asset={asset}
          />
        ) : null,
      };
    }
    if (f0 === "assetIds") {
      return {
        title: "Assets",
        iconName: "fa-sharp fa-coins",
      };
    }
    if (f0 === "assetType") {
      return {
        title: "Asset Type",
        iconName: "fa-sharp fa-filter",
      };
    }
    if (f0 === "labels") {
      return {
        title: "Labels",
        iconName: "fa-sharp fa-tags",
      };
    }
    if (f0 === "providers") {
      const providerSet = new Set(f1);
      const providers = INTEGRATIONS.filter((i) => providerSet.has(i.provider));

      const isOneProvider = providers.length === 1;
      const imgSrc = isOneProvider ? providers[0].logoUrl : null;
      const allSameType = new Set(providers.map((p) => p.type));

      const name =
        allSameType.size === 1
          ? providers[0].type === AccountTypeEnum.Exchange
            ? "Exchanges"
            : "Wallets"
          : "Provider";

      return {
        title: name,
        iconComponent: imgSrc ? (
          <img
            src={imgSrc}
            alt="Icon"
            style={{
              marginRight: "0.5rem",
              width: 17,
              height: 17,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        ) : null,
        iconName: imgSrc ? null : "fa-sharp fa-wallet",
      };
    }
    if (f0 === "startDate")
      return { title: "Start Date", iconName: "fa-sharp fa-calendar" };
    if (f0 === "endDate")
      return { title: "End Date", iconName: "fa-sharp fa-calendar" };
    if (f0 === "ascending")
      return { title: "Ascending", iconName: "fa-sharp fa-sort-amount-up" };
    if (f0 === "hasIncome")
      return { title: "Income", iconName: "fa-sharp fa-dollar-sign" };
    if (f0 === "hasNotes")
      return { title: "Notes", iconName: "fa-sharp fa-notes" };
    if (f0 === "reviewed")
      return { title: "Status", iconName: "fa-sharp fa-check" };
    if (f0 === "search")
      return { title: "Search", iconName: "fa-sharp fa-search" };
    else if (f0 === "sortBy")
      return { title: "Sort By", iconName: "fa-sharp fa-sort" };
    else if (f0 === "isMissingBasis")
      return {
        title: "Missing Basis",
        iconName: "fa-sharp fa-exclamation-triangle",
      };
    return f0;
  }

  function convertF1(f0?: any, f1?: any) {
    if (!f1) return "";
    if (f1 === "incomeSum") return "Income Ranking";
    else if (f1 === "capGainsSum") return "Gains/Losses";

    if (f0 === "startDate" || f0 === "endDate")
      return DateTime.fromJSDate(new Date(f1))
        .toUTC()
        .startOf("day")
        .toFormat("LLL dd, yyyy");

    if (f0 === "assetId") {
      const name = getAssetSymbolOrName(assetById[f1]);
      return truncateMiddle(name || f1, 10, 10, "...");
    }
    if (f0 === "assetType") {
      if (f1?.toUpperCase() === "NFT") return "NFT";
      if (f1?.toLowerCase() === "fungible_token") return "Tokens";
      return "None";
    }
    if (f0 === "labeled") {
      if (f1?.toLowerCase() === "true") return "Reviewed";
      if (f1?.toLowerCase() === "false") return "Unreviewed";
    }
    if (f0 === "assetIds") {
      const assets = (f1 ?? [])
        .map((id: string) => assetById[id])
        .filter(hasValue);

      return uniq(
        assets.map((a: BaseAssetFields) =>
          truncateMiddle(getAssetSymbolOrName(a) || f1, 10, 10, "...")
        )
      ).join(", ");
    }
    if (f0 === "providers") {
      const providerSet = new Set(f1);
      const providers = INTEGRATIONS.filter((i) => providerSet.has(i.provider));

      return (providers ?? []).map((p) => p.name).join(", ");
    }
    if (f0 === "labels") {
      const labels = (f1 ?? [])
        .map((f: string) =>
          f.replace("v1:", "").split("_").map(Helpers.capitalize).join(" ")
        )
        .join(", ");

      return labels;
    }
    if (f0 === "assetKey") {
      const name = getAssetSymbolOrName(assetByKey[f1]);
      return truncateMiddle(name || f1, 10, 10, "...");
    }
    if (f0 === "accountIds") {
      const accounts = (f1 ?? [])
        .map((id: string) => accountById[id])
        .filter(hasValue);

      return accounts
        .map((a: BaseAccountFields) =>
          truncate(a.description || "", { length: 15 })
        )
        .join(", ");
    }

    if (f0 === "labeledPriority") {
      return f1 == "1" ? "Low" : f1 == "2" ? "Medium" : f1 == "3" ? "High" : "";
    }

    if (isBoolean(f1) || f1 === "true" || f1 === "false") {
      if (f1 === true || f1 === "true") return "Yes";
      if (f1 === false || f1 === "false") return "No";
    }

    return f1;
  }

  return (
    <Box marginTop="1.5rem" marginBottom="1rem" display="flex" flexWrap="wrap">
      {Object.entries(filters)
        .filter(
          (f) =>
            hasValue(f[1]) && f[1] !== "" && !EXCLUDE_FILTERS.includes(f[0])
        )
        .map((f) => {
          const info = convertF0(f[0], f[1]);

          if (!info) {
            return null;
          }

          return (
            <Box
              bgColor={theme.secondaryBackground}
              // border={`1px solid ${colors.gray70}`}
              borderRadius={other.borderRadius}
              color={theme.text}
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="0.25rem 0.75rem"
              fontWeight="semibold"
              whiteSpace={"nowrap"}
              marginBottom="0.5rem"
              marginRight="0.5rem"
              fontSize={14}
            >
              {info.iconName && (
                <i
                  className={info.iconName}
                  style={{ marginRight: "0.5rem", fontSize: 14 }}
                />
              )}
              {info.iconComponent && info.iconComponent}
              {`${info.title}: ${convertF1(f[0], f[1])}`}
              <i
                style={{
                  borderRadius: "50%",
                  marginLeft: "1rem",
                  cursor: "pointer",
                }}
                className="fa-sharp fa-times"
                onClick={() => updateFilters({ ...filters, [f[0]]: null })}
              ></i>
            </Box>
          );
        })}
    </Box>
  );
};

const TransactionStats = ({
  getNumTxnsData,
}: {
  getNumTxnsData: NumTxnsResponse | undefined;
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const {
    background,
    ternaryBackground,
    medBackground,
    secondaryBackground,
    header,
    text,
  } = useTheme();

  const unreviewedImportant = getNumTxnsData ? getNumTxnsData.high : null;

  const timeLeft = useMemo(() => {
    if (unreviewedImportant === null) return "";
    let totalMins = 0;
    if (unreviewedImportant === 0) totalMins = 0;
    else if (unreviewedImportant < 100)
      totalMins = Math.round(unreviewedImportant * 0.2);
    else if (unreviewedImportant < 1000)
      // intersects with above line at y = 100
      totalMins = Math.round(unreviewedImportant * 0.1 + 10);
    // intersects with above line at y = 1000
    else totalMins = Math.round(unreviewedImportant * 0.05 + 60);

    if (unreviewedImportant && totalMins === 0 && unreviewedImportant > 0)
      totalMins = 1;

    const hours = Math.floor(totalMins / 60);
    const mins = totalMins % 60;
    if (hours > 0) return `${hours} hrs${mins > 0 ? ` ${mins} mins` : ""}`;
    else return `${mins} min${mins === 1 ? "" : "s"}`;
  }, [unreviewedImportant]);

  const taxDeadline2023 = new Date("4/18/2023");
  const daysLeft = Math.floor(
    (taxDeadline2023.getTime() - Date.now()) / (1000 * 3600 * 24)
  );
  const circularProgressValue =
    unreviewedImportant === null || getNumTxnsData?.total === undefined
      ? undefined
      : (100 * (getNumTxnsData.total - (unreviewedImportant || 0))) /
        getNumTxnsData.total;
  const cheerText =
    circularProgressValue !== undefined &&
    (circularProgressValue === 100
      ? null
      : circularProgressValue >= 60
      ? null
      : null);
  const highPriorityUnlabeledLink = getGainsLossesLink({
    clientId: clientId || "",
    reviewed: false,
    labeledPriority: 3, // highest priority
  });

  return (
    <WhiteBox
      // border={other.boxBorder}
      padding="0.4rem 1rem"
      marginBottom="1rem"
      display="inline-block"
      style={{ boxShadow: "none" }}
      bg={medBackground}
    >
      <HStack justifyContent="center" alignItems="center">
        <Box paddingRight="0.5rem">
          <CircularProgress
            value={circularProgressValue}
            isIndeterminate={!getNumTxnsData}
            color={
              (unreviewedImportant ?? 0) === 0
                ? colors.green50
                : colors.yellow50
            }
            trackColor={ternaryBackground}
            capIsRound
            size="4rem"
          >
            <CircularProgressLabel
              color={header}
              fontSize="14px"
              fontWeight="bold"
            >
              {!circularProgressValue
                ? ""
                : `${Math.floor(circularProgressValue)}%`}
            </CircularProgressLabel>
          </CircularProgress>
          {cheerText && (
            <Text color={header} w="100%" textAlign={"center"}>
              {cheerText}
            </Text>
          )}
        </Box>
        <VStack alignItems="flex-start" justifyContent={"flex-start"}>
          <HStack>
            <Link to={highPriorityUnlabeledLink} style={{ color: header }}>
              <Tooltip
                zIndex={1401}
                trigger={isMobile ? "click" : "hover"}
                bg={colors.black}
                placement="top"
                borderRadius="0.25rem"
                label={
                  unreviewedImportant
                    ? `We highly encourage you to review these ${unreviewedImportant} unlabeled transactions to get accurate tax reports. If you want to optimize your taxes, you can review of the unlabeled transactions as well`
                    : ""
                }
                padding={{ base: "0.5rem 1rem" }}
              >
                <Text
                  padding="0 0.5rem"
                  fontSize="sm"
                  cursor="pointer"
                  paddingLeft="0 !important"
                  _hover={{
                    color: header,
                    textDecoration: "underline",
                  }}
                  color={header}
                >
                  <i
                    className="fa-sharp fa-circle"
                    style={{
                      color: colors.red50,
                      marginRight: 5,
                    }}
                  />{" "}
                  <strong>{unreviewedImportant}</strong> high priority
                </Text>
              </Tooltip>
            </Link>

            <Link
              to={getGainsLossesLink({
                clientId: clientId || "",
                reviewed: true,
              })}
              style={{ color: colors.black }}
            >
              <Text
                color={text}
                padding="0 0.5rem"
                fontSize="sm"
                cursor="pointer"
                _hover={{
                  color: header,
                  textDecoration: "underline",
                }}
              >
                <i
                  className="fa-sharp fa-check-circle"
                  style={{
                    color: colors.green50,
                    marginRight: 5,
                  }}
                />{" "}
                <strong>{getNumTxnsData?.reviewed}</strong> labeled
              </Text>
            </Link>

            {/* <Text color={text} padding="0 0.5rem" fontSize="sm">
              <i
                className="fa-sharp fa-clock"
                style={{
                  color: text,
                  marginRight: 5,
                }}
              />{" "}
              <strong>{timeLeft}</strong> of work
            </Text> */}
            {/* <Text margin="0.75rem 0">
              🗓 <strong>{daysLeft}</strong> days left
            </Text> */}
          </HStack>
          {unreviewedImportant === 0 ? (
            <SecondaryText
              color={text}
              text={
                <span>
                  To optimize your taxes, you can label more transactions.
                </span>
              }
            />
          ) : (
            <SecondaryText
              onClick={() => navigate(highPriorityUnlabeledLink)}
              cursor="pointer"
              color={text}
              text={
                <span>
                  Label{" "}
                  <SecondaryText
                    text={`${unreviewedImportant} high priority transactions`}
                    color={header}
                    textDecor="underline"
                    fontWeight="semibold"
                    display="inline"
                    margin="0"
                  />
                </span>
              }
            />
          )}
        </VStack>
      </HStack>
    </WhiteBox>
  );

  // return (
  //   <Box padding="0.5rem 0">
  //     <Text>
  //       Please review as many transactions as you can so Awaken can generate the
  //       most accurate tax reports.{" "}
  //       {/*When you review one transaction, Awaken
  //       automatically reviews similar transactions. */}
  //       {getNumTxnsData && getNumTxnsData.total && (
  //         <>
  //           <br />
  //           You have&nbsp;
  //           <Link to={highestPriorityLink}>
  //             <Text
  //               display="inline"
  //               fontWeight="semibold"
  //               color={colors.primary}
  //               textDecoration="underline"
  //             >
  //               {getNumTxnsData.unreviewed} transactions
  //             </Text>
  //           </Link>{" "}
  //           that need review.{" "}
  //           {timeLeft && (
  //             <span>
  //               (<strong>~{timeLeft}</strong>)
  //             </span>
  //           )}
  //         </>
  //       )}
  //     </Text>
  //     {getNumTxnsData && getNumTxnsData.unreviewed > 0 && (
  //       <Progress
  //         margin="1rem 0"
  //         value={(100 * getNumTxnsData.reviewed) / getNumTxnsData.total}
  //         width="27.25rem"
  //         bgColor={colors.gray2}
  //       />
  //     )}
  //   </Box>
  // );
};

const SortTriangle = ({
  ascending,
  sortBy,
  expectedSortBy,
}: {
  ascending?: Maybe<string>;
  sortBy?: Maybe<string>;
  expectedSortBy: string;
}) => {
  const isBeingSorted = sortBy === expectedSortBy;

  return (
    <i
      className={`fa-sharp fa-angle-${ascending === "true" ? "up" : "down"}`}
      style={{
        marginLeft: "0.3rem",
        fontSize: 16,
        position: "relative",
        top: 0,
        color: isBeingSorted ? colors.black : colors.gray70,
      }}
    />
  );
};

const NoTransactionsComponent = ({
  transactions,
  filters,
  hasFilters,
  errorGetTransactions,
  _onFetchTransactions,
  isLoadingTransactions,
  clearFilters,
}: {
  transactions: BaseSimpleTransactionFields[];
  filters: TransactionSearchFilters;
  hasFilters: boolean;
  errorGetTransactions: ApolloError | undefined;
  _onFetchTransactions: any;
  isLoadingTransactions: boolean;
  clearFilters: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const _onAddWallet = () => {
    _showModal("AccountModal", {
      location: "no_transactions_page",
    });
  };

  if (isLoadingTransactions) {
    return (
      <Box w="100%" padding="5rem 1rem">
        <Center>
          <Spinner color={theme.header} />
        </Center>
      </Box>
    );
  }

  if (errorGetTransactions) {
    return (
      <Box width="100%" padding="3rem 1rem" textAlign="center">
        <Text color={theme.header}>Something went wrong 🙁</Text>
        <Button
          variant="primary"
          marginTop="1rem"
          onClick={_onFetchTransactions}
        >
          Try again
        </Button>
      </Box>
    );
  }

  // No transactions matched
  if (transactions.length === 0 && hasFilters) {
    return (
      <Box width="100%" padding="3rem 1rem" textAlign="center">
        <Box width="100%" padding="3rem 1rem" textAlign="center">
          <Text color={theme.header}>No transactions matched your filter</Text>
          <Button variant="primary" marginTop="1rem" onClick={clearFilters}>
            Clear filters
          </Button>
        </Box>
      </Box>
    );
  }

  // User has no transactions
  if (transactions.length === 0) {
    return (
      <Box width="100%" padding="3rem 1rem" textAlign="center">
        <Text color={theme.header}>
          No transactions yet. Add an account to import your transactions 👇
        </Text>
        <Button variant="primary" marginTop="1rem" onClick={_onAddWallet}>
          Add Account
        </Button>
      </Box>
    );
  }

  return null;
};

export default Transactions;
