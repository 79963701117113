import {
  Button,
  Container,
  Textarea,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import SecondaryText from "src/components/styled/SecondaryText";
import { colors } from "src/theme";
import { useTheme } from "src/hooks/useTheme";
import { config } from "src/config";
import { Info } from "src/components/styled";
import { useMyToast } from "src/hooks";

type Props = InjectedProps & {
  capLoss: string;
};

function _CXModal({ handleHide, show: isVisible, capLoss }: Props) {
  const theme = useTheme();
  const toast = useMyToast();

  return (
    <Modal
      title={`How to reach us`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          variant="primary"
          style={{
            width: "100%",
          }}
          onClick={handleHide}
        >
          Close
        </Button>
      }
    >
      <Text color={theme.text}>
        Please note,{" "}
        <b>we do not provide tax advice on specific transactions</b>. This
        includes help editing or labeling them. You can find a CPA for tax
        advice on our "Taxes" page, or request bookkeeping help through our
        bookkeeping tab (additional fees apply).
      </Text>

      <br />

      <Text color={theme.text}>
        Please contact{" "}
        <span
          onClick={() => {
            // copy the email
            navigator.clipboard.writeText(config.cxEmail);
            toast.show({
              message: "Support email copied to clipboard!",
              status: "success",
            });
          }}
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            color: colors.primary,
          }}
        >
          {config.cxEmail}
        </span>{" "}
        if you have any questions.
      </Text>

      <br />

      <Text color={theme.text}>
        * We usually respond in under 24 hours for paying customers.
      </Text>

      <br />
    </Modal>
  );
}

export const CXModal = connectModal({
  name: "CXModal",
})(_CXModal);
