import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { ApolloError, useMutation } from "@apollo/client";
import { api } from "src/api";
import { useParams } from "react-router-dom";
import { useMyToast } from "src/hooks";
import {
  Mutation,
  MutationStartCheckoutArgs,
  StartSubscriptionCheckoutLineItem,
  StartSubscriptionCheckoutResponse,
} from "src/api/generated/types";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { stripePromise } from "src/utils/stripe";
import { Divider, HStack, Spinner, Text } from "@chakra-ui/react";
import { last } from "radash";
import { colors } from "src/theme";
import { Button } from "src/components/styled";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  maxTxnCeiling: number;
  onPaymentSuccess: () => void;
};

function _PaymentModal({
  handleHide,
  onPaymentSuccess,
  maxTxnCeiling,
  show: isVisible,
}: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();

  const [startSubscription, { data, loading }] = useMutation<
    Pick<Mutation, "startSubscriptionCheckout">
  >(api.checkouts.startSubscription);

  const onSuccess = () => {
    handleHide();
    if (onPaymentSuccess) onPaymentSuccess();
  };

  useEffect(() => {
    void startSubscription({
      variables: { clientId, txnCeiling: maxTxnCeiling },
    });
  }, []);

  const subCheckout = data?.startSubscriptionCheckout;
  const clientSecret = subCheckout?.clientSecret;
  const requiresPayment = subCheckout?.requiresPayment;
  const theme = useTheme();

  if (loading || !subCheckout) {
    return (
      <Modal
        isVisible={isVisible}
        handleHide={handleHide}
        w="100%"
        maxW="40rem"
      >
        <LoadingBox />
      </Modal>
    );
  }

  return (
    <Modal
      modalColor={theme.background}
      isVisible={isVisible}
      handleHide={handleHide}
      w="100%"
      maxW="40rem"
    >
      {!requiresPayment ? (
        <CreditOnlyCheckout onSuccess={onSuccess} subCheckout={subCheckout} />
      ) : clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <FullPaymentForm onSuccess={onSuccess} subCheckout={subCheckout} />
        </Elements>
      ) : (
        <div />
      )}
    </Modal>
  );
}

const CreditOnlyCheckout = ({
  subCheckout,
  onSuccess,
}: {
  subCheckout: StartSubscriptionCheckoutResponse;
  onSuccess: () => void;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const theme = useTheme();

  const [createSubscription] = useMutation<
    Pick<Mutation, "createSubscription">
  >(api.subscriptions.create);

  const _onSubmit = async () => {
    try {
      await createSubscription({
        variables: {
          clientId,
          txnCeiling: subCheckout.txnCeiling,
        },
        refetchQueries: [api.subscriptions.active, api.clients.retrieve],
      });

      toast.show({
        status: "success",
        message: "Success, you can now download your tax reports!",
      });

      onSuccess();
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as ApolloError)?.message || "Error creating subscription!",
      });
    }
  };

  return (
    <div style={{ padding: "2rem 0 0 0" }}>
      <CheckoutSummary subCheckout={subCheckout} />
      <Button
        style={{
          width: "100%",
          margin: "1rem 0.25rem 1.5rem 0.25rem",
        }}
        variant="primary"
        onClick={_onSubmit}
      >
        Claim Free Tax Reports
      </Button>
    </div>
  );
};

const LoadingBox = () => {
  return (
    <div
      style={{
        minHeight: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text fontSize="md" style={{ alignItems: "center", display: "flex" }}>
        Loading checkout <Spinner style={{ marginLeft: "1rem" }} size="sm" />
      </Text>
    </div>
  );
};

const FullPaymentForm = ({
  subCheckout,
  onSuccess,
}: {
  subCheckout: StartSubscriptionCheckoutResponse;
  onSuccess: () => void;
}) => {
  const [isReady, setReady] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();

  useEffect(() => {
    if (!elements) return;
    // make dark mode if applicable
    elements?.update({
      appearance: { theme: theme.theme === "dark" ? "night" : "stripe" },
    });
  }, [elements, theme.theme]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !isReady) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <div style={{ padding: "2rem 0 0 0" }}>
      <div
        style={{
          margin: "0 0.25rem",
        }}
      >
        <CheckoutSummary subCheckout={subCheckout} />
        <PaymentElement options={{}} onReady={() => setReady(true)} />
        {!isReady && (
          <Text fontSize="md" style={{ alignItems: "center", display: "flex" }}>
            <Spinner color={theme.header} size="md" />
          </Text>
        )}
      </div>
      <Button
        style={{
          width: "100%",
          margin: "3rem 0.25rem 1.5rem 0.25rem",
        }}
        variant="primary"
        onClick={handleSubmit}
        disabled={!isReady}
      >
        Purchase Tax Report
      </Button>
    </div>
  );
};

const CheckoutSummary = ({
  subCheckout,
}: {
  subCheckout: StartSubscriptionCheckoutResponse;
}) => {
  const lineItems = subCheckout?.lineItems || [];
  const allLineItems = lineItems.slice(0, lineItems.length - 1);
  const totalLineItem = last(lineItems);
  const theme = useTheme();

  return (
    <>
      <Text
        color={theme.header}
        fontSize="md"
        fontWeight="bold"
        marginBottom="1rem"
      >
        Checkout Summary
      </Text>
      <div
        style={{
          padding: "1rem",
          marginBottom: "2rem",
          backgroundColor: theme.secondaryBackground,
          borderRadius: 10,
          border: `1px solid ${theme.border}`,
        }}
      >
        {allLineItems.map((lineItem, i) => (
          <LineItem key={i} lineItem={lineItem} />
        ))}
        <Divider style={{ margin: "0.5rem 0" }} />
        <LineItem lineItem={totalLineItem} />
      </div>
    </>
  );
};

const LineItem = ({
  lineItem,
}: {
  lineItem: StartSubscriptionCheckoutLineItem;
}) => {
  const theme = useTheme();

  return (
    <HStack padding="0.25rem 0">
      <div style={{ flex: 1, textAlign: "left" }}>
        <Text color={theme.header} fontSize="sm" fontWeigh="500">
          {lineItem?.name}
        </Text>
      </div>
      <div style={{ flex: 1, textAlign: "right" }}>
        <Text
          fontSize="sm"
          fontWeight="bold"
          color={lineItem?.amountCents > 0 ? theme.header : colors.green50}
        >
          {lineItem?.amountCents === 0 ? "FREE" : lineItem?.amount}
        </Text>
      </div>
    </HStack>
  );
};

export const PaymentModal = connectModal({
  name: "PaymentModal",
})(_PaymentModal);
