import {
  BoxProps,
  Container,
  HStack,
  Text,
  TextProps,
  Tooltip,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { RECALCULATING_HELP_MESSAGE } from "src/config";
import { useRecalculate } from "src/hooks";
import { colors, other } from "src/theme";
import { Touchable } from "../Touchable";
import React, { CSSProperties } from "react";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { UserAuthProviderEnum } from "src/api/generated/types";
import { useTheme } from "src/hooks/useTheme";

type RecalculateButtonProps = BoxProps & {
  message?: string;
  buttonProps?: BoxProps;
  textProps?: TextProps;
  iconStyle?: CSSProperties;
};

export function RecalculateButton({
  message,
  buttonProps,
  textProps,
  iconStyle,
  ...props
}: RecalculateButtonProps) {
  const recalculate = useRecalculate();
  const isLarge = useIsLargeScreen();
  const { background, secondaryBackground, text } = useTheme();

  return (
    <Tooltip
      placement="top"
      trigger={isMobile ? undefined : "hover"}
      label={message || RECALCULATING_HELP_MESSAGE}
      zIndex={1401}
      bg={colors.black}
      borderRadius="0.25rem"
      padding={isLarge ? "0.5rem 1rem" : "0.5rem"}
      {...props}
    >
      <div style={{ display: "inherit" }}>
        <Touchable
          bg={secondaryBackground}
          _hover={{ bg: buttonProps?.bg || secondaryBackground }}
          padding={isLarge ? "0.5rem 1rem" : "0.5rem"}
          borderRadius={other.borderRadius}
          cursor="pointer"
          onClick={recalculate}
          w="auto"
          {...buttonProps}
        >
          <i
            className="fa-sharp fa-calculator"
            style={{
              color: text,
              ...iconStyle,
            }}
          />
          <Text
            fontSize={isLarge ? "sm" : "xs"}
            padding="0"
            color={text}
            fontWeight="semibold"
            {...textProps}
          >
            Recalculate
          </Text>
        </Touchable>
      </div>
    </Tooltip>
  );
}
