import { WatchQueryFetchPolicy, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { api } from "src/api";
import {
  QueryGetPortfolioV2Args,
  PortfolioResponseV2,
} from "src/api/generated/types";

type Props = {
  activeClientId: string;
  setIsLoadingInitialCoins?: (isLoading: boolean) => void;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export const usePortfolio = ({
  activeClientId,
  setIsLoadingInitialCoins,
  fetchPolicy,
}: Props) => {
  // console.log("client: ", activeClient?.name, " ", activeClient?.id);
  const coinsAndDefiPortfolioVariables = useMemo(
    (): QueryGetPortfolioV2Args => ({
      clientId: activeClientId,
      includeDefi: true,
      includeCoins: true,
      includeNFTs: false,
    }),
    [activeClientId]
  );

  const {
    data: coinsAndDefiData,
    error: coinsAndDefiError,
    refetch: refetchCoinsAndDefiPortfolio,
    networkStatus: coinsAndDefiNetworkStatus,
  } = useQuery<{
    getPortfolioV2: Omit<PortfolioResponseV2, "nfts" | "collections">;
  }>(api.portfolio.getPortfolioV2CoinsAndDefi, {
    skip: !activeClientId,
    variables: coinsAndDefiPortfolioVariables,
    fetchPolicy: fetchPolicy || "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (setIsLoadingInitialCoins) setIsLoadingInitialCoins(false);
    },
  });

  const balances = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.balances || [],
    [coinsAndDefiData?.getPortfolioV2?.balances]
  );
  const defiPositions = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.defiPositions || [],
    [coinsAndDefiData?.getPortfolioV2?.defiPositions]
  );

  const totalDefiCents = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.defiTotalValueCents ?? null,
    [coinsAndDefiData?.getPortfolioV2?.defiTotalValueCents]
  );

  const totalWalletCents = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.balanceTotalValueCents ?? null,
    [coinsAndDefiData?.getPortfolioV2?.balanceTotalValueCents]
  );

  const coinTotalValueCents = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.coinTotalValueCents ?? null,
    [coinsAndDefiData?.getPortfolioV2?.coinTotalValueCents]
  );

  const dailyFiatAmountCents = useMemo(() => {
    return coinsAndDefiData?.getPortfolioV2?.dailyFiatAmountCents ?? null;
  }, [coinsAndDefiData?.getPortfolioV2?.dailyFiatAmountCents]);

  // we only gave this data for coins + defi atm. if roll out for NFTs, will want to add that in here
  const totalDailyPercentage = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.dailyPercentage ?? null,
    [coinsAndDefiData?.getPortfolioV2?.dailyPercentage]
  );

  const totalDailyPercentageFormatted = useMemo(
    () => coinsAndDefiData?.getPortfolioV2?.dailyPercentageFormatted ?? null,
    [coinsAndDefiData?.getPortfolioV2?.dailyPercentageFormatted]
  );

  return {
    coinsAndDefiData,
    coinsAndDefiError,
    coinsAndDefiNetworkStatus,
    refetchCoinsAndDefiPortfolio,
    balances,
    defiPositions,
    totalDailyPercentageFormatted,
    totalDailyPercentage,
    totalDefiCents,
    totalWalletCents,
    coinTotalValueCents,
    dailyFiatAmountCents,
  };
};
