import { useMutation } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { useMyToast } from "./useMyToast";
import { RECALCULATE_REFETCH_QUERIES } from "src/modules/jobs/recalculate";

export const useRecalculate = () => {
  const { clientId } = useParams();
  const toast = useMyToast();
  const [rerunGraph] = useMutation(api.graph.rerun);

  const recalculate = useCallback(async () => {
    console.log("TRIGGERED RECALCULATE!");
    if (!clientId) {
      return;
    }

    try {
      await rerunGraph({
        variables: { clientId },
        // refetchQueries: [...RECALCULATE_REFETCH_QUERIES], now we have poller
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: (err as Error)?.message,
      });
    }
  }, [clientId]);

  return recalculate;
};
