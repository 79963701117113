import React, { useCallback, useEffect } from "react";
import { NoNavBarPageTemplate } from "src/components/layouts/PageTemplate";
import {
  Box,
  Center,
  Text,
  Image,
  HStack,
  Divider,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "src/assets/awaken/logos/logo-name.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input } from "src/components";
import { colors, other } from "src/theme";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AuthenticationService,
  AuthenticationType,
} from "src/modules/authentication";
import { BaseUserFields } from "src/api/fragments";
import { MyToast } from "src/components/MyToast";
import { useMe, useMyToast } from "src/hooks";
import { InjectedProps, connectModal, show } from "redux-modal";
import { GoogleButton } from "src/views/Authentication/components/Google";
import { Modal } from "src/components/Modal";
import { FirebaseError } from "firebase/app";
import { useDispatch } from "react-redux";
import {
  MultiFactorError,
  getMultiFactorResolver,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "src/utils/firebase";

type VerifyEmailModalProps = InjectedProps & {
  email: string;
  onSuccess: () => Promise<void>;
};

function _VerifyEmailModal(props: VerifyEmailModalProps) {
  const toast = useMyToast();

  const _sendEmailVerification = async () => {
    const user = auth.currentUser;

    if (!user) {
      toast.show({
        message: "You are not logged in.",
        status: "error",
      });
      return;
    }

    try {
      await sendEmailVerification(user);
    } catch (error) {
      toast.show({
        message: (error as any).message || "Something went wrong.",
        status: "error",
      });
    }
  };

  const _onClickVerifyEmail = async () => {
    const user = auth.currentUser;

    if (!user) {
      toast.show({
        message: "You are not logged in.",
        status: "error",
      });
      return;
    }

    await user.reload();

    const newUser = auth.currentUser;

    if (!newUser || !newUser.emailVerified) {
      toast.show({
        message: "Your email is not verified.",
        status: "error",
      });
      return;
    }

    if (props.onSuccess) await props.onSuccess();

    props.handleHide();
  };

  useEffect(() => {
    _sendEmailVerification();
  }, []);

  return (
    <Modal
      title={<Text fontSize="2xl">Verify Email</Text>}
      isVisible={props.show}
      handleHide={props.handleHide}
    >
      <VStack
        w="100%"
        style={{
          paddingBottom: "2rem",
        }}
      >
        <div>
          <Box
            width="100%"
            style={{
              padding: "3rem 0",
            }}
          >
            <Text>
              Please check your email and click verify. When you are done, come
              back here and press the below button.
            </Text>

            <Button
              onClick={_sendEmailVerification}
              style={{
                cursor: "pointer",
                color: colors.primary,
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              Resend verification email
            </Button>
          </Box>
        </div>
        <br />
        <Button
          onClick={_onClickVerifyEmail}
          width="100%"
          variant="primary"
          type="submit"
          padding="1rem 2rem"
        >
          Verify Email
        </Button>
      </VStack>
    </Modal>
  );
}

export const VerifyEmailModal = connectModal({
  name: "VerifyEmailModal",
})(_VerifyEmailModal);
