import React, { useContext, useMemo, useRef } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { api } from "src/api";
import _, {
  debounce,
  isEmpty,
  isNil,
  keyBy,
  noop,
  orderBy,
  truncate,
} from "lodash";
import {
  DefaultErrors,
  FailureOrSuccess,
  Maybe,
  UnexpectedError,
  failure,
  hasValue,
  success,
} from "src/core";
import Helpers, { D } from "src/utils/helpers";
import {
  AssetTypeEnum,
  MutationUpdateAssetArgs,
  PortfolioNft,
} from "src/api/generated/types";
import { Link } from "react-router-dom";
import { colors } from "src/theme";
import { Box, Text } from "@chakra-ui/react";
import { AssetIcon } from "src/components/styled/Assets";
import { useTheme } from "src/hooks/useTheme";

export const GRID_ITEM_HEIGHT = 250;
export const LIST_ITEM_HEIGHT = 60;

type NFTRowProps = {
  nft: PortfolioNft;
  itemHeight: number;
  mode: "grid" | "list";
  onPress: (nft: PortfolioNft) => void;
};

const SWIPEABLE_ICON_WIDTH = 75;

const NFTRow = React.memo((props: NFTRowProps) => {
  if (props.mode === "list") {
    return <RowNFT {...props} />;
  }

  return <ColumnNFT {...props} />;
});

const RowNFT = React.memo(({ nft, onPress, itemHeight }: NFTRowProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);

  const _onPress = () => {
    onPress(nft);
  };

  return (
    <Link
      to="#"
      style={{
        backgroundColor: colors.white,
        display: "flex",
        flexDirection: "row",
        padding: 15,
        alignItems: "center",
        justifyContent: "center",
        height: itemHeight,
        // borderBottomColor: border,
        // borderBottomWidth: 1,
      }}
    >
      <div>
        <img
          style={{
            height: 40,
            width: 40,
            borderRadius: 8,
            borderColor: colors.gray90,
            borderWidth: 2,
            objectFit: "cover",
          }}
          src={nft.previewImageUrl || ""}
        />
        {!isNil(nft?.providerImageUrl) && (
          <img
            style={{
              width: 18,
              height: 18,
              borderColor: colors.gray90,
              borderRadius: 100,
              borderWidth: 1,
              position: "absolute",
              bottom: -5,
              right: -5,
              objectFit: "cover",
            }}
            src={nft?.providerImageUrl || ""}
          />
        )}
      </div>

      <div
        style={{
          flex: 1,
          marginRight: 15,
          marginLeft: 10,
        }}
      >
        <Text
          style={{
            color: colors.black,
            textAlign: "left",
            fontSize: 13,
          }}
        >
          {nft?.name || ""}
        </Text>
        {/* <Text
          style={{
            color: header,
            textAlign: "left",
            fontSize: 13,
          }}
        >
          {nft?.provider || "-"}
        </Text> */}
      </div>

      {!isNil(nft.fiatPriceCents) && (
        <div
          style={{
            padding: 5,
            backgroundColor: colors.gray90,
            borderRadius: 5,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: colors.black,
              fontWeight: "bold",
            }}
          >
            {nft.fiatPriceCents ? D(nft.fiatPriceCents).toFormat() : "-"}
          </Text>
        </div>
      )}
    </Link>
  );
});

const ColumnNFT = React.memo(({ onPress, nft, itemHeight }: NFTRowProps) => {
  const { header, background, border, secondaryBackground } = useTheme();

  const _onPress = () => {
    onPress(nft);
  };

  return (
    <Link
      to="#"
      onClick={_onPress}
      style={{
        height: itemHeight,
        display: "flex",
        flexDirection: "column",
        padding: 5,
        alignItems: "center",
      }}
    >
      {/* {!isNil(nft.videoUrl) ? (
        <View
          style={{
            borderRadius: 10,
            overflow: "hidden",
            height: 150,
            width: "100%",
          }}
        >
          <FastImage
            style={{
              height: 150,
              width: "100%",
              borderRadius: 10,
              borderColor: border,
              borderWidth: 2,
              position: "absolute", // Add this to position image over the video initially
            }}
            resizeMode="cover"
            source={{
              uri: nft.previewImageUrl || "",
            }}
          />
          <Video
            source={{ uri: nft.videoUrl }}
            isMuted
            shouldPlay
            isLooping
            resizeMode={ResizeMode.COVER}
            style={{
              borderRadius: 10,
              height: "100%",
              width: "100%",
              opacity: isVideoLoaded ? 1 : 0, // Control the display of video based on its load state
            }}
            onLoad={() => setIsVideoLoaded(true)} // Event handler for when the video is loaded
          />
        </View>
      ) : ( */}
      <div
        style={{
          height: GRID_ITEM_HEIGHT - 75,
          width: "100%",
        }}
      >
        <AssetIcon
          asset={{
            iconImageUrl: nft.previewImageUrl || "",
            symbol: nft.name || "",
            type: AssetTypeEnum.Nft,
          }}
          size={GRID_ITEM_HEIGHT - 75}
          textStyle={{
            fontSize: 32,
            fontWeight: "bold",
          }}
          style={{
            borderRadius: 15,
            overflow: "hidden",
            width: "100%",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: 5,
          width: "100%",
          padding: 5,
        }}
      >
        <div
          style={{
            height: 40,
            marginTop: 5,
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Box
            width="100%"
            textAlign="left"
            mr={5}
            flex={1}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            fontWeight="medium"
          >
            <Text color={header} fontSize="md" noOfLines={2}>
              {nft?.name || ""}
            </Text>
          </Box>

          {!isNil(nft?.providerImageUrl) && (
            <img
              style={{
                width: 18,
                top: 2,
                position: "relative",
                height: 18,
                borderColor: border,
                borderRadius: 100,
                borderWidth: 1,
                objectFit: "cover",
              }}
              src={nft?.providerImageUrl || ""}
            />
          )}
        </div>

        <div
          style={{
            flexDirection: "row",
            marginTop: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isNil(nft.fiatPriceCents) && (
            <div
              style={{
                padding: "5px 10px",
                alignSelf: "flex-start",
                backgroundColor: secondaryBackground,
                borderRadius: 5,
                width: "100%",
              }}
            >
              <Text
                fontWeight="bold"
                fontSize="sm"
                style={{
                  textAlign: "center",
                  color: header,
                }}
              >
                {!isNil(nft.fiatPriceCents)
                  ? D(nft.fiatPriceCents).toFormat()
                  : "-"}
              </Text>
            </div>
          )}
          <div style={{ flex: 1 }} />
        </div>
      </div>
    </Link>
  );
});

export default React.memo(NFTRow);
