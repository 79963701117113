import BigNumber from "bignumber.js";

export const DEFAULT_DECIMALS = 19; // the max number of decimals for any token we support

export const DEFAULT_PAGE_SIZE = 20; // make sure it's same on backend too!

export const RECALCULATING_HELP_MESSAGE =
  "Click this button after you make changes to your transactions.";

export const SYNC_HELP_MESSAGE = "This will pull in recent transactions.";

export const DEFAULT_ROUNDING_MODE = BigNumber.ROUND_HALF_CEIL;

export const CURRENT_TAX_YEAR = 2023;
export const CURRENT_TAX_YEAR_STR = String(CURRENT_TAX_YEAR);
export const CURRENT_TAX_YEAR_OPTION = {
  label: CURRENT_TAX_YEAR_STR,
  value: CURRENT_TAX_YEAR_STR,
};

export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/awaken-portfolio/id6472827013?platform=iphone";
