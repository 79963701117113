import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Image,
  Text,
  Tooltip,
  useFormControlProps,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { Maybe } from "src/core";
import { useClientById, useMe } from "src/hooks";
import {
  DatePicker,
  Input,
  Select,
  Option,
  Checkbox,
} from "src/components/styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValues, getDefaultValues, schema } from "./form";
import { useTransactionSearch } from "src/hooks/useTransactionSearch";
import _, { filter, groupBy, orderBy, sortBy, truncate } from "lodash";
import { AssetTypeEnum, Query } from "src/api/generated/types";
import {
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
} from "src/api/fragments";
import {
  getAssetSymbolOrName,
  isUnnamedAsset,
} from "src/modules/ledger/assets";
import { colors } from "src/theme";
import "react-dates/initialize";
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates";
import { INTEGRATIONS } from "../AccountModal/constants";
import { api } from "src/api";
import { useQuery } from "@apollo/client";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { getAssetUrl } from "src/modules/getAssetUrl";
import { AssetIcon } from "src/components/styled/Assets";
import StatusTag from "src/components/styled/StatusTag";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  clientId: Maybe<string>;
};

const REVIEWED_OPTIONS: Option[] = [
  {
    value: "true",
    label: "Reviewed",
  },
  {
    value: "false",
    label: "Unreviewed",
  },
];

const ASSET_TYPES: Option[] = [
  {
    value: "nft",
    label: "NFTs",
  },
  {
    value: "fungible_token",
    label: "Tokens",
  },
];

const defaultValuesUseEffectCheck = (defaultValues: FormValues) =>
  JSON.stringify({
    ...defaultValues,
    accounts:
      defaultValues.accounts ?? []
        ? (defaultValues.accounts || []).map((a) => ({
            value: a.value,
            data: a.data,
            label: "", // remove JSX.Element label
          }))
        : null,
    providers: (defaultValues.providers ?? []).map((p) => ({
      value: p.value,
      data: p.data,
      label: "", // remove JSX.Element label
    })),
  });

function _FilterTxnsModal({ handleHide, show: isVisible, clientId }: Props) {
  const { accounts, assets, getAssets } = useClientById(clientId);
  const { filters, updateFilters } = useTransactionSearch();
  const { me } = useMe("cache-first");
  const { data } = useQuery<Pick<Query, "getLabels">>(api.rules.getLabels, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    getAssets();
  }, []);

  // build a list of options from the accounts / wallets
  const accountOptions = useMemo(
    () =>
      accounts.map((a) => ({
        account: a,
        value: a.id,
        name: a.description,
        label: a.description,
      })),
    [JSON.stringify(accounts, null)]
  );

  const providerOptions = useMemo(
    () =>
      INTEGRATIONS.map((i) => ({
        value: i.provider,
        label: i.name,
        logoUrl: i.logoUrl,
      })),
    []
  );

  const assetOptions = useMemo(() => {
    const [realAssets, spamAssets] = _.partition(assets, (a) => !a.isSpam);

    const sortedAssets = _.sortBy(
      realAssets.map((a) => ({
        ...a,
        key: _getAssetKey(a),
      })),
      (a) => a.key
    );

    const realAssetsByKey = groupBy(sortedAssets, (a) => a.key);

    return orderBy(
      [
        ...Object.entries(realAssetsByKey).map(([key, assets]) => ({
          value: key,
          isUnnamedAsset: isUnnamedAsset(assets[0]), // deprecated field
          label: getAssetSymbolOrName(assets[0]),
          assets: assets,
        })),
        ...spamAssets.map((a) => ({
          value: a.id,
          isUnnamedAsset: isUnnamedAsset(a), // deprecated field
          label: getAssetSymbolOrName(a),
          assets: [a],
        })),
      ],
      // this way we have priced assets show up FIRST when filtering through. ex. looking up "USDC"
      // when there are a ton of spam usdc but they aren't labeled, we want priced to be at the top
      (a) => a.assets.some((a) => a.coinGeckoTokenId),
      "desc"
    ).filter((a) => !!a.label);
  }, [JSON.stringify(assets)]);

  const labelOptions = data?.getLabels ?? [];

  const defaultValues = useMemo(() => {
    const accountIds = new Set(filters.accountIds ?? []);
    const providers = new Set(filters.providers ?? []);
    const labels = new Set(filters.labels ?? []);
    // FIXME: this is rlly asset keys. it isn't a specific match on ID atm it is by the coingecko or identifier
    const assetIds = new Set(filters.assetIds ?? []);

    const selectedAccountOptions =
      accountIds.size > 0
        ? accountOptions.filter((o) => accountIds.has(o.value))
        : null;

    const selectedProviderOptions =
      providers.size > 0
        ? providerOptions.filter((o) => providers.has(o.value))
        : null;

    const selectedAssetOptions =
      assetIds.size > 0
        ? assetOptions.filter((a) => {
            const aIds = a.assets.map((a) => a.id);
            return aIds.some((id) => assetIds.has(id));
          })
        : filters.assetSymbolOrName
        ? assetOptions.filter((a) => a.label === filters.assetSymbolOrName)
        : null;

    const selectedLabelOptions =
      labels.size > 0 ? labelOptions.filter((a) => labels.has(a.value)) : null;

    const startDate = filters.startDate
      ? new Date(filters.startDate).toISOString()
      : null;

    const endDate = filters.endDate
      ? new Date(filters.endDate).toISOString()
      : null;

    return getDefaultValues({
      search: filters.search,
      startDate,
      accounts: selectedAccountOptions,
      providers: selectedProviderOptions,
      endDate,
      isMissingBasis: filters.isMissingBasis ?? null,
      hasIncome: filters.hasIncome ?? null,
      hasNotes: filters.hasNotes ?? null,
      assets: selectedAssetOptions || null,
      assetType: ASSET_TYPES.find((o) => o.value === filters.assetType) || null,
      reviewed:
        REVIEWED_OPTIONS.find((o) => o.value === filters.reviewed) || null,
      labels: selectedLabelOptions,
    });
  }, [accountOptions, assetOptions, labelOptions]); // Note: do not add the filters as a dependency

  const {
    formState: { isSubmitting },
    control,
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = (values: FormValues) => {
    // remove any spaces or ' or " from the string
    const search = values.search?.trim() || "";
    // .replace(/['"]/g, ""); <- do not do this! breaks constraints
    const assetIds = (values.assets || [])
      .map((a) => a.assets.map((a) => a.id))
      .flat();

    console.log("ASSET IDS", assetIds);

    const newFilters = {
      startDate: values.startDate,
      endDate: values.endDate,
      accountIds: (values.accounts || []).map((a) => a.value),
      labels: (values.labels || []).map((a) => a.value),
      providers: (values?.providers || []).map((p) => p.value),
      search: search,
      assetIds,
      assetType: values.assetType?.value ?? null,
      assetSymbolOrName: null, // !isUnnamedAsset ? values?.asset?.label : undefined, <- deprecated
      reviewed: values.reviewed?.value ?? null,
      // we only set to true if it is true. otherwise it is null. not missing basis is the same as filtering for ALL txns
      isMissingBasis: values.isMissingBasis === true ? true : null,
      hasIncome: values.hasIncome === true ? true : null,
      hasNotes: values.hasNotes === true ? true : null,
      page: 0,
      includeSpam: assetIds.length > 0 ? true : null,
    };

    // this updates the url
    updateFilters(newFilters);

    handleHide();
  };

  const theme = useTheme();

  // Note: don't change this stringify part. it was causing a bug where the default values were updated
  // and then it just would remove stuff from the url. super weird. whatever.
  useEffect(
    () => reset(defaultValues),
    [defaultValuesUseEffectCheck(defaultValues)]
  );

  const isSuperUser = me?.isSuperuser || false;

  return (
    <>
      {/* other referenced modals */}
      <Modal
        title="Search Transactions"
        headerProps={{
          padding: "1rem",
          marginBottom: 0,
        }}
        titleHeaderProps={{
          style: {
            fontSize: 18,
            marginBottom: 0,
          },
        }}
        bodyProps={{
          style: {
            padding: "1rem",
            maxHeight: "80vh",
            overflowY: "scroll",
          },
        }}
        isVisible={isVisible}
        handleHide={handleHide}
        w="95%"
        maxW="600px"
        footerProps={{
          style: {
            padding: "1rem",
          },
        }}
        Footer={
          <Button
            onClick={handleSubmit(onSubmit, (e) => console.error(e))}
            width="100%"
            variant="primary"
            fontSize="sm"
            isLoading={isSubmitting}
            style={{ position: "relative" }}
          >
            Search{" "}
            <i
              style={{ fontSize: 18, position: "absolute", top: 10, right: 25 }}
              className="fa-sharp fa-arrow-right"
            />
          </Button>
        }
      >
        <Box>
          <Input
            labelIconName="fa-sharp fa-search"
            label="Search (across most of transaction fields)"
            name="search"
            placeholder="Name / Hash / Contract / Function Name / Notes"
            control={control}
          />

          <HStack alignItems="flex-start" w="100%">
            <Flex flex={1}>
              <Select
                label="Status"
                labelIconName="fa-sharp fa-check"
                options={REVIEWED_OPTIONS}
                name="reviewed"
                placeholder=""
                control={control}
                selectProps={{ isClearable: true }}
                containerStyle={{ width: "100%" }}
              />
            </Flex>

            <Flex flex={1}>
              <Select
                label="Only specific labels"
                labelIconName="fa-sharp fa-tags"
                options={labelOptions}
                name="labels"
                placeholder=""
                control={control}
                selectProps={{
                  isMulti: true,
                  styles: {
                    multiValue: (base, state) => ({
                      ...base,
                      minWidth: "inherit",
                      backgroundColor: theme.secondaryBackground,
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: theme.background,
                      borderColor: theme.border,
                      color: theme.header,
                      border: "none",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: theme.text,
                    }),
                    indicatorSeparator: (p) => ({ ...p, display: "none" }),
                    singleValue: (p) => ({ ...p, color: theme.header }),
                    container: (container) => ({
                      ...container,
                      backgroundColor: theme.background,
                      borderRadius: "5px",
                      border: "1px solid " + theme.border,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    input: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                  },
                }}
                containerStyle={{ width: "100%" }}
              />
            </Flex>
          </HStack>

          <HStack alignItems="flex-start" w="100%">
            <Flex flex={1} flexGrow={1}>
              <Select
                label="Account"
                labelIconName="fa-sharp fa-wallet"
                options={accountOptions}
                name="accounts"
                containerStyle={{ width: "100%" }}
                placeholder=""
                control={control}
                selectProps={{
                  isMulti: true,
                  isClearable: false,
                  styles: {
                    input: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    multiValue: (base, state) => ({
                      ...base,
                      minWidth: "inherit",
                      color: theme.header,
                      backgroundColor: theme.secondaryBackground,
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: theme.background,
                      borderColor: theme.border,
                      border: "none",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: theme.text,
                    }),
                    indicatorSeparator: (p) => ({ ...p, display: "none" }),
                    singleValue: (p) => ({ ...p, color: theme.header }),
                    container: (container) => ({
                      ...container,
                      backgroundColor: theme.background,
                      borderRadius: "5px",
                      border: "1px solid " + theme.border,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  },
                  components: {
                    MultiValueLabel: ({ innerProps, data }) => (
                      <Box {...innerProps}>
                        <Tooltip
                          label={(data as any).account?.description || ""}
                        >
                          <Text
                            fontSize="xs"
                            style={{
                              maxWidth: 200,
                            }}
                          >
                            {(data as any).account?.description || ""}
                          </Text>
                        </Tooltip>
                      </Box>
                    ),
                    Option: ({ innerRef, innerProps, label, data }) => (
                      <Tooltip
                        openDelay={500}
                        label={(data as any).account?.description || ""}
                      >
                        <HStack
                          w="100%"
                          padding="0.75rem 0.5rem"
                          ref={innerRef}
                          {...innerProps}
                          _hover={{
                            bg: colors.gray90,
                            cursor: "pointer",
                          }}
                        >
                          <Image
                            style={{ borderRadius: 3 }}
                            src={(data as any).account?.iconImageUrl}
                            w="1rem"
                            h="1rem"
                            margin="0 0.5rem"
                          />
                          <div style={{ fontSize: 14 }}>
                            {truncate(
                              (data as any).account?.description || "",
                              {
                                length: 25,
                              }
                            )}
                          </div>
                        </HStack>
                      </Tooltip>
                    ),
                  },
                }}
              />
            </Flex>
            <Flex flex={1}>
              <Select
                label="Chain or exchange"
                labelIconName="fa-sharp fa-exchange"
                options={providerOptions}
                name="providers"
                placeholder=""
                control={control}
                selectProps={{
                  isClearable: true,
                  isMulti: true,
                  styles: {
                    input: (base) => ({
                      color: theme.header,
                    }),
                    multiValue: (base, state) => ({
                      ...base,
                      minWidth: "inherit",
                      color: theme.header,
                      background: theme.secondaryBackground,
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: theme.background,
                      borderColor: theme.border,
                      border: "none",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: theme.text,
                    }),
                    indicatorSeparator: (p) => ({ ...p, display: "none" }),
                    singleValue: (p) => ({ ...p, color: theme.header }),
                    container: (container) => ({
                      ...container,
                      backgroundColor: theme.background,
                      borderRadius: "5px",
                      border: "1px solid " + theme.border,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  },
                  components: {
                    Option: ({ innerRef, innerProps, label, data }) => (
                      <HStack
                        w="100%"
                        padding="0.75rem 0.5rem"
                        ref={innerRef}
                        {...innerProps}
                        _hover={{
                          bg: colors.gray90,
                          cursor: "pointer",
                        }}
                      >
                        {(data as any)?.logoUrl ? (
                          <Image
                            style={{ borderRadius: 3 }}
                            src={(data as any).logoUrl}
                            w="1rem"
                            h="1rem"
                            margin="0 0.5rem"
                          />
                        ) : (
                          <Box
                            style={{
                              width: "1rem",
                              height: "1rem",
                              justifyContent: "center",
                              display: "flex",
                              flexDirection: "revert",
                              alignItems: "center",
                              borderRadius: 5,
                              fontSize: 12,
                              fontWeight: "bold",
                            }}
                            bg={colors.gray80}
                          >
                            {(data as any).label?.charAt(0).toUpperCase()}
                          </Box>
                        )}

                        <div style={{ fontSize: 14 }}>
                          {(data as any).label}
                        </div>
                      </HStack>
                    ),
                  },
                }}
                containerStyle={{ width: "100%" }}
              />
            </Flex>
          </HStack>

          <HStack alignItems="flex-start" w="100%">
            <Flex flex={1}>
              <Select
                label="Assets"
                labelIconName="fa-sharp fa-coins"
                options={assetOptions}
                name="assets"
                control={control}
                placeholder=""
                containerStyle={{
                  width: "100%",
                }}
                selectProps={{
                  isClearable: true,
                  isMulti: true,
                  styles: {
                    input: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    multiValue: (base, state) => ({
                      ...base,
                      minWidth: "inherit",
                      color: theme.header,
                      background: theme.secondaryBackground,
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: theme.header,
                    }),
                    control: (base) => ({
                      ...base,
                      backgroundColor: theme.background,
                      borderColor: theme.border,
                      color: theme.header,
                      border: "none",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: theme.text,
                    }),
                    indicatorSeparator: (p) => ({ ...p, display: "none" }),
                    singleValue: (p) => ({ ...p, color: theme.header }),
                    container: (container) => ({
                      ...container,
                      backgroundColor: theme.background,
                      borderRadius: "5px",
                      border: "1px solid " + theme.border,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  },
                  components: {
                    MultiValueLabel: ({ innerProps, data }) => (
                      <HStack {...innerProps}>
                        <AssetsImage assets={(data as any)?.assets || []} />
                        <Text fontSize="sm">{(data as any).label || ""}</Text>
                      </HStack>
                    ),
                  },
                }}
              />
            </Flex>

            <Flex flex={1}>
              <Select
                label="Asset Types"
                labelIconName="fa-sharp fa-filter"
                options={ASSET_TYPES}
                name="assetType"
                containerStyle={{
                  width: "100%",
                }}
                control={control}
                placeholder=""
                selectProps={{
                  isClearable: true,
                }}
              />
            </Flex>
          </HStack>

          <HStack>
            <Flex flex={1}>
              <DatePicker
                label="Start Date"
                labelIconName="fa-sharp fa-calendar"
                control={control}
                containerStyle={{ width: "100%" }}
                name="startDate"
                showTimeSelect={false}
              />
            </Flex>
            <Box style={{ margin: "0 1rem" }}>
              <i
                style={{
                  position: "relative",
                  top: 8,
                  fontSize: 18,
                  color: colors.gray30,
                }}
                className="fa-sharp fa-long-arrow-right"
              />
            </Box>
            <Flex flex={1}>
              <DatePicker
                label="End Date"
                labelIconName="fa-sharp fa-calendar"
                control={control}
                containerStyle={{
                  width: "100%",
                  backgroundColor: theme.background,
                }}
                name="endDate"
                showTimeSelect={false}
              />
            </Flex>
          </HStack>

          <Divider style={{ margin: "1rem 0" }} />

          <Checkbox
            containerStyle={{ marginBottom: "1.5rem" }}
            label="Missing purchase price"
            labelIconName="fa-sharp fa-dollar-sign"
            name="isMissingBasis"
            placeholder="Only show transactions with no price"
            control={control}
          />

          <Checkbox
            containerStyle={{ marginBottom: "1.5rem" }}
            label="Show transactions with income"
            labelIconName="fa-sharp fa-sack-dollar"
            name="hasIncome"
            placeholder="Only show transactions with income"
            control={control}
          />

          <Checkbox
            containerStyle={{ marginBottom: "1.5rem" }}
            label="Show transactions with notes"
            labelIconName="fa-sharp fa-sticky-note"
            name="hasNotes"
            placeholder="Only show transactions with notes"
            control={control}
          />
        </Box>
      </Modal>
    </>
  );
}

const _getAssetKey = (asset: BaseAssetFields): string => {
  if (asset.type === AssetTypeEnum.FiatCurrency) {
    return asset.symbol || asset.id;
  }
  if (asset.type === AssetTypeEnum.Nft) {
    return `${asset.contractAddress}-${asset.tokenId || ""}`;
  }
  if (asset.type === AssetTypeEnum.FungibleToken) {
    return asset.symbol || asset.id;
  }
  return asset.id;
};

const AssetsImage = ({ assets }: { assets: BaseAssetFields[] }) => {
  // make it so we switch through these random assets every 2.5 seconds and render using the <AssetIcon
  const [assetIndex, setAssetIndex] = useState(0);
  // const [asset, setAsset] = useState(assets[0]);

  // useEffect(() => {
  //   const images = assets.map((a) => getAssetUrl(a));
  //   // if every image is the same, return
  //   if (images.every((i) => i === images[0])) return;
  //   // only if all assets are NFT
  //   if (assets.every((a) => a.type === AssetTypeEnum.Nft)) return;
  //   // if only one asset, return
  //   if (assets.length === 1) return;

  //   const interval = setInterval(() => {
  //     // Delay setting the new asset and resetting the fade to create a fade effect
  //     setTimeout(() => {
  //       // Calculate the next asset index, looping back to 0 if needed
  //       const nextIndex = (assetIndex + 1) % assets.length;
  //       setAssetIndex(nextIndex);

  //       // Set the corresponding asset from the assets array
  //       setAsset(assets[nextIndex]);
  //     }, 500); // Delay for 0.5 seconds for the fade effect
  //   }, 2500); // Interval of 2.5 seconds in milliseconds

  //   // Cleanup the interval when the component unmounts
  //   return () => clearInterval(interval);
  // }, [assetIndex, assets]);

  const asset = assets[0];

  if (!asset) {
    return null;
  }

  return <AssetIcon textStyle={{ fontSize: 8 }} size={20} asset={asset} />;
};

export const FilterTxnsModal = connectModal({
  name: "FilterTxnsModal",
})(_FilterTxnsModal);
