import { useLazyQuery } from "@apollo/client";
import {
  Text,
  Divider,
  Heading,
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import {
  GetNumTxnTypesResponse,
  PortfolioResponse,
  Transaction as TransactionType,
} from "src/api/generated/types";
import WhiteBox from "src/components/styled/WhiteBox";
import { colors, other } from "src/theme";
import { useClientById } from "src/hooks";
import { Transaction } from "./Transaction";
import { Touchable } from "src/components/Touchable";
import ReactPaginate from "react-paginate";
import { getAssetSymbolOrName } from "src/modules/ledger/assets";
import { AssetKeyInfoContext } from "../utils";
import { ActionSheet, DatePicker } from "src/components";
import { Maybe, hasValue } from "src/core";
import { keyBy, truncate, uniqBy } from "lodash";
import { BaseAccountFields } from "src/api/fragments";
import Helpers from "src/utils/helpers";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTransactions } from "./useTransactions";
import { useTheme } from "src/hooks/useTheme";

const LIMIT = 10;

export const Transactions = () => {
  const [page, setPage] = useState(0);
  const theme = useTheme();

  const { client, assetKeyV2 } = useContext(AssetKeyInfoContext);

  const clientId = client?.id || "";
  const assetKey = assetKeyV2 || "";

  const { transactions, total, loading } = useTransactions(
    clientId,
    assetKey,
    page
  );

  const _setPage = (newPage: number) => {
    if (newPage < 0) {
      setPage(0);
    } else {
      setPage(newPage);
    }
  };

  const totalPages = useMemo(() => Math.ceil(total / LIMIT), [total]);

  return (
    <div style={{ width: "100%", flexShrink: 0 }}>
      <HStack alignItems="center" marginBottom="1.5rem">
        <HStack flex={1}>
          <Text
            color={theme.header}
            fontSize={18}
            fontWeight="semibold"
            marginRight="5px"
          >
            Transactions
          </Text>
          {/* <Link style={{ display: "flex" }} to={link}>
            <Touchable label="View" iconName="fa-sharp fa-external-link-alt" />
          </Link> */}
        </HStack>
      </HStack>

      <div
        style={{
          border: `1px solid ${theme.border}`,
          borderRadius: 7,
        }}
      >
        <WhiteBox
          style={{
            boxShadow: "none", // other.lighterBoxShadow,
          }}
          overflow="hidden"
          padding="0"
          border="none"
          marginTop="0"
          width="100%"
          h="100%"
          overflowX="scroll"
        >
          {loading && !transactions.length ? (
            <div>
              <div
                style={{
                  padding: "4rem",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Spinner size="sm" style={{ marginRight: 15 }} /> Loading...
              </div>
            </div>
          ) : (
            transactions.map((t, i) => (
              <Transaction
                timezone={client?.timezone || "UTC"}
                transaction={t}
                key={t.id}
                limitedView
                isLast={transactions.length - 1 === i}
              />
            ))
          )}
        </WhiteBox>
      </div>

      <Divider style={{ borderColor: "transparent", margin: "1rem 0" }} />

      <HStack padding="0.25rem 0">
        <HStack
          flex={1}
          marginLeft={5}
          alignItems="center"
          justifyContent="flex-end"
        >
          <ReactPaginate
            breakLabel=".."
            nextLabel={<i className="fa-sharp fa-chevron-right" />}
            onPageChange={({ selected }) => _setPage(selected)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            forcePage={page}
            initialPage={page || 0}
            pageCount={totalPages}
            previousLabel={<i className="fa-sharp fa-chevron-left" />}
            pageClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            pageLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            previousClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            previousLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            nextClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            nextLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            breakClassName={
              theme.theme === "light" ? "page-item" : "dark-page-item"
            }
            breakLinkClassName={
              theme.theme === "light" ? "page-link" : "dark-page-link"
            }
            containerClassName="pagination"
            activeClassName="active"
          />
        </HStack>
      </HStack>
    </div>
  );
};

const AccountSelector = ({
  setAccountIds,
  accountIds,
  assetKey,
}: {
  setAccountIds: (acctId: string[]) => void;
  accountIds: string[];
  assetKey: string;
}) => {
  const { clientId } = useParams();
  const {
    accounts: allAccounts,
    assets,
    client,
    getAssets,
  } = useClientById(clientId);
  const [isShown, setShown] = useState(true);

  const accountById = useMemo(
    () => keyBy(allAccounts, (a) => a.id),
    [allAccounts]
  );
  const assetById = useMemo(() => keyBy(assets, (a) => a.id), [assets]);

  const [
    fetchAssetPortfolio,
    { data: portfolioData, loading: positionsLoading, error: positionsErrors },
  ] = useLazyQuery<{
    getPortfolio: Pick<PortfolioResponse, "total" | "balances">;
  }>(api.portfolio.getPortfolioAssets, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!clientId) return;
    fetchAssetPortfolio({
      variables: { clientId, assetKey },
    });
  }, [clientId]);

  const _accounts = useMemo((): BaseAccountFields[] => {
    const assetBalances = (portfolioData?.getPortfolio?.balances || []) ?? [];

    console.log(assetBalances);

    const breakdown = assetBalances
      .flatMap((b) => b.breakdown)
      // filter the breakdown with highest amount to lowest amount
      .sort((a, b) => b.amount - a.amount);

    const accounts = breakdown.map((b) => accountById[b.accountId]);

    return accounts.filter(hasValue);
  }, [portfolioData, accountById]);

  const selectedAccounts = useMemo(
    () => accountIds.map((id) => accountById[id]).filter(hasValue),
    [accountIds, accountById]
  );

  const selectedAccount = selectedAccounts[0]; // only allow selecting one for now
  const isLarge = useIsLargeScreen();
  const accounts = uniqBy(_accounts, (a) => a.id);

  return (
    <ActionSheet
      content={{ width: 200, maxHeight: 300, overflowY: "scroll" }}
      commands={accounts.map((a) => ({
        label: truncate(a.description || "Account", { length: 15 }),
        iconImageSrc: a.iconImageUrl,
        onClick: () => setAccountIds(Array.from(new Set([a.id]))),
      }))}
    >
      <HStack
        style={{
          backgroundColor: colors.gray90,
          border: `1px solid ${colors.gray80}`,
          borderRadius: 7,
          height: 35,
          cursor: "pointer",
          justifyContent: "flex-start",
          alignItems: "center",
          color: colors.gray20,
          padding: "0 0.5rem",
          width: isLarge ? 200 : 150,
        }}
      >
        {selectedAccount ? (
          <>
            <Image
              w="1rem"
              h="1rem"
              borderRadius="100%"
              src={selectedAccount.iconImageUrl}
              right="-0.4rem !important"
              bottom="-0.4rem !important"
              border={"1px solid " + colors.gray80}
              bg={colors.white}
            />
            <Text fontSize="sm" flex={1}>
              {Helpers.capitalize(
                truncate(selectedAccount.description || "Account", {
                  length: 10,
                })
              )}
            </Text>
            <Touchable
              iconName="fa-sharp fa-times"
              onClick={(e) => {
                e.stopPropagation();
                setAccountIds([]);
              }}
            />
            <i style={{ marginLeft: 5 }} className="fa-sharp fa-chevron-down" />
          </>
        ) : (
          <>
            <i style={{ marginRight: 5 }} className="fa-sharp fa-wallet" />
            <Text flex={1} fontSize="sm">
              Select
            </Text>
            <i
              style={{ marginLeft: 10 }}
              className="fa-sharp fa-chevron-down"
            />
          </>
        )}
      </HStack>
    </ActionSheet>
  );
};
