import { Maybe } from "src/core";
import StatusTag from "../styled/StatusTag";

type ReviewStatusTagProps = {
  isReviewed: boolean;
  capPriority?: Maybe<number>;
  needsRecalculate: boolean;
  type?: string | null;
};

export const ReviewStatusTag = ({
  isReviewed,
  capPriority,
  type,
  needsRecalculate,
}: ReviewStatusTagProps) => {
  if (needsRecalculate) {
    return (
      <StatusTag
        iconName="fa-sharp fa-question-circle"
        type={"info"}
        boxProps={{ style: { flexWrap: "nowrap" } }}
        label="Needs Recalculate"
      />
    );
  } else if (isReviewed) {
    return (
      <StatusTag
        iconName="fa-sharp fa-check-circle"
        type="success"
        label={"Reviewed"}
        boxProps={{ style: { flexWrap: "nowrap" } }}
      />
    );
  } else {
    return (
      <StatusTag
        iconName="far fa-circle"
        type={capPriority === 3 ? "error" : "warning"}
        boxProps={{ style: { flexWrap: "nowrap" } }}
        label="Unreviewed"
      />
    );
  }
};
