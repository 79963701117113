import { Box, HStack, Text } from "@chakra-ui/react";
import { Dictionary } from "lodash";
import { Link } from "react-router-dom";
import { AccountProviderEnum, AccountTypeEnum } from "src/api/generated/types";
import { Account } from "src/components/Accounts";
import { Info } from "src/components/styled";
import { config } from "src/config";
import { Maybe } from "src/core";
import { colors } from "src/theme";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import { IntegrationProviderInfo, IntegrationAction } from "./types";
import { useTheme } from "src/hooks/useTheme";

const _img = getImageKitCDNUrl;

export const ExtraordinaryChain = () => {
  const theme = useTheme();

  return (
    <HStack marginTop="5px !important" alignItems="center">
      <Text fontSize="sm" color={theme.text}>
        Extraordinary support 🎖
        <Info message="Awaken is light years ahead of every other tax product on the market. It's the only product to support staking, Uniswap V3 LP, and more. When you label 1 transaction, 10+ others may get labeled for you." />
      </Text>
    </HStack>
  );
};

const WatchVideo = ({ demoUrl }: { demoUrl: string }) => {
  const theme = useTheme();

  return (
    <HStack marginTop="0.75rem !important" alignItems="center">
      {/* font awesome warning icon */}
      <i
        className="fa-sharp fa-video"
        style={{
          color: colors.primary,
          fontSize: 12,
        }}
      />
      <Text color={theme.text} fontSize="sm" marginLeft="5px !important">
        Video Tutorial:{" "}
        <a
          target="_blank"
          style={{
            color: colors.primary,
            fontWeight: "bold",
            textDecoration: "underline",
          }}
          href={demoUrl}
        >
          click here
        </a>
      </Text>
    </HStack>
  );
};

const KucoinDescription = () => {
  const theme = useTheme();

  return (
    <Box marginTop="0.75rem !important">
      <WatchVideo demoUrl=" https://www.loom.com/share/cb0ffe1b26a0433eb5f04a34afab1baf" />

      <HStack marginTop="0.75rem" alignItems="flex-start">
        {/* font awesome warning icon */}
        <i
          className="fa-sharp fa-triangle-exclamation"
          style={{
            color: colors.yellow50,
            fontSize: 12,
            position: "relative",
            top: 2,
          }}
        />
        <Text color={theme.text} fontSize="sm" marginLeft="3px !important">
          Please upload your deposits and withdrawals as a separate CSV in our
          format.
          <Info message="If you need help with this, just send us a message!" />
        </Text>
      </HStack>
    </Box>
  );
};

const AnyCSVDescription = ({ word }: { word: string }) => {
  const theme = useTheme();

  return (
    <div style={{ marginTop: "5px", fontSize: 14, color: theme.text }}>
      Upload a CSV file from any {word} not listed.
    </div>
  );
};

const GeminiDescription = () => {
  const theme = useTheme();

  return (
    <Box marginTop="0.75rem !important">
      {/* <WatchVideo demoUrl=" https://www.loom.com/share/cb0ffe1b26a0433eb5f04a34afab1baf" /> */}

      <HStack marginTop="0.75rem" alignItems="flex-start">
        {/* font awesome warning icon */}
        <i
          className="fa-sharp fa-triangle-exclamation"
          style={{
            color: colors.yellow50,
            fontSize: 12,
            position: "relative",
            top: 2,
          }}
        />
        <Text color={theme.text} fontSize="sm" marginLeft="3px !important">
          We do not support the now defunct Gemini Earn product.
          <Info message="If you need help with this, just send us a message!" />
        </Text>
      </HStack>
    </Box>
  );
};

const KrakenDescription = () => {
  const theme = useTheme();

  return (
    <>
      <WatchVideo demoUrl="https://www.loom.com/share/74faa4f8c5344645b8e7a4f2907d9745" />
      <HStack marginTop="0.75rem !important" alignItems="center">
        {/* font awesome warning icon */}
        <i
          className="fa-sharp fa-triangle-exclamation"
          style={{
            color: colors.yellow50,
            fontSize: 12,
          }}
        />
        <Text color={theme.text} fontSize="sm" marginLeft="3px !important">
          We do not support OTC, Margin, or Futures
          <Info message="If you have these types of trades, send us a message and we can help you out! You'll need to upload a CSV of OTC, Margin, or Futures trades into Awaken to include them in your tax return." />
        </Text>
      </HStack>
    </>
  );
};

const getStakeTaxLink = (chain: string, tabName: string) => {
  return (
    <Box
      marginTop="0.75rem !important"
      marginBottom="1.5rem"
      alignItems="flex-start"
      style={{
        padding: "0.75rem",
        backgroundColor: colors.lightBlue100,
        borderRadius: 10,
      }}
    >
      You can get a CSV to upload for {chain} in just a <b>couple of minutes</b>
      !
      <br />
      <br />
      1. Go to{" "}
      <a
        style={{
          fontWeight: "bold",
          color: colors.primary,
          textDecoration: "underline",
        }}
        href="https://stake.tax/"
        target="_blank"
      >
        stake.tax
      </a>{" "}
      and click the "{tabName}" tab.
      <br /> <br />
      2. When the CSV generation is complete, you can download the format that
      says "awakentax"!
    </Box>
  );
};

const getZKExportLink = (chain: string) => (
  <Box
    marginTop="0.75rem !important"
    marginBottom="1.5rem"
    alignItems="flex-start"
    style={{
      padding: "0.75rem",
      backgroundColor: colors.lightBlue100,
      borderRadius: 10,
    }}
  >
    You can get a CSV to upload for {chain} in just a <b>couple of minutes</b>!
    <br />
    <br />
    1. Go to{" "}
    <a
      style={{
        fontWeight: "bold",
        color: colors.primary,
        textDecoration: "underline",
      }}
      href="https://zkexport.netlify.app"
      target="_blank"
    >
      zkexport
    </a>{" "}
    and add your wallet address.
    <br /> <br />
    2. Download the CSV in the Awaken format.
    <br /> <br />
    3. Upload that CSV to Awaken!
  </Box>
);

export const AwakenCSVLink = ({ chain }: { chain: string }) => {
  const theme = useTheme();

  return (
    <Box
      marginTop="0.75rem !important"
      marginBottom="1.5rem"
      alignItems="flex-start"
      style={{
        padding: "1rem",
        backgroundColor:
          theme.theme === "light" ? colors.lightBlue100 : colors.lightBlue10,
        borderRadius: 10,
        fontSize: 16,
        color: theme.header,
      }}
    >
      Use AI to format your CSV for {chain}! You can try it out by going to{" "}
      <a
        style={{
          fontWeight: "bold",
          color: colors.primary,
          textDecoration: "underline",
        }}
        href="https://csv.awaken.tax/"
        target="_blank"
      >
        csv.awaken.tax
      </a>
      !
    </Box>
  );
};

// This makes it sound like all our other integrations are "less than great"
// const GREAT_EXCHANGE = (
//   <HStack marginTop="0 !important" alignItems="center">
//     <Text fontSize="sm">
//       🥇 Great support
//       <Info message="Awaken has great support for this exchange. We make sure to pull in all of your transactions, and automatically label most if not all of them." />
//     </Text>
//   </HStack>
// );

const imageSize = { width: 80, height: 80 };

export const INTEGRATIONS: IntegrationProviderInfo[] = [
  {
    name: "Ethereum",
    isLive: true,
    provider: "ethereum",
    keywordsV2: ["ledger", "phantom", "rainbow wallet"],
    keywords: "metamask,coinbase wallet,rainbow wallet,phantom,ledger",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/eth.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "ethereum",

        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "ethereum",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    isExtraordinary: true,
  },
  {
    name: "Coinbase",
    provider: "coinbase",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/coinbase.svg",
      imageSize
    ),
    type: AccountTypeEnum.Exchange,
    options: [
      // {
      //   action: IntegrationAction.HatchfiLink,
      //   provider: "coinbase",
      //   hatchfiProvider: "coinbase",
      //   buttonText: "Connect (old)",
      //   modalName: null,
      // },
      {
        action: IntegrationAction.Oauth,
        provider: "coinbase",
        hatchfiProvider: null,
        buttonText: "Connect",
        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinbase",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    /*
    yeah they do. we arent worse than them, basically the same. maybe slightly better (idk if cointracker supports spending crypto which you can do with coinbase cardspend and we support)
    maybe a 4. not a 5 because it can be flakey and require a reconnect. that isnt about data qulaity
    data quality prob a 5. 4 experience. 4/5 data quality
    */
  },
  {
    name: "Polygon",
    provider: "polygon",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/matic.png",
      imageSize
    ),
    isLive: true,
    keywords: "coinbase wallet,phantom",
    // isNew: true,
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "polygon",

        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "polygon",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    isExtraordinary: true,
  },
  {
    name: "Optimism",
    provider: "optimism",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/optimism.svg",
      imageSize
    ),
    isLive: true,
    keywords: "coinbase wallet",
    // isNew: true,
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "optimism",

        // onlySuperUser: true,
        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "optimism",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    isExtraordinary: true,
  },
  {
    name: "Arbitrum",
    provider: "arbitrum",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/arbitrum.png",
      imageSize
    ),
    keywords: "metamask,coinbase wallet",
    isLive: true,
    // isNew: true,
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "arbitrum",

        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "arbitrum",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    isExtraordinary: true,
  },
  {
    name: "Avalanche",
    provider: "avalanche",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/avalanche.svg",
      imageSize
    ),
    isLive: true,
    // isNew: true,
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "avalanche",

        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "avalanche",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    // description: (
    //   <HStack marginTop="0.75rem !important" alignItems="center">
    //     {/* font awesome warning icon */}
    //     <i
    //       className="fa-sharp fa-triangle-exclamation"
    //       style={{
    //         color: colors.yellow50,
    //         fontSize: 12,
    //       }}
    //     />
    //     <Text fontSize="sm" marginLeft="3px !important">
    //       We temporarily don't support internal transactions.{" "}
    //       <Info message="Due to the recent migration of the Snowtrace block explorer, internal transactions are not supported so you may notice some of your transactions are missing cost basis. We will have this fixed before the tax season begins, and update your account fixing this without any action needed from you." />
    //     </Text>
    //   </HStack>
    // ),
    // isExtraordinary: true,
  },
  {
    name: "Base",
    provider: "base",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/base.png",
      imageSize
    ),
    isLive: true,
    isBeta: false,
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "base",
        hatchfiProvider: null,
        buttonText: "Connect",
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "base",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
    isExtraordinary: true,
  },
  {
    name: "Solana",
    provider: "solana",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/solana.svg",
      imageSize
    ),
    isLive: true,
    // isNew: true,
    // isBeta: true,
    keywords: "phantom,solflare",
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "solana",

        // onlySuperUser: true,
        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "solana",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    // description: (
    //   <Text fontSize="sm" marginLeft="3px !important" color="blue">
    //     Automated support coming in a few days! 👀
    //   </Text>
    // ),
  },
  {
    name: "Kucoin",
    isLive: true,
    provider: "kucoin",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/kucoin.png",
      imageSize
    ),
    type: AccountTypeEnum.Exchange,
    description: <KucoinDescription />,
    options: [
      // {
      //   action: IntegrationAction.HatchfiLink,
      //   provider: "kucoin",
      //   hatchfiProvider: "kucoin",
      //
      //   modalName: null,
      // },
      {
        action: IntegrationAction.FileUpload,
        provider: "kucoin",
        hatchfiProvider: null,
        modalName: null,
        buttonText: "File",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "kucoin",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Binance US",
    provider: "binance_us",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/binance-us.png",
      imageSize
    ),
    description: (
      <WatchVideo demoUrl="https://www.loom.com/share/a144558893654274973996f4d1f0c933" />
    ),
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.VezgoLink,
        provider: "binance_us",
        vezgoProvider: "binanceus",
        hatchfiProvider: null,
        modalName: null,
      },
      {
        action: IntegrationAction.FileUpload,
        provider: "binance_us",
        buttonText: "File",
        hatchfiProvider: null,
        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "binance_us",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Coinbase Pro",
    provider: "coinbase_pro",
    isLive: true,
    description: `Coinbase Pro is deprecated, but they change their CSV format every year. We recommend you format it with our CSV tool or use their exported gain/loss to handle the taxes for it separately.`,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/coinbase-pro.png",
      imageSize
    ),
    // isNew: true,
    type: AccountTypeEnum.Exchange,
    options: [
      // {
      //   action: IntegrationAction.FileUpload,
      //   provider: "coinbase_pro",
      //   hatchfiProvider: null,
      //   modalName: null,
      //   buttonText: "File",
      // },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinbase_pro",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },

  {
    name: "Binance Chain",
    provider: "bsc",
    keywords: "bsc,bnb chain,binance smart chain,binance",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/bnb.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    // description: (
    //   <HStack marginTop="0.75rem !important" alignItems="center">
    //     {/* font awesome warning icon */}
    //     <i
    //       className="fa-sharp fa-triangle-exclamation"
    //       style={{
    //         color: colors.yellow50,
    //         fontSize: 12,
    //       }}
    //     />
    //     <Text fontSize="sm" marginLeft="3px !important">
    //       ERC1155 transfers may not be pulled in
    //       <Info message="ERC1155 is an NFT standard that makes NFTs have fungible token properties (ex. it can have an amount that is not 1, unlike traditional NFTs). If you are an NFT trader on BNB, you may have traded ERC1155 tokens that may not be pulled in to Awaken. If you notice any missing tokens, send us a message and we can help you out!" />
    //     </Text>
    //   </HStack>
    // ),
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "bsc",

        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bsc",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Blast",
    provider: "blast",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/blast.png",
      imageSize
    ),
    isLive: true,
    isBeta: false,
    type: AccountTypeEnum.Wallet, // blockchain
    options: [
      {
        action: IntegrationAction.TriggerModal,
        // onlySuperUser: true,
        provider: "blast",
        hatchfiProvider: null,
        buttonText: "Connect",
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "blast",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Gemini",
    provider: "gemini",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/gemini.png",
      imageSize
    ),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    description: <GeminiDescription />,
    options: [
      // {
      //   action: IntegrationAction.HatchfiLink,
      //   provider: "gemini",
      //   hatchfiProvider: "gemini",
      //   modalName: null,
      // },
      {
        action: IntegrationAction.VezgoLink,
        provider: "gemini",
        vezgoProvider: "gemini",
        modalName: null,
        hatchfiProvider: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "gemini",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Kraken",
    provider: "kraken",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/kraken.png",
      imageSize
    ),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    // description: <KrakenDescription />,
    options: [
      // {
      //   action: IntegrationAction.HatchfiLink,
      //   provider: "kraken",
      //   hatchfiProvider: "kraken",
      //   modalName: null,
      // },
      {
        action: IntegrationAction.VezgoLink,
        provider: "kraken",
        vezgoProvider: "kraken",
        modalName: null,
        hatchfiProvider: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "kraken",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitcoin",
    provider: "bitcoin",
    keywords: "ledger",
    logoUrl: _img("https://assets.awaken.tax/icons/btc.png", imageSize),
    isLive: true,
    isBeta: false,
    type: AccountTypeEnum.Wallet,
    // description: (
    //   <HStack marginTop="0.75rem" alignItems="flex-start">
    //     <Text fontSize="sm" marginLeft="3px !important">
    //       Our Ordinals support is in Beta{" "}
    //       <i className="fa-sharp fa-party-horn" />
    //     </Text>
    //   </HStack>
    // ),
    options: [
      // xpub6CUGRUonZSQ4TWtTMmzXdrXDtypWKiKrhko4egpiMZbpiaQL2jkwSB1icqYh2cfDfVxdx4df189oLKnC5fSwqPfgyP3hooxujYzAu3fDVmz
      // {
      //   action: IntegrationAction.TriggerModal,
      //   provider: "bitcoin",
      //
      //   modalProps: {
      //     messageForWalletInput:
      //       "Please add your Bitcoin xpub key so we can pull in all your BTC transactions.",
      //   },
      //   // onlySuperUser: true,
      //   hatchfiProvider: null,
      //   modalName: "WalletAccountModal",
      // },
      {
        action: IntegrationAction.VezgoLink,
        provider: "bitcoin",
        hatchfiProvider: null,
        vezgoProvider: "bitcoin",

        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitcoin",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Crypto.com",
    provider: "crypto-com",
    logoUrl: _img("https://assets.awaken.tax/icons/crypto-com.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.FileUpload,
        provider: "crypto-com",
        hatchfiProvider: null,
        buttonText: "File",
        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "crypto-com",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Fantom",
    provider: "fantom",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/fantom.png",
      imageSize
    ),
    // isBeta: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.TriggerModal,
        provider: "fantom",

        // onlySuperUser: true,
        hatchfiProvider: null,
        modalName: "WalletAccountModal",
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "fantom",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
    isExtraordinary: true,
  },
  {
    name: "Binance",
    provider: "binance",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/binance.png",
      imageSize
    ),
    type: AccountTypeEnum.Exchange,
    description: (
      <WatchVideo demoUrl="https://www.loom.com/share/fce2a33d871c402ea68a5772dcdacc3c" />
    ),
    options: [
      {
        action: IntegrationAction.VezgoLink,
        provider: "binance",
        vezgoProvider: "binance",
        hatchfiProvider: null,
        modalName: null,
      },
      {
        action: IntegrationAction.FileUpload,
        provider: "binance",
        hatchfiProvider: null,
        buttonText: "File",
        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "binance",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Robinhood Crypto",
    provider: "robinhood",
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/robinhood.png",
      imageSize
    ),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "robinhood",
        hatchfiProvider: null,

        modalName: null,
        // modalProps: {
        //   description: (
        //     <Text marginBottom="2rem">
        //       Upload the CSV file you got from the{" "}
        //       <a
        //         style={{ color: colors.primary, fontWeight: "bold" }}
        //         target="_blank"
        //         href="https://robinhood.com/account/tax-center"
        //       >
        //         Robinhood Tax Center
        //       </a>{" "}
        //       here.
        //       <br /> <br /> It should be called "2022 – Robinhood Crypto 1099
        //       (CSV)".
        //       <br />
        //       <br />
        //       <i className="fa-sharp fa-info-circle" /> If you have any crypto
        //       deposits to Robinhood from outside sources, you may want to edit
        //       your basis by filtering for unlabeled Robinhood transactions.
        //     </Text>
        //   ),
        // },
      },
    ],
  },
  {
    name: "Cash App",
    provider: "cash_app",
    logoUrl: _img("https://assets.awaken.tax/icons/cashapp.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.FileUpload,
        provider: "cash_app",
        hatchfiProvider: null,
        buttonText: "File",
        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "cash_app",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },

  {
    name: "Terra Classic",
    provider: "terra-classic",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/terra-classic.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    awakenCsvModalDescription: getStakeTaxLink("Terra Classic", "LUNA1"),
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "terra-classic",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Terra 2",
    provider: "terra-2",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/terra2.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    awakenCsvModalDescription: getStakeTaxLink("Terra 2", "LUNA2"),
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "terra-2",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Near",
    provider: "near",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/near.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "near",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Scroll",
    provider: "scroll",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/scroll.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "scroll",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Thorchain",
    provider: "thorchain",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/thorchain.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "thorchain",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Celestia",
    provider: "celestia",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/celestia.png",
      imageSize
    ),
    awakenCsvModalDescription: getStakeTaxLink("Celestia", "TIA"),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "celestia",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Ronin",
    provider: "ronin",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/ronin.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "ronin",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Algorand",
    provider: "algorand",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/algorand.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    awakenCsvModalDescription: getStakeTaxLink("Algorand", "ALGO"),
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "algorand",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "OKX",
    provider: "okx",
    logoUrl: _img("https://assets.awaken.tax/icons/okx.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "okx",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BlockFi",
    provider: "blockfi",
    logoUrl: _img("https://assets.awaken.tax/icons/blockfi.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "blockfi",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Voyager",
    provider: "voyager",
    logoUrl: _img("https://assets.awaken.tax/icons/voyager.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "voyager",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Fuse",
    provider: "fuse",
    logoUrl: _img("https://assets.awaken.tax/icons/fuse.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "fuse",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Juno",
    provider: "juno",
    logoUrl: _img("https://assets.awaken.tax/icons/juno.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "juno",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Uphold",
    provider: "uphold",
    logoUrl: _img("https://assets.awaken.tax/icons/uphold.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "uphold",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "MetalPay",
    provider: "metalpay",
    logoUrl: _img("https://assets.awaken.tax/icons/metalpay.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "metalpay",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "PulseChain",
    provider: "pulsechain",
    logoUrl: _img("https://assets.awaken.tax/icons/pulsechain.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    // description: (
    //   <HStack marginTop="0.75rem !important" alignItems="center">
    //     {/* font awesome warning icon */}
    //     <i
    //       className="fa-sharp fa-triangle-exclamation"
    //       style={{
    //         color: colors.yellow50,
    //         fontSize: 12,
    //       }}
    //     />
    //     <Text fontSize="sm" marginLeft="3px !important">
    //       NFTs are not supported. Only supports wallets with less than a few
    //       thousand transactions.{" "}
    //       <Info message="The main block explorer, https://scan.pulsechain.com, does not support NFTs at this time, so Awaken will not be able to pull them in." />
    //     </Text>
    //   </HStack>
    // ),
    options: [
      // {
      //   action: IntegrationAction.TriggerModal,
      //   provider: "pulsechain",
      //   hatchfiProvider: null,
      //   modalName: "WalletAccountModal",
      // },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "pulsechain",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Tezos",
    provider: "tezos",
    logoUrl: _img("https://assets.awaken.tax/icons/tezos.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "tezos",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Cardano",
    provider: "cardano",
    logoUrl: _img("https://assets.awaken.tax/icons/ada.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "cardano",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Dogechain",
    provider: "dogechain",
    logoUrl: _img("https://assets.awaken.tax/icons/dogechain.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "dogechain",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Flow",
    provider: "flow",
    logoUrl: _img("https://assets.awaken.tax/icons/flow.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "flow",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Exodus",
    provider: "exodus",
    logoUrl: _img("https://assets.awaken.tax/icons/exodus.svg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "exodus",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Polkadot",
    provider: "polkadot",
    logoUrl: _img("https://assets.awaken.tax/icons/dot2.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "polkadot",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Pokt",
    provider: "pokt",
    logoUrl: _img("https://assets.awaken.tax/icons/pokt.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "pokt",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Gnosis",
    provider: "gnosis",
    logoUrl: _img("https://assets.awaken.tax/icons/gnosis.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      // {
      //   action: IntegrationAction.TriggerModal,
      //   provider: "gnosis",
      //   hatchfiProvider: null,
      //   modalName: "WalletAccountModal",
      // },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "gnosis",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BitForex",
    provider: "bitforex",
    logoUrl: _img("https://assets.awaken.tax/icons/bitforex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitforex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Crypto4Winners",
    provider: "crypto4winners",
    logoUrl: _img(
      "https://assets.awaken.tax/icons/crypto4winners.jpeg",
      imageSize
    ),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "crypto4winners",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "UpBit",
    provider: "upbit",
    logoUrl: _img("https://assets.awaken.tax/icons/upbit.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "upbit",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "CoinDCX",
    provider: "coindcx",
    logoUrl: _img("https://assets.awaken.tax/icons/coindcx.svg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coindcx",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "CoinEx",
    provider: "coinex",
    logoUrl: _img("https://assets.awaken.tax/icons/coinex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitcoin Swan",
    provider: "bitcoin_swan",
    logoUrl: _img(
      "https://assets.awaken.tax/icons/bitcoinswan.jpeg",
      imageSize
    ),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitcoin_swan",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Chia",
    provider: "chia",
    logoUrl: _img("https://assets.awaken.tax/icons/chia.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "chia",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Proof of Memes",
    provider: "pom",
    logoUrl: _img("https://assets.awaken.tax/icons/pom.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "pom",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Celsius",
    provider: "celsius",
    logoUrl: _img("https://assets.awaken.tax/icons/celsius.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "celsius",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "FTX",
    provider: "ftx",
    logoUrl: _img("https://assets.awaken.tax/icons/ftx.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "ftx",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "FTX US",
    provider: "ftx_us",
    logoUrl: _img("https://assets.awaken.tax/icons/ftx-us.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "ftx_us",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Helium",
    provider: "helium",
    logoUrl: _img("https://assets.awaken.tax/icons/helium.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "helium",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Metis",
    provider: "metis",
    logoUrl: _img("https://assets.awaken.tax/icons/metis.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "metis",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Coinmetro",
    provider: "coinmetro",
    logoUrl: _img("https://assets.awaken.tax/icons/coinmetro.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinmetro",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "LedgerX",
    provider: "ledgerx",
    logoUrl: _img("https://assets.awaken.tax/icons/ledgerx.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "ledgerx",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Newton Exchange",
    provider: "newton_exchange",
    logoUrl: "https://assets.awaken.tax/icons/newton.png",
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "newton_exchange",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "LocalBitcoins",
    provider: "localbitcoins",
    logoUrl: "https://assets.awaken.tax/icons/localbitcoins.png",
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "localbitcoins",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Swyftx",
    provider: "swyftx",
    logoUrl: "https://assets.awaken.tax/icons/swyftx.jpeg",
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "swyftx",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Cronos",
    provider: "cronos",
    logoUrl: "https://assets.awaken.tax/icons/cro.png",
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "cronos",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "CoinStats",
    provider: "coinstats",
    logoUrl: "https://assets.awaken.tax/icons/coinstats.png",
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinstats",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Huobi",
    provider: "huobi",
    logoUrl: _img("https://assets.awaken.tax/icons/huobi.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "huobi",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitfinex",
    provider: "bitfinex",
    logoUrl: _img("https://assets.awaken.tax/icons/bitfinex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitfinex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitmax",
    provider: "bitmax",
    logoUrl: _img("https://assets.awaken.tax/icons/bitmax.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitmax",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitstamp",
    provider: "bitstamp",
    logoUrl: _img("https://assets.awaken.tax/icons/bitstamp.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.FileUpload,
        provider: "bitstamp",
        hatchfiProvider: null,

        modalName: null,
      },
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitstamp",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "ByBit",
    provider: "bybit",
    logoUrl: _img("https://assets.awaken.tax/icons/bybit.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bybit",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Gate.io",
    provider: "gate-io",
    logoUrl: _img("https://assets.awaken.tax/icons/gate-io.webp", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "gate-io",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Poloniex",
    provider: "poloniex",
    logoUrl: _img("https://assets.awaken.tax/icons/poloniex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "poloniex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "MEXC",
    provider: "mexc",
    logoUrl: _img("https://assets.awaken.tax/icons/mexc.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "mexc",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Digifinex",
    provider: "digifinex",
    logoUrl: _img("https://assets.awaken.tax/icons/digifinex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "digifinex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Tron",
    provider: "tron",
    logoUrl: _img("https://assets.awaken.tax/icons/tron.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "tron",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Coincheck",
    provider: "coincheck",
    logoUrl: _img("https://assets.awaken.tax/icons/coincheck.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coincheck",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Whitebit",
    provider: "whitebit",
    logoUrl: _img("https://assets.awaken.tax/icons/whitebit.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "whitebit",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BKEX",
    provider: "bkex",
    logoUrl: _img("https://assets.awaken.tax/icons/bkex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bkex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitrue",
    provider: "bitrue",
    logoUrl: _img("https://assets.awaken.tax/icons/bitrue.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitrue",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BTCEX",
    provider: "btcex",
    logoUrl: _img("https://assets.awaken.tax/icons/btcex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "btcex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bithumb",
    provider: "bithumb",
    logoUrl: _img("https://assets.awaken.tax/icons/bithumb.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bithumb",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "CoinSpot",
    provider: "coinspot",
    logoUrl: _img("https://assets.awaken.tax/icons/coinspot.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinspot",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Nexo",
    provider: "nexo",
    logoUrl: _img("https://assets.awaken.tax/icons/nexo.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "nexo",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitclout",
    provider: "bitclout",
    logoUrl: _img("https://assets.awaken.tax/icons/bitclout.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitclout",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Stacks",
    provider: "stacks",
    logoUrl: _img("https://assets.awaken.tax/icons/stacks.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "stacks",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "zkSync Lite",
    provider: "zksync",
    awakenCsvModalDescription: getZKExportLink("zkSync Lite"),
    logoUrl: _img("https://assets.awaken.tax/icons/zksync.svg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "zksync",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "zkSync Era",
    provider: "zksync-era",
    awakenCsvModalDescription: getZKExportLink("zkSync Era"),
    logoUrl: _img("https://assets.awaken.tax/icons/zksync-era.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "zksync-era",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "StarkNet",
    provider: "stark_net",
    logoUrl: _img("https://assets.awaken.tax/icons/starknet.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "stark_net",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Wax",
    provider: "wax",
    logoUrl: _img("https://assets.awaken.tax/icons/wax.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "wax",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Cosmos",
    provider: "cosmos",
    logoUrl: _img("https://assets.awaken.tax/icons/cosmos.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    awakenCsvModalDescription: getStakeTaxLink("Cosmos", "COSMOS+"),
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "cosmos",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Juno Chain",
    provider: "juno_chain",
    logoUrl: _img("https://assets.awaken.tax/icons/juno-chain.png", imageSize),
    isLive: true,
    awakenCsvModalDescription: getStakeTaxLink("Juno", "Juno"),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "juno_chain",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Osmosis",
    provider: "osmosis",
    logoUrl: _img("https://assets.awaken.tax/icons/osmosis.png", imageSize),
    isLive: true,
    awakenCsvModalDescription: getStakeTaxLink("Osmosis", "OSMO"),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "osmosis",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Secret",
    provider: "secret",
    logoUrl: _img("https://assets.awaken.tax/icons/secret.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "secret",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bingx",
    provider: "bingx",
    logoUrl: _img("https://assets.awaken.tax/icons/bingx.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bingx",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Coinhako",
    provider: "coinhako",
    logoUrl: _img("https://assets.awaken.tax/icons/coinhako.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinhako",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Deribit",
    provider: "deribit",
    logoUrl: _img("https://assets.awaken.tax/icons/deribit.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "deribit",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Theta",
    provider: "theta",
    logoUrl: _img("https://assets.awaken.tax/icons/theta.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "theta",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "VeChain",
    provider: "vechain",
    logoUrl: _img("https://assets.awaken.tax/icons/vechain.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "vechain",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "xt.com",
    provider: "xt-com",
    logoUrl: _img("https://assets.awaken.tax/icons/xt.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "xt-com",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Canto",
    provider: "canto",
    logoUrl: _img("https://assets.awaken.tax/icons/canto.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "canto",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "ImmutableX",
    provider: "immutable_x",
    logoUrl: _img("https://assets.awaken.tax/icons/immutablex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "immutable_x",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "STEX",
    provider: "stex",
    logoUrl: _img("https://assets.awaken.tax/icons/stex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "stex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitso",
    provider: "bitso",
    logoUrl: _img("https://assets.awaken.tax/icons/bitso.webp", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitso",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitbank",
    provider: "bitbank",
    logoUrl: _img("https://assets.awaken.tax/icons/bitbank.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitbank",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bittrex",
    provider: "bittrex",
    logoUrl: _img("https://assets.awaken.tax/icons/bittrex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bittrex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Okcoin",
    provider: "okcoin",
    logoUrl: _img("https://assets.awaken.tax/icons/okcoin.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "okcoin",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BitMEX",
    provider: "bitmex",
    logoUrl: _img("https://assets.awaken.tax/icons/bitmex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitmex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BitFlyer",
    provider: "bitflyer",
    logoUrl: _img("https://assets.awaken.tax/icons/bitflyer.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitflyer",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "LBANK",
    provider: "lbank",
    logoUrl: _img("https://assets.awaken.tax/icons/lbank.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "lbank",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitget",
    provider: "bitget",
    logoUrl: _img("https://assets.awaken.tax/icons/bitget.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitget",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitmart",
    provider: "bitmart",
    logoUrl: _img("https://assets.awaken.tax/icons/bitmart.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitmart",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Coinone",
    provider: "coinone",
    logoUrl: _img("https://assets.awaken.tax/icons/coinone.webp", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "coinone",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Tapbit",
    provider: "tapbit",
    logoUrl: _img("https://assets.awaken.tax/icons/tapbit.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "tapbit",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Blockchain.com",
    provider: "blockchain-com",
    logoUrl: _img(
      "https://assets.awaken.tax/icons/blockchain-com.webp",
      imageSize
    ),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "blockchain-com",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bit.com",
    provider: "bit-com",
    logoUrl: _img("https://assets.awaken.tax/icons/bit-com.webp", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bit-com",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitpanda",
    provider: "bitpanda",
    logoUrl: _img("https://assets.awaken.tax/icons/bitpanda.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitpanda",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "CEX.io",
    provider: "cex-io",
    logoUrl: _img("https://assets.awaken.tax/icons/cex-io.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "cex-io",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Dcoin",
    provider: "dcoin",
    logoUrl: _img("https://assets.awaken.tax/icons/dcoin.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "dcoin",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitfront",
    provider: "bitfront",
    logoUrl: _img("https://assets.awaken.tax/icons/bitfront.jpeg", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitfront",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "BTSE",
    provider: "btse",
    logoUrl: _img("https://assets.awaken.tax/icons/btse.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "btse",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Citex",
    provider: "citex",
    logoUrl: _img("https://assets.awaken.tax/icons/citex.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "citex",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bione",
    provider: "bione",
    logoUrl: _img("https://assets.awaken.tax/icons/bione.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bione",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Changelly",
    provider: "changelly",
    logoUrl: _img("https://assets.awaken.tax/icons/changelly.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "changelly",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Bitay",
    provider: "bitay",
    logoUrl: _img("https://assets.awaken.tax/icons/bitay.png", imageSize),
    isLive: true,
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "bitay",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Harmony",
    provider: "harmony",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/harmony.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "harmony",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "Nifty Gateway",
    provider: "nifty_gateway",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/nifty.png",
      imageSize
    ),
    type: AccountTypeEnum.Exchange,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "nifty_gateway",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Moonriver",
    provider: "moonriver",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/moonriver.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "moonriver",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Energy Web",
    provider: "energy_web",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/energyweb.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "energy_web",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "Klaytn",
    provider: "klaytn",
    isLive: true,
    logoUrl: getImageKitCDNUrl(
      "https://assets.awaken.tax/icons/klaytn.png",
      imageSize
    ),
    type: AccountTypeEnum.Wallet,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "klaytn",
        hatchfiProvider: null,

        modalName: null,
      },
    ],
  },
  {
    name: "General Exchange",
    provider: "general_exchange",
    isLive: true,
    logoUrl: "https://assets.awaken.tax/icons/wallet-solid.svg",
    type: AccountTypeEnum.Exchange,
    description: <AnyCSVDescription word="exchange" />,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "general_exchange",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
  {
    name: "General Blockchain",
    provider: "general_blockchain",
    isLive: true,
    logoUrl: "https://assets.awaken.tax/icons/wallet-solid.svg",
    type: AccountTypeEnum.Wallet,
    description: <AnyCSVDescription word="blockchain" />,
    options: [
      {
        action: IntegrationAction.AwakenCsvFileUpload,
        provider: "general_blockchain",
        hatchfiProvider: null,
        modalName: null,
      },
    ],
  },
];

export const APPLICABLE_INTEGRATIONS = INTEGRATIONS.filter(
  (integration) => integration.isLive && integration.options.length > 0
);

export const GENERAL_EXCHANGE_INTEGRATION = INTEGRATIONS.find(
  (integration) => integration.provider === "general_exchange"
);

export const GENERAL_BLOCKCHAIN_INTEGRATION = INTEGRATIONS.find(
  (integration) => integration.provider === "general_blockchain"
);

export const PROVIDER_TO_LOGO_URL = Object.fromEntries(
  INTEGRATIONS.map((i) => [i.provider, i.logoUrl])
);
