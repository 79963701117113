import { Box, Button, Container, Text } from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import { MutationRemoveRuleArgs, Rule, Scalars } from "src/api/generated/types";
import { api } from "src/api";

function _OverrideLabelModal({
  handleHide,
  show: isVisible,
  rule,
  numTxnsRuleUsed,
  updateTransaction,
  updateTransactionOverrideLabel,
  isLoadingUpdateTransaction,
}: InjectedProps & {
  rule: Rule;
  numTxnsRuleUsed: number;
  isLoadingUpdateTransaction: boolean;
  updateTransaction: () => void;
  updateTransactionOverrideLabel: () => void;
}) {
  const [removeRule, { loading: isLoadingRemoveRule }] = useMutation<
    {
      removeRule: Scalars["String"];
    },
    MutationRemoveRuleArgs
  >(api.rules.removeRule);

  const overrideLabel = async () => {
    if (isLoadingUpdateTransaction) return;
    updateTransactionOverrideLabel();
    handleHide();
  };

  const deletePattern = async () => {
    if (isLoadingRemoveRule || isLoadingUpdateTransaction) return;
    await removeRule({
      variables: {
        ruleId: rule.id,
      },
    });
    updateTransaction();
    handleHide();
  };

  const cancel = () => {
    if (isLoadingUpdateTransaction) return;
    handleHide();
  };

  return (
    <Modal
      title="Override Label"
      isVisible={isVisible}
      handleHide={handleHide}
      w="30rem"
      Footer={
        <Box w="100%">
          <Button
            width="100%"
            variant="primary"
            marginBottom="1rem"
            onClick={overrideLabel}
          >
            Override Label
          </Button>
          <Button
            width="100%"
            variant="red"
            marginBottom="1rem"
            onClick={deletePattern}
            isLoading={isLoadingRemoveRule}
          >
            Delete Pattern
          </Button>
          <Button width="100%" variant="gray" onClick={cancel}>
            Cancel
          </Button>
        </Box>
      }
    >
      <Container margin="0" padding="0">
        <Text>
          This transaction follows a pattern that applies to {numTxnsRuleUsed}{" "}
          other transactions. You can override the label of just this one
          transaction, or you can delete the pattern entirely to change all{" "}
          {numTxnsRuleUsed}.
        </Text>
      </Container>
    </Modal>
  );
}

export const OverrideLabelModal = connectModal({
  name: "OverrideLabelModal",
})(_OverrideLabelModal);
