import {
  Box,
  Heading,
  Text,
  HStack,
  VStack,
  Link,
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
} from "@chakra-ui/react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps, show } from "redux-modal";
import { colors, other } from "src/theme";
import { Button, Info } from "src/components/styled";
import { abrNumber, D } from "src/utils/helpers";
import { motion } from "framer-motion";
import { PriceThreshold, THRESHOLDS } from "./constants";
import { compose, isNil } from "lodash/fp";
import numeral from "numeral";
import { Warning } from "src/components/Warning";
import { useDispatch } from "react-redux";
import { useActiveSubscription } from "src/hooks/useActiveSubscription";
import { useNavigate, useParams } from "react-router-dom";
import { useClientById } from "src/hooks";
import { CURRENT_TAX_YEAR } from "src/config";
import { Maybe } from "src/core";
import { useApolloClient, useMutation } from "@apollo/client";
import { api } from "src/api";
import numbro from "numbro";
import { sum } from "lodash";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import SecondaryText from "src/components/styled/SecondaryText";
import { useTheme } from "src/hooks/useTheme";
import { useEffect, useState } from "react";
import { Mutation } from "src/api/generated/types";

type Props = InjectedProps & {
  isLandingPage: boolean;
};

function _CheckoutModal({
  handleHide,
  show: isVisible,
  isLandingPage = false,
}: Props) {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const _showModal = compose(dispatch, show);
  const [showDiscountCalculation, setShowDiscountCalculation] = useState(false);

  const [
    checkMagicEdenDiscount,
    { data: dataCheckMagicEden, loading: loadingCheckME, error: errorCheckME },
  ] = useMutation<Pick<Mutation, "checkMagicEdenDiscount">>(
    api.users.clients.discounts.magicEden.check
  );

  const {
    totalUsed,
    hasSubscription,
    availableDiscounts,
    subscription,
    discountCalculation,
  } = useActiveSubscription(clientId || "", "no-cache");
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const availableCredit = client?.availableCreditCents;
  const discountCents = sum(availableDiscounts.map((d) => d.amountCents));

  const hasCredit = !!availableCredit && availableCredit > 0;
  const apolloClient = useApolloClient();
  const navigate = useNavigate();

  const _startCheckout = async (threshold: PriceThreshold) => {
    if (isLandingPage) {
      navigate(`/signup${window.location.search}`);
      return;
    }

    // if they have a paid subscription -> make them message us
    if (hasSubscription && !subscription?.isFreeSubscription) {
      alert(
        "Sorry, you already have a subscription! Message support if you need to upgrade."
      );
      // refetch so the sidebar updates just in case it isn't already. we saw people somehow double paying
      apolloClient.refetchQueries({ include: [api.subscriptions.active] });
      return handleHide();
    }

    // otherwise go to payment
    _showModal("PaymentModal", {
      maxTxnCeiling: threshold.max || 0,
      onPaymentSuccess: () => handleHide(),
    });
  };

  useEffect(() => {
    checkMagicEdenDiscount({
      variables: {
        clientId,
      },
    });
  }, [clientId]);

  const isEligibleForMagicEden =
    dataCheckMagicEden?.checkMagicEdenDiscount?.isEligible &&
    !dataCheckMagicEden?.checkMagicEdenDiscount?.hasClaimed;

  const isLarge = useIsLargeScreen();
  const theme = useTheme();

  return (
    <Modal isVisible={isVisible} handleHide={handleHide} w="100%" maxW="40rem">
      <div>
        <Box
          w="100%"
          height="100%"
          display={"flex"}
          justifyContent="center"
          alignItems="flex-start"
          flexDir="column"
          padding="1rem 0"
        >
          <Heading
            variant="md"
            textAlign="left"
            padding="0"
            margin="0"
            marginBottom="1rem !important"
            color={theme.header}
          >
            Get your {CURRENT_TAX_YEAR} taxes done
          </Heading>

          {/* <Text style={{ margin: "0rem 0 1rem 0" }} fontSize="md">
            Awaken does not charge you for transations that are spam or likely
            to be spam.
            <Info
              message="Our
            pricing algorithm automatically excludes any transactions that might
            be spam, so you don't need to waste your time labeling them as spam
            on our software."
            />
          </Text> */}

          {totalUsed !== null && totalUsed > 0 && (
            <Text
              color={theme.text}
              style={{ margin: "0rem 0 1rem 0" }}
              fontSize="md"
            >
              You have{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: theme.header,
                  textDecoration: "underline",
                }}
              >
                {totalUsed}
              </span>{" "}
              transactions for {CURRENT_TAX_YEAR}. Awaken never charges you for
              spam.
            </Text>
          )}

          <div
            style={{
              background: theme.secondaryBackground,
              borderRadius: 10,
              width: "100%",
            }}
          >
            <div
              onClick={() =>
                setShowDiscountCalculation(!showDiscountCalculation)
              }
              style={{
                color: theme.text,
                cursor: "pointer",
                fontSize: 16,
                padding: "0.75rem 1rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <i
                className="fa-sharp fa-calculator"
                style={{ marginRight: 10 }}
              />

              <Text
                flex={1}
                fontSize="md"
                fontWeight="medium"
                color={theme.text}
              >
                See how we calculated this
              </Text>

              <i className="fa-sharp fa-chevron-down" />
            </div>
            {showDiscountCalculation && discountCalculation && (
              <Box
                display="flex"
                padding="0 1rem 1rem 1rem"
                alignItems="flex-start"
                justifyContent={"flex-start"}
              >
                <Box>
                  <SecondaryText text="Total: " fontWeight={"bold"} />
                  <SecondaryText text="Discount: " fontWeight={"bold"} />

                  {discountCalculation.magicEdenDiscount > 0 && (
                    <SecondaryText paddingLeft="1rem" text="Magic Eden: " />
                  )}

                  {discountCalculation.coinbaseWalletDiscount > 0 && (
                    <SecondaryText
                      paddingLeft="1rem"
                      text="Coinbase wallet: "
                    />
                  )}
                  {discountCalculation.spamDiscount > 0 && (
                    <SecondaryText paddingLeft="1rem" text="Spam: " />
                  )}
                  {discountCalculation.likelySpamDiscount > 0 && (
                    <SecondaryText paddingLeft="1rem" text="Likely spam: " />
                  )}

                  <SecondaryText
                    text="FINAL: "
                    fontWeight={"bold"}
                    fontSize="md"
                  />
                </Box>
                <Box w="1rem" />
                <Box>
                  <SecondaryText
                    text={`+ ${discountCalculation.totalNumTxns} transactions`}
                    fontWeight={"bold"}
                  />
                  <SecondaryText
                    text={`- ${discountCalculation.totalDiscount} transactions`}
                    fontWeight={"bold"}
                  />
                  {discountCalculation.magicEdenDiscount > 0 && (
                    <SecondaryText
                      text={`- ${discountCalculation.magicEdenDiscount} transactions`}
                    />
                  )}
                  {discountCalculation.coinbaseWalletDiscount > 0 && (
                    <SecondaryText
                      text={`- ${discountCalculation.coinbaseWalletDiscount} transactions`}
                    />
                  )}
                  {discountCalculation.spamDiscount > 0 && (
                    <SecondaryText
                      text={`- ${discountCalculation.spamDiscount} transactions`}
                    />
                  )}
                  {discountCalculation.likelySpamDiscount > 0 && (
                    <SecondaryText
                      text={`- ${discountCalculation.likelySpamDiscount} transactions`}
                    />
                  )}

                  <SecondaryText
                    text={`= ${discountCalculation.numPayableTxns} transactions`}
                    fontWeight={"bold"}
                    fontSize="md"
                  />
                </Box>
                {/* <TableContainer>
                <Table>
                  <Thead>
                    <Th>
                      <Text fontSize="sm" fontWeight="bold">
                        Discount
                      </Text>
                    </Th>
                    <Th>
                      <Text fontSize="sm" fontWeight="bold">
                        Num Transactions
                      </Text>
                    </Th>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Text fontSize="sm">Spam</Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">
                          {discountCalculation.spamDiscount}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text fontSize="sm">Likely Spam</Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">
                          {discountCalculation.likelySpamDiscount}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text fontSize="sm">Coinbase Wallet</Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">
                          {discountCalculation.coinbaseWalletDiscount}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text fontSize="sm">Total</Text>
                      </Td>
                      <Td>
                        <Text fontSize="sm">
                          {discountCalculation.totalDiscount}
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer> */}

                {/* <Text style={{ margin: "0rem 0 1rem 0" }} fontSize="md">
              Spam: {discountCalculation.spamDiscount} transactions
              <br />
              Likely Spam: {discountCalculation.likelySpamDiscount} transactions
              <br />
              {discountCalculation.coinbaseWalletDiscount
                ? `Coinbase Wallet: ${discountCalculation.coinbaseWalletDiscount}\n`
                : null}
              <br />
              TOTAL: {discountCalculation.totalDiscount} transactions
              <br />
              TOTAL BEFORE DISCOUNT: {discountCalculation.totalNumTxns}{" "}
              transactions
              <br />
              FINAL: {discountCalculation.numPayableTxns} transactions
            </Text> */}
              </Box>
            )}
          </div>

          {isEligibleForMagicEden && (
            <div
              style={{
                padding: 15,
                backgroundColor: theme.secondaryBackground,
                borderRadius: 10,
                marginBlock: 25,
              }}
            >
              <Text color={theme.text} style={{ margin: "0 0 0.5rem 0" }}>
                You are eligible to get a discount because you have used Magic
                Eden!
              </Text>

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(show("MagicEdenDiscountModal"));
                }}
                style={{
                  fontSize: 16,
                  marginTop: 15,
                  width: "100%",
                  backgroundColor: "#e32575",
                  color: "#FFF",
                  borderRadius: 100,
                }}
              >
                Claim Magic Eden Discount{" "}
                <i
                  style={{ marginLeft: 5 }}
                  className={"fa-sharp fa-party-horn"}
                />
              </Button>
            </div>
          )}

          {availableDiscounts.length > 0 && (
            <div>
              <Text
                color={theme.text}
                style={{ margin: "0 0 0.5rem 0" }}
                fontSize="sm"
              >
                Your Discounts:
              </Text>
              <div
                style={{
                  // display the inline
                  marginBottom: "1.5rem",
                }}
              >
                {availableDiscounts.map((d) => (
                  <div
                    style={{
                      display: "inline-block",
                      marginRight: 5,
                      marginBottom: 5,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: theme.secondaryBackground,
                        borderRadius: 5,
                        padding: "7px 15px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: theme.text,
                        fontWeight: "500",
                        border: `1px solid ${theme.border}`,
                      }}
                    >
                      <i
                        className="fa-sharp fa-badge-dollar"
                        style={{ marginRight: 5, color: colors.gray20 }}
                      />
                      {d.title}:{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: colors.green50,
                          marginLeft: 5,
                        }}
                      >
                        {d.amount} off
                      </span>{" "}
                      {d.infoMessage && (
                        <Info
                          style={{
                            color: theme.text,
                            marginLeft: 5,
                          }}
                          message={d.infoMessage}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <Box
            maxW="60rem"
            display="flex"
            marginTop="1.5rem"
            w="100%"
            flexDir={"column"}
            justifyContent="space-around"
            bg={theme.background}
          >
            {/* <ReferralPricingOption /> */}

            {/* <Divider style={{ margin: "2rem 0" }} /> */}

            {THRESHOLDS.map((threshold, i) => (
              <PricingCard
                key={i}
                threshold={threshold}
                isLandingPage={isLandingPage ?? false}
                gradient={threshold.gradient}
                totalTxns={totalUsed}
                onClick={() => _startCheckout(threshold)}
                discountCents={discountCents ?? 0}
              />
            ))}

            {/* <Warning
              iconName="fa-sharp fa-badge-dollar"
              bg={colors.lightBlue100}
              border={`1px solid ${colors.lightBlue50}`}
              style={{ margin: "1rem 0 0 0" }}
              iconStyle={{ color: colors.primary, fontSize: 25 }}
              message={
                <Text fontSize="sm" color={colors.black}>
                  Have you purchased inaccurate tax reports from
                  CoinTracker/Koinly? Email your receipt to{" "}
                  <Link
                    _hover={{
                      textDecoration: "underline",
                    }}
                    href="mailto:team@awaken.tax"
                    style={{
                      fontWeight: "bold",
                      color: colors.primary,
                    }}
                  >
                    team@awaken.tax
                  </Link>{" "}
                  and we'll give you free credit (up to $100){" "}
                  <Info message="For example, if you spent $200 on CoinTracker and are not happy with it, we will give you $100 of free credits on Awaken." />
                </Text>
              }
            /> */}

            {/* <Text
            textAlign="left"
            fontStyle="italic"
            fontSize="sm"
            style={{ margin: "0", marginTop: "0.25rem" }}
          >
            By purchasing a tax report for this year, we also give you reports
            for all past years for free.
          </Text> */}
          </Box>

          <div
            style={{
              padding: 15,
              background:
                theme.theme === "dark" ? colors.green05 : colors.green100,
              border: `1px solid ${colors.green50}`,
              borderRadius: 10,
              marginBlock: 25,
            }}
          >
            <Text color={theme.header} style={{ margin: "0 0 0.5rem 0" }}>
              Get <b>up to $75 off</b> if you paid for Koinly /
              CryptoTaxCalculator / Cointracker etc... and are a first-time
              Awaken customer.
              <br />
              <br />
              <Button
                style={{
                  background: colors.positive,
                  color: colors.white,
                }}
                onClick={() => dispatch(show("CompetitorCreditModal"))}
              >
                Apply for Credit Match{" "}
                <i
                  style={{ marginLeft: 10 }}
                  className="fa-sharp fa-long-arrow-right"
                />
              </Button>
            </Text>
          </div>
        </Box>
      </div>
    </Modal>
  );
}

type PricingCardProps = {
  threshold?: PriceThreshold;
  gradient: string;
  onClick: () => void;
  totalTxns: Maybe<number>;
  discountCents: number;
  isLandingPage: boolean;
  hideButton?: boolean;
};

export function PricingCard({
  gradient,
  threshold,
  onClick,
  totalTxns,
  discountCents,
  isLandingPage,
  hideButton = false,
}: PricingCardProps) {
  const costMin = !isNil(threshold?.min)
    ? (threshold?.price || 0) / threshold!.min!
    : null;
  const theme = useTheme();

  const costMax = !isNil(threshold?.max)
    ? (threshold?.price || 0) / threshold!.max!
    : null;

  const showContactUs =
    threshold?.price === undefined || threshold?.price === null;
  const isExceeded =
    !isNil(threshold?.max) &&
    totalTxns !== null &&
    totalTxns > (threshold?.max || 0);

  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      marginBottom="0.5rem"
      borderRadius={other.borderRadius}
      borderColor={theme.border}
      border={`1px solid ${theme.border}`}
      padding="0.75rem"
      w="100%"
    >
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        style={{
          height: 60,
          width: 60,
          borderRadius: 7,
          background: gradient,
          opacity: 0.75,
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        {/* {threshold?.max === 1_000_000 && (
          <Text color={colors.white} fontWeight="bold" fontSize="md">
            ∞
          </Text>
        )} */}

        {threshold?.emoji && (
          <Text
            // color={colors.white}
            fontWeight="bold"
            fontSize="x-large"
            color="transparent"
            textShadow="0 0 0 white"
          >
            {threshold?.emoji}
          </Text>
        )}

        {/* {threshold?.max === 1000
            ? "1,000"
            : threshold?.max
            ? threshold?.max === 1_000_000
              ? "∞"
              : abrNumber(threshold.max)
            : numbro(threshold?.min).format("0,000") + "+"} */}

        {/* {threshold && (
          <HStack>
            {Array.from({ length: threshold.numIcons }).map(() => (
              <i
                style={{
                  fontSize: 14,
                  color: colors.white,
                  margin: 0,
                  padding: "0 1px",
                }}
                className={threshold.iconName}
              />
            ))}
          </HStack>
        )} */}
      </motion.div>
      <VStack flex={1} alignItems="flex-start" marginLeft="0 !important">
        <Text
          color={theme.header}
          fontSize="sm"
          fontWeight="500"
          textAlign={"left"}
          margin="0"
        >
          {threshold?.max === 1_000_000
            ? `UNLIMITED transactions`
            : threshold?.max
            ? `Up to ${numbro(threshold.max).format("0,000")} transactions`
            : `UNLIMITED transactions`}{" "}
        </Text>

        <Text
          textAlign={"left"}
          fontSize="sm"
          padding="0"
          margin="0"
          paddingRight="10px"
          color={theme.text}
          // style={{
          //   marginTop: "0.2rem",
          //   paddingTop: 0,
          // }}
        >
          {showContactUs ? (
            <>
              <a
                onClick={() => {
                  window.open("mailto:team@awaken.tax");
                  // if ((window as any).Intercom) {
                  //   (window as any).Intercom("showNewMessage");
                  // }
                }}
                style={{
                  color: colors.primary,
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Contact us
              </a>{" "}
              for pricing. We also offer special Whale and Mega-Whale packages
              🐳!
            </>
          ) : (
            <div
              style={{
                marginTop: 0,
                paddingTop: 0,
              }}
            >
              {!isNil(threshold?.price) && (
                <Text
                  fontSize="md"
                  color={theme.text}
                  style={{ display: "inline-block" }}
                  // marginTop={0}
                  // paddingTop={0}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: discountCents > 0 ? theme.header : colors.green50,
                      textDecoration:
                        discountCents > 0 ? "line-through" : undefined,
                    }}
                  >
                    {D((threshold?.price || 0) * 100, "USD").toFormat()}
                  </span>
                  {discountCents > 0 && (
                    <Discount
                      threshold={threshold}
                      discountCents={discountCents}
                    />
                  )}
                </Text>
              )}
            </div>
          )}
        </Text>
        {/* <SecondaryText
          text="We do not charge for spam transactions"
          fontSize="0.75rem"
          fontWeight="semibold"
          style={{
            marginTop: "0.2rem",
            paddingTop: 0,
          }}
        /> */}
      </VStack>

      {!hideButton && (
        <div style={{ margin: "auto" }}>
          {showContactUs ? (
            <Button
              fontSize="sm"
              // style={{
              //   position: "relative",
              //   top: -15,
              // }}
              onClick={() => {
                window.open("mailto:team@awaken.tax");
                // if ((window as any).Intercom) {
                //   (window as any).Intercom("showNewMessage");
                // }
              }}
            >
              Contact Us
            </Button>
          ) : (
            <Button
              fontSize="sm"
              // style={{
              //   position: "relative",
              //   top: -15,
              // }}
              onClick={onClick}
              disabled={isExceeded}
              bg={theme.secondaryBackground}
              _hover={{ bg: theme.ternaryBackground }}
              color={theme.header}
            >
              {isLandingPage
                ? "Get Started"
                : isExceeded
                ? "Exceeded"
                : "Purchase"}{" "}
              <i
                style={{
                  marginLeft: "0.5rem",
                }}
                className="fa-sharp fa-arrow-right"
              />
            </Button>
          )}
        </div>
      )}
    </HStack>
  );
}

const Discount = ({
  threshold,
  discountCents,
}: {
  threshold: PriceThreshold;
  discountCents: number;
}) => {
  const remainingPriceCents = Math.max(
    0,
    100 * (threshold.price ?? 0) - discountCents
  );

  if (remainingPriceCents === 0) {
    return (
      <span
        style={{
          fontWeight: "bold",
          color: colors.green50,
          marginLeft: "0.5rem",
        }}
      >
        $0.00 (100% free)
      </span>
    );
  }

  return (
    <span
      style={{
        fontWeight: "bold",
        color: colors.green50,
        marginLeft: "0.5rem",
      }}
    >
      {D(Math.max(0, remainingPriceCents), "USD").toFormat()}
    </span>
  );
};
// type UsePacksInput = ReturnType<typeof usePacksInput>;

// const usePacksInput = () => {
//   const mapping = useMemo(() => objectify(THRESHOLDS, (t) => t.priceId), []);

//   // price ID to quantity selected
//   const [selectedPrices, setSelectedPrices] = useState<Record<string, number>>(
//     {}
//   );

//   const increment = (priceId: string) => {
//     const newPrices = { ...selectedPrices };
//     const current = selectedPrices[priceId] || 0;
//     newPrices[priceId] = current + 1;
//     setSelectedPrices(newPrices);
//   };

//   const decrement = (priceId: string) => {
//     const newPrices = { ...selectedPrices };
//     const current = selectedPrices[priceId] || 0;
//     newPrices[priceId] = Math.max(current - 1, 0);
//     setSelectedPrices(newPrices);
//   };

//   const change = (priceId: string, newValue: number) => {
//     const newPrices = { ...selectedPrices };
//     newPrices[priceId] = newValue;
//     setSelectedPrices(newPrices);
//   };

//   const cart = useMemo(
//     () =>
//       Object.entries(selectedPrices).map(([key, val]) => ({
//         priceId: key,
//         price: mapping[key],
//         quantity: val,
//         totalPrice: mapping[key].price * val,
//         totalTxns: mapping[key].numTxns * val,
//       })),
//     [selectedPrices, mapping]
//   );

//   const numberOfTransactions = useMemo(
//     () => sum(cart, (c) => c.totalTxns),
//     [cart]
//   );
//   const totalPrice = useMemo(() => sum(cart, (c) => c.totalPrice), [cart]);

//   return {
//     increment,
//     decrement,
//     change,
//     selectedPrices,
//     cart,
//     numberOfTransactions,
//     totalPrice,
//   };
// };

export const CheckoutModal = connectModal({
  name: "CheckoutModal",
})(_CheckoutModal);
