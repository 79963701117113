import {
  HStack,
  Box,
  Tooltip,
  Divider,
  Spinner,
  Image,
  Text,
  VStack,
  Flex,
  Grid,
  GridItem,
  Switch,
} from "@chakra-ui/react";
import { BaseAssetFields, BaseUserFields } from "src/api/fragments";
import WhiteBox from "src/components/styled/WhiteBox";
import {
  Copy,
  Input,
  Select,
  Option,
  Info,
  Button,
  ActionSheet,
} from "src/components/styled";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioResponse,
  PortfolioSortColumnEnum,
} from "src/api/generated/types";
import _, { debounce, isEmpty, keyBy, truncate } from "lodash";
import { colors, other } from "src/theme";
import { AssetRow } from "./AssetRow";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { PortfolioContext } from "../context";
import NFTRow, { GRID_ITEM_HEIGHT } from "./NFTRow";
import { ApolloError, useMutation } from "@apollo/client";
import { api } from "src/api";
import { useTheme } from "src/hooks/useTheme";

function NFTs() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId);
  const [useFloor, setUseFloor] = useState(false);
  const [updateClient] = useMutation(api.clients.update);
  const toast = useMyToast();
  const { background, text, medBackground, secondaryBackground, header } =
    useTheme();

  useEffect(() => {
    if (!client) return;
    const defaultToFloor = client?.defaultToFloor;
    setUseFloor(defaultToFloor ?? false);
  }, [client]);

  const _useFloorForClient = async (v: boolean) => {
    setUseFloor(v);

    console.log(v);

    try {
      const newClient = await updateClient({
        variables: {
          clientId: client?.id,
          defaultToFloor: v,
        },
        refetchQueries: [
          api.clients.retrieve,
          api.users.clients.getActive,
          api.portfolio.getPortfolioV2NFTs,
        ],
      });

      console.log("updated client");
      console.log(newClient);
      toast.show({
        status: "success",
        message: v ? "Using floor as default" : "Unsetting floor as default",
      });
    } catch (err) {
      console.log(err);
      setUseFloor((p) => !p);
      if (err instanceof ApolloError) {
        alert("Error: " + err.message);
      }
    } finally {
      console.log("finally");
    }
  };

  const { isLoadingNFTs, nfts } = useContext(PortfolioContext);

  if (!isLoadingNFTs && !nfts.length) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 0,
        paddingBottom: 100,
        // marginTop: "1.5rem", FIXME: add back when ready
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignSelf="flex-start"
        alignContent="flex-start"
        w="100%"
        // marginTop="2rem" FIXME: add back when ready
      >
        <WhiteBox
          padding="0"
          w="100%"
          marginTop="1rem"
          style={{
            boxShadow: "none",
            //minHeight: LIMIT * 70
          }} // just so less jolty when navigating
          border="none"
          bg={background}
        >
          {isLoadingNFTs ? (
            <div
              style={{
                padding: "10rem 3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner color={header} />
            </div>
          ) : (
            <>
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  marginBottom: 25,
                }}
              >
                <Text
                  style={{
                    fontWeight: "medium",
                    fontSize: 14,
                    color: header,
                    marginRight: 5,
                  }}
                >
                  Use floor as the price
                </Text>
                <Switch
                  isChecked={useFloor}
                  onChange={(e) => _useFloorForClient(e.target.checked)}
                />
              </div>

              {/*  make this a grid of 4 columns  */}
              <Grid
                templateColumns="repeat(auto-fit, minmax(240px, 1fr))"
                gap={4}
              >
                {nfts.map((nft) => (
                  <GridItem colSpan={1}>
                    <NFTRow
                      itemHeight={GRID_ITEM_HEIGHT + 50}
                      mode="grid"
                      onPress={() => {
                        alert(
                          "NFT details coming soon. You'll be able to edit the price, see details, and a lot more."
                        );
                      }}
                      key={nft.assetKey}
                      nft={nft}
                    />
                  </GridItem>
                ))}
              </Grid>
            </>
          )}
        </WhiteBox>
      </Box>
      {/* {isSuperUser && (
          <Button
            bg={colors.gray2}
            marginRight="0.5rem"
            _hover={{ bg: colors.gray80 }}
            padding="0.5rem 1rem"
            borderRadius={other.borderRadius}
            onClick={_hardRefreshAssetImages}
          >
            <i
              style={{ marginRight: 5, color: colors.green50 }}
              className="fa-sharp fa-refresh"
            />{" "}
            <Text
              color={colors.gray20}
              fontSize="sm"
              padding="0"
              fontWeight="semibold"
            >
              Refresh Asset Metadata{" "}
              <Info message="Updates images and names of assets if they have changed." />
            </Text>
          </Button>
        )} */}
    </div>
  );
}

export default NFTs;
