import { Button, Container, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";

type Props = InjectedProps & {
  startingFeedback?: string;
};

function _FeedbackModal({
  handleHide,
  show: isVisible,
  startingFeedback,
}: Props) {
  const [feedback, setFeedback] = useState(startingFeedback || "");
  const [submitFeedback, { loading }] = useMutation(api.contact.feedback);
  const toast = useMyToast();
  const theme = useTheme();

  const _onSubmit = async function () {
    if (!feedback)
      return toast.show({
        message: "Please fill out all the form fields.",
        status: "error",
      });

    try {
      await submitFeedback({
        variables: { feedback },
      });

      toast.show({
        message: "Thank you for your feedback",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }

    handleHide();
  };

  return (
    <Modal
      title="Leave feedback"
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          variant="primary"
          isLoading={loading}
          onClick={_onSubmit}
        >
          Submit
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <Textarea
          value={feedback}
          noOfLines={12}
          height={300}
          style={{
            color: theme.header,
          }}
          onChange={(e) => setFeedback(e.target.value)}
          // label="Client Full Name"
          placeholder="Type here..."
        />
      </Container>
    </Modal>
  );
}

export const FeedbackModal = connectModal({
  name: "FeedbackModal",
})(_FeedbackModal);
