import {
  HStack,
  Box,
  Tooltip,
  Divider,
  Spinner,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { BaseAssetFields, BaseUserFields } from "src/api/fragments";
import WhiteBox from "src/components/styled/WhiteBox";
import {
  Copy,
  Input,
  Select,
  Option,
  Info,
  Button,
  ActionSheet,
} from "src/components/styled";
import { useClientById, useMe, useMyToast } from "src/hooks";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioResponse,
  PortfolioSortColumnEnum,
} from "src/api/generated/types";
import _, { debounce, isEmpty, keyBy, truncate } from "lodash";
import { colors, other } from "src/theme";
import { AssetRow } from "./AssetRow";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { PortfolioContext } from "../context";
import DefiPositionRow from "./DefiPositionRow";
import { useTheme } from "src/hooks/useTheme";

function DefiPositions() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId);

  const location = useLocation();
  const toast = useMyToast();
  const { isLoadingCoins, defiPositions } = useContext(PortfolioContext);
  const { background, header } = useTheme();

  if (!isLoadingCoins && !defiPositions.length) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        flexGrow: 0,
        paddingBottom: 100,
        // marginTop: "1.5rem", FIXME: add back when ready
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        alignSelf="flex-start"
        alignContent="flex-start"
        w="100%"
        style={{ marginTop: 20 }}
        // marginTop="2rem" FIXME: add back when ready
      >
        <WhiteBox
          padding="0"
          w="100%"
          marginTop="1rem"
          style={{
            boxShadow: "none",
            //minHeight: LIMIT * 70
          }} // just so less jolty when navigating
          border="none"
          bg={background}
        >
          {isLoadingCoins ? (
            <div
              style={{
                padding: "10rem 3rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner color={header} />
            </div>
          ) : (
            <>
              {defiPositions.map((position) => (
                <DefiPositionRow
                  position={position}
                  key={position.groupByKey}
                  client={client}
                />
              ))}
            </>
          )}
        </WhiteBox>
      </Box>
      {/* {isSuperUser && (
          <Button
            bg={colors.gray2}
            marginRight="0.5rem"
            _hover={{ bg: colors.gray80 }}
            padding="0.5rem 1rem"
            borderRadius={other.borderRadius}
            onClick={_hardRefreshAssetImages}
          >
            <i
              style={{ marginRight: 5, color: colors.green50 }}
              className="fa-sharp fa-refresh"
            />{" "}
            <Text
              color={colors.gray20}
              fontSize="sm"
              padding="0"
              fontWeight="semibold"
            >
              Refresh Asset Metadata{" "}
              <Info message="Updates images and names of assets if they have changed." />
            </Text>
          </Button>
        )} */}
    </div>
  );
}

export default DefiPositions;
