import { Box, Button, Container, HStack, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import {
  AssetTypeEnum,
  Mutation,
  MutationCreateAssetArgs,
  MutationCreateAssetV2Args,
  MutationUpdateAssetArgs,
} from "src/api/generated/types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import { Input } from "src/components/styled/Form/Input";
import { Maybe } from "src/core";
import { useMyToast } from "src/hooks";
import { colors } from "src/theme";
import { BaseAssetFields } from "src/api/fragments";
import { debounce } from "lodash/fp";
import { useParams } from "react-router-dom";
import { Option, Select } from "src/components/styled";
import { INTEGRATIONS } from "../AccountModal/constants";
import { IntegrationProviderInfo } from "../AccountModal/types";
import { OptionProps } from "react-select";
import Helpers from "src/utils/helpers";

type Props = InjectedProps & {
  provider: string;
  asset: Maybe<BaseAssetFields>;
  onCreate?: (x: any) => any;
  onUpdate?: (x: any) => any;
  mode: "creating" | "editing";
};

const ASSET_OPTIONS = [
  { label: "Coins", value: AssetTypeEnum.FungibleToken },
  { label: "NFT", value: AssetTypeEnum.Nft },
];

function _AssetModal({
  handleHide,
  provider,
  show: isVisible,
  asset,
  mode,
  onCreate,
  onUpdate,
}: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const [name, setName] = useState(asset?.name || "");
  const [contractAddress, setContractAddress] = useState(
    asset?.contractAddress || ""
  );

  console.log(asset);

  const [coinGeckoTokenId, setCoingeckoId] = useState(
    asset?.coinGeckoTokenId || ""
  );

  const [type, setType] = useState<AssetTypeEnum>(
    asset?.type || AssetTypeEnum.FungibleToken
  );

  // GQL
  const [createAsset, { loading: loadingCreate }] = useMutation<
    Pick<Mutation, "createAssetV2">,
    MutationCreateAssetV2Args
  >(api.assets.createV2);

  const [updateAsset, { loading: loadingUpdate }] = useMutation(
    api.assets.update
  );

  const _update = useCallback(async () => {
    if (!asset || !asset.id) {
      return;
    }

    const variables: MutationUpdateAssetArgs = {
      assetId: asset.id,
      name: name || asset?.name,
      symbol: name || asset?.symbol,
      imageUrl: null || asset?.imageUrl,
      description: null || asset?.description,
      type: type || asset?.type,
      contractAddress: contractAddress || asset?.contractAddress,
      coingeckoId: coinGeckoTokenId || asset.coinGeckoTokenId,
    };

    await updateAsset({
      variables,
      refetchQueries: [api.clients.assets],
    });

    handleHide();

    if (onUpdate) {
      onUpdate(asset);
    }
  }, [
    name,
    type,
    updateAsset,
    asset,
    contractAddress,
    coinGeckoTokenId,
    onUpdate,
  ]);

  const _create = useCallback(async () => {
    if (!clientId) {
      return;
    }

    const variables: MutationCreateAssetV2Args = {
      name,
      symbol: name,
      imageUrl: null,
      description: null,
      provider: provider,
      contractAddress,
      coinGeckoTokenId,
      clientId,
      type: type || null,
    };

    const asset = await createAsset({
      variables,
      refetchQueries: [api.clients.assets],
    });

    handleHide();

    if (onCreate) {
      onCreate(asset.data?.createAssetV2 ?? null);
    }
  }, [clientId, name, type, createAsset, provider, contractAddress, onCreate]);

  const _onSubmit = useCallback(
    async (e?: any) => {
      try {
        e?.preventDefault();

        if (asset && asset.id) {
          await _update();
        } else {
          await _create();
        }

        handleHide();
      } catch (err) {
        toast.show({
          message: (err as Error).message || "An error occurred.",
          status: "error",
        });
      }
    },
    [_update, _create]
  );

  // const results = searchData?.searchTrackedAssets || [];

  const disabled = name.trim().length === 0 || !type;

  return (
    <Modal
      title={`${asset ? "Update" : "Create"} Asset`}
      isVisible={isVisible}
      handleHide={handleHide}
      Footer={
        <Button
          width="100%"
          type="submit"
          variant="primary"
          onClick={_onSubmit}
          isLoading={loadingCreate || loadingUpdate}
          disabled={disabled}
        >
          {mode === "creating" ? "Create" : "Update"} Asset
        </Button>
      }
    >
      <form onSubmit={_onSubmit}>
        <Container padding="0px" marginTop="0px !important">
          <Select
            label="Blockchain/Exchange"
            selectProps={{
              value: provider
                ? { label: Helpers.capitalize(provider), value: provider }
                : null,
              isDisabled: true,
              isClearable: true,
              isLoading: false,
              components: {
                Option: CustomOption as any,
              },
            }}
            options={INTEGRATIONS.map((o) => ({
              label: o.name,
              value: o.provider,
              integration: o,
            }))}
            isRequired
          />

          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            placeholder="Ex. ETH"
            isRequired
          />

          <Select
            name="type"
            options={ASSET_OPTIONS}
            label="Asset Type"
            value={ASSET_OPTIONS.find((o) => o.value === type)}
            selectProps={{
              onChange: (o) =>
                setType(((o as Option)?.value as AssetTypeEnum) || null),
            }}
            isRequired
          />

          {type === AssetTypeEnum.FungibleToken && (
            <Input
              value={contractAddress}
              onChange={(e) => setContractAddress(e.target.value)}
              label="Contract Address"
              subtitle="If this is a blockchain asset, paste the contract address (EVM) or the mint address (Solana)."
              placeholder=""
            />
          )}

          {type === AssetTypeEnum.FungibleToken && (
            <Input
              value={coinGeckoTokenId}
              onChange={(e) => setCoingeckoId(e.target.value)}
              label="Coingecko Token ID"
              subtitle={
                <>
                  Find the asset on Coingecko, and copy the "API ID" field.{" "}
                  <br />
                  <a
                    style={{ color: colors.primary, fontWeight: "bold" }}
                    href={
                      coinGeckoTokenId
                        ? `https://coingecko.com/en/coins/${coinGeckoTokenId}`
                        : "https://coingecko.com"
                    }
                    target="_blank"
                  >
                    Open Coingecko <i className="fas fa-external-link-alt" />
                  </a>
                </>
              }
              placeholder=""
            />
          )}
        </Container>
      </form>
    </Modal>
  );
}

const CustomOption = ({
  innerRef,
  innerProps,
  data,
}: OptionProps<Option & { integration: IntegrationProviderInfo }>) => (
  <div ref={innerRef} {...innerProps}>
    <HStack
      margin="0"
      bg={colors.white}
      cursor="pointer"
      padding="0.5rem 1rem"
      _hover={{
        bg: colors.gray90,
      }}
    >
      <Text marginRight="1rem !important" flex={1}>
        {data.integration?.name}
      </Text>
      <img
        style={{
          width: "2rem",
          height: "auto",
          borderRadius: "100%",
        }}
        src={data.integration?.logoUrl || ""}
        alt={data.integration?.name}
      />
    </HStack>
  </div>
);

export const AssetModal = connectModal({
  name: "AssetModal",
})(_AssetModal);
