import { Container, Text } from "@chakra-ui/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Modal } from "src/components/Modal";
import { connectModal, InjectedProps } from "redux-modal";
import { useMutation } from "@apollo/client";
import { api } from "src/api";
import { useMe, useMyToast } from "src/hooks";
import { auth } from "src/utils/firebase";
import {
  MultiFactorError,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorSession,
  MultiFactorInfo,
  PhoneInfoOptions,
} from "firebase/auth";
import { Button, Input } from "src/components/styled";
import { E164Number, parsePhoneNumberFromString } from "libphonenumber-js";
import { Maybe } from "src/core";
import { colors } from "src/theme";
import { FirebaseError } from "firebase/app";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

type Props = InjectedProps & {
  flowType: "sign_in" | "enroll";
  multiFactorError?: MultiFactorError;
  onSuccess?: (props: InjectedProps) => Promise<void>;
  session: MultiFactorSession;
  multiFactorHint: MultiFactorInfo;
};

function _PhoneVerificationModal(props: Props) {
  const {
    handleHide,
    show: isVisible,
    flowType,
    multiFactorError,
    onSuccess,
    session: _session,
    multiFactorHint,
  } = props;

  const [phoneNumber, setPhoneNumber] = useState<E164Number | null>(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [step, setStep] = useState<"phone" | "code">(
    multiFactorHint ? "code" : "phone"
  );
  const toast = useMyToast();
  const [_recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);

  const _onSubmit = async function () {
    try {
      if (step === "phone") {
        await _sendCode();
        return;
      }

      if (step === "code") {
        await _verifyCode();

        console.log(props.onSuccess);

        // Note: the on success is responsible
        // for closing the modal. this is bc the on success may have a navigate()
        // and a navigate will need to close the modal before progressing
        if (props.onSuccess) {
          return await props.onSuccess(props);
        }

        handleHide();
        return;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const _sendCode = async () => {
    try {
      // use firebase to add a 2 factor auth method to the user
      let recaptcha: Maybe<RecaptchaVerifier> = _recaptcha;

      if (!recaptcha) {
        recaptcha = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          },
          auth
        );
        setRecaptcha(recaptcha);
      }

      if (!recaptcha) {
        console.log("No recaptcha");
        return;
      }

      const user = auth.currentUser;

      const session =
        _session || (user ? await multiFactor(user).getSession() : null);

      if (!session) {
        console.log("No session");
        toast.show({
          message:
            "Session is missing. Please refresh and try again, and if the problem persists, contact support.",
          status: "error",
        });
        return;
      }

      if (multiFactorHint) {
        const phoneInfoOptions: PhoneInfoOptions = {
          multiFactorHint: multiFactorHint,
          session: session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        // Send SMS verification code.
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptcha
        );

        setVerificationId(verificationId);
        setStep("code");

        return;
      }

      if (!phoneNumber) {
        // toast log it
        toast.show({
          message: "Please enter a valid phone number.",
          status: "error",
        });
        return;
      }

      const formattedNumber = phoneNumber;

      const phoneInfoOptions: PhoneInfoOptions = {
        phoneNumber: formattedNumber,
        session: session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      // Send SMS verification code.
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptcha
      );

      setVerificationId(verificationId);
      setStep("code");
    } catch (err) {
      console.log(err);

      if (err instanceof FirebaseError) {
        if (err.code === "auth/too-many-requests") {
          toast.show({
            message: "Too many requests. Please try again later.",
            status: "error",
          });
          return;
        }
      }

      toast.show({
        message: (err as any)?.message || "Invalid phone number.",
        status: "error",
      });
    }
  };

  const _verifyCode = async () => {
    try {
      const user = auth.currentUser;

      if (!verificationId) return;
      if (!verificationCode) return;

      // Ask user for the verification code. Then:
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      if (flowType === "enroll") {
        if (!user) {
          toast.show({
            message:
              "User is missing. Please refresh and try again, and if the problem persists, contact support.",
            status: "error",
          });
          return;
        }

        const endingDigits = phoneNumber?.slice(-4) || "";

        await multiFactor(user).enroll(
          multiFactorAssertion,
          `Phone ending in ${endingDigits}`
        );
        console.log("[enrolled 2 factor]");

        return;
      }

      if (flowType === "sign_in" && multiFactorError) {
        const multiFactorResolver = getMultiFactorResolver(
          auth,
          multiFactorError
        );

        await multiFactorResolver.resolveSignIn(multiFactorAssertion);

        console.log("[signed in 2 factor]");

        return;
      }

      console.log("unhandled: ", flowType);
    } catch (err) {
      console.log(err);

      if (err instanceof FirebaseError) {
        console.log(err.message);
        console.log(err.code);
        if (err.code === "auth/code-expired") {
          toast.show({
            message: "Code expired. Please try again.",
            status: "error",
          });
          return;
        }
      }

      toast.show({
        message: (err as any)?.message || "Invalid verification code.",
        status: "error",
      });
      throw err;
    }
  };

  const _onChange = (number: string) => {
    const parsedNumber = `+${number}`;

    setPhoneNumber(parsedNumber);
  };

  useEffect(() => {
    if (!multiFactorHint) return;
    // hack to wait for render so the recaptcha can load
    setTimeout(() => _sendCode(), 200);
  }, [multiFactorHint]);

  return (
    <Modal
      isVisible={isVisible}
      handleHide={handleHide}
      minH="35vh"
      Footer={
        <Button
          width="100%"
          variant="primary"
          onClick={_onSubmit}
          style={{ marginBottom: "2rem" }}
        >
          {step === "phone" ? "Send Code" : "Verify Code"}
        </Button>
      }
    >
      <Container padding="3rem 0 1rem 0" marginTop="0px !important">
        <Text fontSize="lg" fontWeight="bold" marginBottom="0">
          {flowType === "enroll"
            ? "Enroll"
            : flowType === "sign_in"
            ? "Sign in with"
            : ""}{" "}
          Two-Factor Authentication
        </Text>

        <br />

        {multiFactorHint && (
          <Text
            style={{
              marginBottom: 10,
            }}
          >
            We sent a code to {multiFactorHint.displayName?.toLowerCase()}
          </Text>
        )}

        {step === "phone" && (
          <PhoneInput
            priority={{ us: 0 }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            placeholder="Enter phone number"
            enableSearch={true}
            onlyCountries={["us", "mx", "ca", "uk"]}
            preferredCountries={["us", "mx", "ca", "uk"]}
            country="us"
            dropdownStyle={{
              maxHeight: 150,
            }}
            value={phoneNumber ?? undefined}
            onChange={(e, data) => {
              console.log(e, data);
              _onChange(e ?? null);
            }}
          />
        )}

        {step === "code" && (
          <>
            <Input
              value={verificationCode}
              autoFocus
              onChange={(e) => setVerificationCode(e.target.value)}
              // label="Client Full Name"
              placeholder="Enter your verification code"
            />

            {/* text to resend the code */}

            <Button
              onClick={_sendCode}
              style={{
                cursor: "pointer",
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              Resend code
            </Button>
          </>
        )}

        <div id="recaptcha-container" />
      </Container>
    </Modal>
  );
}

export const PhoneVerificationModal = connectModal({
  name: "PhoneVerificationModal",
})(_PhoneVerificationModal);
