import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { colors } from "src/theme";
import { isMobile } from "react-device-detect";
import { useTheme } from "src/hooks/useTheme";

type Props = {
  style?: any;
  message: string | JSX.Element;
  trigger?: string;
};

const Info = ({ trigger, style = {}, message }: Props) => {
  const { medBackground, text } = useTheme();

  return (
    <Tooltip
      zIndex={1401}
      trigger={trigger || (isMobile ? "click" : "hover")}
      bg={colors.gray05}
      placement="top"
      borderRadius="0.25rem"
      label={message}
      padding={{ base: "0.5rem 1rem" }}
    >
      <i
        className="fa-sharp fa-info-circle"
        style={{
          position: "relative",
          top: 0,
          left: 4,
          color: text,
          display: "inline-block",
          ...style,
        }}
      />
    </Tooltip>
  );
};

export { Info };
