import React from "react";
import { BaseLedgerEntryFullFields } from "src/api";
import {
  BaseAccountFields,
  BaseClientFields,
  BaseFullTransactionFields,
} from "src/api/fragments";
import {
  TransferBreakdown,
  TransferBreakdownInfo,
} from "src/components/modals/TxnDetailModal/Transfers/TransferForm/utils";
import { Maybe } from "src/core";

type ActiveTransactionContext = {
  transaction: Maybe<BaseFullTransactionFields>;
  client: Maybe<BaseClientFields>;
  transfers: Maybe<TransferBreakdown>;
  // accounts: BaseAccountFields[];
  showAssetTransfersBuilder: boolean;
  ledgerEntries: BaseLedgerEntryFullFields[];
};

export const ActiveTransactionContext =
  React.createContext<ActiveTransactionContext>({
    transaction: null,
    transfers: null,
    // accounts: [],
    client: null,
    showAssetTransfersBuilder: false,
    ledgerEntries: [],
  });
