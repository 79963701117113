import {
  Text,
  Heading,
  Box,
  HStack,
  Flex,
  Link,
  VStack,
  useClipboard,
  Image,
  Grid,
  GridItem,
  ButtonGroup,
  Icon,
} from "@chakra-ui/react";
import WhiteBox from "src/components/styled/WhiteBox";
import { Link as ReactRouterLink, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useNavigate, useParams } from "react-router-dom";
import { useClientById, useMe, useMyToast } from "src/hooks";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
  BaseClientFields,
  BaseUserFields,
} from "src/api/fragments";
import { compose, groupBy, isNil } from "lodash/fp";
import truncateMiddle from "truncate-middle";
import { Touchable } from "src/components/Touchable";
import { ActionSheet, AwakenTooltip, Button, Copy, Info } from "src/components";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { api, apolloClient } from "src/api";
import { colors, other } from "src/theme";
import Loading from "src/views/Loading";
import React, { useEffect, useMemo, useState } from "react";
import {
  AccountIntegrationStatusEnum,
  AccountStatusEnum,
  ImportTypeEnum,
  Maybe,
  Mutation,
  PortfolioResponse,
  Query,
} from "src/api/generated/types";
import StatusTag from "src/components/styled/StatusTag";
import { truncate } from "lodash";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import { isBrowser, isMobile } from "react-device-detect";
import { Metrics } from "./metrics";
import { fork } from "radash";
import { CogsAnimation } from "src/components/CogsAnimation";
import _ from "lodash";
import { hasValue } from "src/core";
import { AddAccount } from "src/components/Accounts";
import { ReferralProgram } from "./ReferralProgram";
import { AccountsGroupedRow } from "./AccountsGrouped";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { shouldReconnect } from "./utils";
import { Warning } from "src/components/Warning";
import { AccountsContext } from "./context";
import { useTheme } from "src/hooks/useTheme";
import { GMEDiscountButton } from "./GMEDiscountButton";

const MAX_ACCOUNTS_ALLOWED_FOR_POLLING = 250;

import CoinbaseLogo from "src/assets/awaken/logos/coinbase.png";
import { config } from "src/config";
import { client } from "src/api/axios";
import { FreeCreditBadge } from "../PortfolioV2/components/Summary";

function Accounts() {
  const navigate = useNavigate();
  const { clientId } = useParams<{ clientId: string }>();
  const toast = useMyToast();
  const dispatch = useDispatch();
  const showModal = compose(dispatch, show);
  const [params] = useSearchParams();
  const triggerAccountModal = params.get("showAddAccountModal") === "true";
  const { client } = useClientById(clientId, {
    onlyFetchClient: true,
  });
  const [accountsToGroup, setAccountsToGroup] = useState<BaseAccountFields[]>(
    []
  );
  const [groupModeOn, setGroupModeOn] = useState<boolean>(false);
  const { header, background, text } = useTheme();
  // every 5 seconds -> if have a lot of accounts tho we change this
  const [pollInterval, setPollInterval] = useState<number | null>(5000);

  const { accounts, isLoadingAccounts } = useClientById(clientId, {
    // poll accounts every 5 seconds for updates
    pollAccountsMs: pollInterval,
    accountFetchPolicy: "cache-first",
    skipFetchAssetsOnLoad: true,
  });

  const [checkMagicEdenEligibility, { data: magicEdenData }] = useMutation<
    Pick<Mutation, "checkMagicEdenDiscount">
  >(api.users.clients.discounts.magicEden.check);

  const isEligibleForMagicEden =
    magicEdenData?.checkMagicEdenDiscount?.isEligible || false;
  const eligibleMagicEdenAccountIds =
    magicEdenData?.checkMagicEdenDiscount?.accountIds || [];

  // don't auto refresh if they have too many accounts
  useEffect(() => {
    if (!accounts.length) return;
    if (accounts.length > MAX_ACCOUNTS_ALLOWED_FOR_POLLING) {
      setPollInterval(null);
      return;
    }
  }, [accounts.length]);

  const [deleteClient] = useMutation(api.clients.delete);
  const [updateOutOfDate] = useMutation(api.accounts.updateOutOfDate);

  const { data: meData, refetch: refetchMe } = useQuery<Pick<Query, "me">>(
    api.users.me,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: clientData, refetch: refetchClient } = useQuery<
    Pick<Query, "getClientById">
  >(api.clients.retrieve, {
    variables: {
      clientId,
    },
    fetchPolicy: "cache-and-network",
    skip: !clientId,
  });

  const me = useMemo(() => meData?.me || null, [meData]);

  useEffect(() => void refetchMe(), []);
  useEffect(() => void refetchClient(), [clientId]);

  useEffect(() => {
    if (!triggerAccountModal) return;
    showModal("AccountModal", {
      location: "Accounts",
    });
  }, [triggerAccountModal]);

  const isSuperUser = me?.isSuperuser || false;

  const _deleteClient = async () => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await deleteClient({
          variables: { clientId },
          refetchQueries: [api.clients.list],
        });
        navigate("/dashboard");
      } catch (err) {
        toast.show({
          status: "error",
          message: (err as Error).message || "Couldn't delete client.",
        });
      }
    }
  };

  const _updateOutOfDate = async () => {
    try {
      await updateOutOfDate({
        variables: { clientId },
        refetchQueries: [api.clients.list],
      });

      toast.show({
        status: "success",
        message:
          "Successfully started update for all accounts. This may take a few minutes.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: (err as Error).message || "Couldn't delete client.",
      });
    }
  };

  const _onLeaveFeedback = () => showModal("FeedbackModal");

  const [normalAccounts, virtualAccounts] = useMemo(() => {
    const [normalAccounts, virtualAccounts] = fork(
      accounts.filter(hasValue),
      (a) => a?.importType !== ImportTypeEnum.VirtualAccount
    );
    const normalAccountsByWallet = groupBy(
      (a) =>
        a.accountGroupId ||
        (a.importType === ImportTypeEnum.Address ? a.walletAddress : a.id),
      normalAccounts
    );

    return [Object.values(normalAccountsByWallet), virtualAccounts];
  }, [accounts]);

  const _onAddWallet = () => {
    showModal("AccountModal", {
      location: "Accounts",
    });
  };

  const [createGroup] = useMutation<Pick<Mutation, "createAccountGroup">>(
    api.accounts.createGroup
  );
  const [updateAccount] = useMutation<Pick<Mutation, "updateAccount">>(
    api.accounts.update
  );

  const _mergeAccounts = () => {
    setGroupModeOn(true);
  };

  const _saveGroup = async () => {
    // create
    if (!accountsToGroup.length) {
      toast.show({
        status: "error",
        message:
          "No accounts selected. Please click an account below to select it.",
      });
      return;
    }

    if (accountsToGroup.length === 1) {
      toast.show({
        status: "error",
        message: "You need to select at least 2 accounts to group them.",
      });
      return;
    }

    try {
      const group = await createGroup({
        variables: {
          clientId,
          name: accountsToGroup[0].name,
        },
      });

      const groupId = group.data?.createAccountGroup.id;

      if (!groupId) {
        toast.show({
          status: "error",
          message: "Couldn't save group.",
        });
        return;
      }

      // update accounts
      for (const account of accountsToGroup) {
        await updateAccount({
          variables: {
            accountId: account.id,
            accountGroupId: groupId,
          },
        });
      }

      setAccountsToGroup([]);
      setGroupModeOn(false);

      // refetch the accounts (they will be grouped)
      apolloClient.refetchQueries({
        include: [api.clients.accounts],
      });
    } catch (err) {
      console.log(err);
      toast.show({
        status: "error",
        message: "Couldn't save group.",
      });
    }
  };

  const isLarge = useIsLargeScreen();
  const accountsToReconnect = Object.values(normalAccounts)
    .flat()
    .filter((a) => shouldReconnect(a));

  useEffect(() => {
    if (!clientId) return;
    void checkMagicEdenEligibility({
      variables: { clientId },
    });
  }, [clientId]);

  // only run this if we are loading accounts and the number of accounts has changed
  if (isLoadingAccounts) {
    return <Loading />;
  }

  // console.log(
  //   "Created At: ",
  //   me?.createdAt,
  //   "\n",
  //   config.coinbaseWalletDiscountDate,
  //   "\n",
  //   me?.createdAt,
  //   config.coinbaseWalletDiscountDate.getTime()
  // );

  // console.log(magicEdenData);

  return (
    <AccountsContext.Provider
      value={{
        client: client,
        accountsToGroup: accountsToGroup,
        setAccounts: setAccountsToGroup,
        groupModeOn: groupModeOn,
        setGroupModeOn: setGroupModeOn,
        isEligibleForMagicEden: isEligibleForMagicEden,
        magicEdenAccountIds: eligibleMagicEdenAccountIds,
      }}
    >
      <Box
        maxWidth={"inherit"}
        bg={background}
        // style={{
        //   padding: "1rem 2.5rem",
        // }}
      >
        <Box w="100%" paddingTop="1rem">
          {/* <FreeCreditBadge /> */}

          <HStack alignItems="center" padding="0.5rem 0">
            <Flex flex={1} alignItems="center" display="flex" flexDir="row">
              <Heading color={header} margin="0" size="lg">
                Accounts
              </Heading>
            </Flex>

            {isBrowser &&
              (groupModeOn ? (
                <>
                  <Touchable
                    label="Cancel"
                    // two squares being combined
                    iconName="fa-sharp fa-times"
                    onClick={() => {
                      setGroupModeOn(false);
                      setAccountsToGroup([]);
                    }}
                  />

                  <Button variant="primary" size="sm" onClick={_saveGroup}>
                    Save Group ({accountsToGroup.length})
                  </Button>
                </>
              ) : (
                <AwakenTooltip message="Group multiple accounts together into one. This will treat all of the transactions/assets for all accounts in the group so it is effectively one account, so use with caution. This can be useful for Bitcoin (you have multiple public keys) or if you have a wallet and added a supplemental CSV that you want to be grouped with it. Do not group two unrelated wallets together, as your taxes will be inaccurate if you do that.">
                  <div style={{ display: "flex" }}>
                    <Touchable
                      label="Group Accounts"
                      // two squares being combined
                      iconName="fa-sharp fa-merge"
                      onClick={_mergeAccounts}
                    />
                  </div>
                </AwakenTooltip>
              ))}

            {isBrowser && (
              <Touchable
                label="Add Account"
                iconName="fa-sharp fa-plus-circle"
                onClick={_onAddWallet}
              />
            )}

            <ActionSheet
              content={{
                maxW: "250px",
              }}
              popover={{ placement: "bottom-end" }}
              commands={[
                {
                  label: "Update Accounts",
                  iconName: "fa-sharp fa-circle-bolt",
                  iconColor: header,
                  color: header,
                  onClick: _updateOutOfDate,
                },
                isSuperUser
                  ? {
                      label: "Delete Client",
                      iconName: "fa-sharp fa-trash",
                      hasDivider: true,
                      iconColor: colors.red,
                      color: colors.red,
                      onClick: _deleteClient,
                    }
                  : null,
              ].filter(hasValue)}
            >
              <Touchable label="More" iconName="fa-sharp fa-ellipsis-v" />
            </ActionSheet>
          </HStack>
          {/* just removing for now. especially as we go more year round don't rlly need this alert here. could be incorporated into the tax flow / check list */}

          {groupModeOn && (
            <Text color={text} fontSize="md" style={{ marginBottom: 25 }}>
              Click on the accounts below to group them{" "}
              <Info message="Grouped accounts will be treated as a single account for tax purposes. This is useful if you want to have multiple accounts (ex. multiple wallets or a wallet + a CSV) be merged into one." />
            </Text>
          )}

          {accountsToReconnect.length > 0 && (
            <ReconnectAccountsWarning accounts={accountsToReconnect} />
          )}
          {/* <DashboardWarning accounts={accounts} /> */}

          <Grid
            marginTop={{ base: "0rem", md: "1rem" }}
            templateColumns={{
              sm: "repeat(1, 1fr)",
              lg: "repeat(2, 1fr)",
              "2xl": "repeat(3, 1fr)",
            }}
            alignItems="center"
            columnGap={2}
            rowGap={2}
          >
            {normalAccounts.map((accounts, i) => (
              <GridItem
                h="100%"
                w="100%"
                colSpan={1}
                key={i}
                // hacky
                maxW={isLarge ? "100%" : "93%"}
              >
                <AccountsGroupedRow accounts={accounts} />
              </GridItem>
            ))}
            <GridItem
              colSpan={1}
              h="100%"
              w="100%"
              // hacky
              maxW={isLarge ? "100%" : "93%"}
            >
              <AddAccount
                numAccounts={normalAccounts.length}
                location="Accounts"
              />
            </GridItem>
          </Grid>
          {/* {virtualAccounts.length > 0 && (
          <Box marginTop="5rem">
            <Flex flex={1} alignItems="center" display="flex" flexDir="row">
              <Heading margin="0" size="md">
                External Positions{" "}
                <Info
                  style={{ fontSize: 16 }}
                  message="These include staking/lending positions."
                />
              </Heading>
            </Flex>
            <Grid
              marginTop="1rem"
              templateColumns={{
                sm: "repeat(1, 1fr)",
                lg: "repeat(2, 1fr)",
                "2xl": "repeat(3, 1fr)",
              }}
              alignItems="flex-start"
              columnGap={2}
              rowGap={2}
            >
              {virtualAccounts.map((a) => (
                <GridItem colSpan={1} key={a.id}>
                  <AccountRow
                    account={a}
                    key={a.id}
                    portfolio={portfolioWithAssetsByAccountId[a.id] || null}
                  />
                </GridItem>
              ))}
            </Grid>
          </Box>
        )} */}
        </Box>

        <Box>
          <ButtonGroup>
            <ClaimCoinbaseDiscount me={me} client={client} />
          </ButtonGroup>
        </Box>

        <VStack
          style={{
            color: colors.gray4,
            top: "2rem",
            position: "relative",
            margin: "1rem 0",
          }}
          alignItems="flex-start"
        >
          <ReactRouterLink
            to="/legal/privacy"
            style={{
              color: colors.gray4,

              alignItems: "flex-start",
              display: "block",
            }}
          >
            <Text fontSize="sm">
              Your privacy is our <u>priority.</u>
            </Text>
          </ReactRouterLink>
          {/* <Text
            onClick={_onLeaveFeedback}
            fontSize="sm"
            style={{ cursor: "pointer" }}
            target={isMobile ? undefined : "_blank"}
          >
            <u>Leave feedback</u>
          </Text>*/}
        </VStack>

        {/* <GMEDiscountButton client={client} /> */}

        <ReferralProgram />
      </Box>
    </AccountsContext.Provider>
  );
}

const ReconnectAccountsWarning = ({
  accounts,
}: {
  accounts: BaseAccountWithCurrentJobFields[];
}) => {
  // if (!accounts.length) return null;

  return (
    <Warning
      margin="1rem 0"
      bg={colors.red100}
      fontWeight="semibold"
      border={`1px solid ${colors.red50}`}
      color={colors.black}
      // connect icon
      iconName="fa-sharp fa-plug"
      iconStyle={{
        marginRight: 5,
        color: colors.red50,
        fontSize: 18,
      }}
      w="100%"
      message={
        <>
          Some of your accounts ({accounts.map((a) => a.description).join(", ")}
          ) need to be reconnected. Please click the "Reconnect" buttons below
          👇
        </>
      }
    />
  );
};

const ClaimCoinbaseDiscount = ({
  me,
  client,
}: {
  me: Maybe<Pick<BaseUserFields, "createdAt">>;
  client: Maybe<Pick<BaseClientFields, "createdAt" | "coinbaseWalletInfo">>;
}) => {
  const dispatch = useDispatch();
  const showModal = compose(dispatch, show);

  const showCoinbaseDiscountModal = () => {
    if (!client) return;
    showModal("CoinbaseDiscountModal", {
      coinbaseWalletInfo: client?.coinbaseWalletInfo || null,
    });
  };

  if (!me?.createdAt || !client?.createdAt) {
    return null;
  }

  if (
    new Date(me.createdAt).getTime() <
    config.coinbaseWalletDiscountDate.getTime()
  ) {
    return null;
  }

  if (
    new Date(client.createdAt).getTime() <
    config.coinbaseWalletDiscountDate.getTime()
  ) {
    return null;
  }

  return null; // for now just don't show it

  return (
    <Button
      onClick={showCoinbaseDiscountModal}
      variant="outline"
      colorScheme="gray"
      marginTop="1rem"
      leftIcon={
        client?.coinbaseWalletInfo?.confirmed ? (
          <i
            className="fa-sharp fa-check-circle"
            style={{
              color: colors.white,
            }}
          />
        ) : (
          <Image src={CoinbaseLogo} w="1rem" h="1rem" />
        )
      }
      bgColor="#0052ff"
      color={colors.white}
      _hover={{
        bgColor: "#0052ff",
        color: colors.white,
      }}
    >
      Claim{client?.coinbaseWalletInfo?.confirmed ? "ed" : ""} Wallet Discount
    </Button>
  );
};

export default Accounts;
