import {
  Heading,
  Table,
  Text,
  HStack,
  Box,
  Image,
  Td,
  ButtonGroup,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Link,
  PopoverArrow,
  Tooltip,
} from "@chakra-ui/react";
import {
  BaseAccountFields,
  BaseAccountWithCurrentJobFields,
  BaseAssetFields,
  BaseUserFields,
} from "src/api/fragments";
import {
  useLocation,
  useNavigate,
  useParams,
  Link as ReactRouterLink,
} from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { api } from "src/api";
import {
  AssetAccountBreakdown,
  AssetTypeEnum,
  AssetWarning,
  CurrencyCodeEnum,
  PortfolioBalance,
  PortfolioBalanceV2,
  PortfolioResponse,
} from "src/api/generated/types";
import _, {
  Dictionary,
  isEmpty,
  keyBy,
  max,
  noop,
  truncate,
  uniq,
} from "lodash";
import { AssetIcon } from "src/components/styled/Assets";
import { colors, other } from "src/theme";
import {
  getHarvestLink,
  getLink,
  getPortfolioLink,
} from "src/modules/ledger/transactions";
import { PROVIDER_TO_LOGO_URL } from "src/components/modals/AccountModal/constants";
import Helpers, { D, formatNum } from "src/utils/helpers";
import numeral from "numeral";
import { Maybe, hasValue } from "src/core";
import { Touchable } from "src/components/Touchable";
import {
  getAssetOnMarketplaceOrCoingecko,
  getAssetUrl,
} from "src/modules/getAssetUrl";
import SmallLogo from "src/assets/awaken/logos/awaken-black.jpeg";
import numbro from "numbro";
import StatusTag from "src/components/styled/StatusTag";
import { NAME_FLEX, VALUE_FLEX } from "./constants";
import { ActionSheet } from "src/components";
import { useMyToast } from "src/hooks";
import {
  getAssetKeyForUrl,
  getBalanceImageInfo,
  getGenericTokenName,
} from "./utils";
import { AccountPosition } from "./AccountPosition";
import { singular } from "pluralize";
import { getImageKitCDNUrl } from "src/utils/imagekit";
import BigNumber from "bignumber.js";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useTheme } from "src/hooks/useTheme";

const MIN_DECIMALS = 8;

const _shouldRenderBreakdown = (b: { amount: number }) =>
  new BigNumber(b.amount).dp(MIN_DECIMALS).gt(0);

export const AssetRow = ({
  portfolioBalance,
  clientId,
  currency,
  warning,
}: {
  portfolioBalance: PortfolioBalanceV2;
  clientId: string;
  currency: CurrencyCodeEnum;
  warning: Maybe<AssetWarning>;
}) => {
  const {
    background,
    text,
    border,
    medBackground,
    secondaryBackground,
    header,
  } = useTheme();

  const assetKey = portfolioBalance.assetPricingKey;
  const assetDetailLink = `${getPortfolioLink(clientId)}/${assetKey}`;

  const tokenName = getGenericTokenName(
    {
      asset: { type: portfolioBalance.type },
      amount: portfolioBalance.totalAmount,
    },
    true
  );
  const provider = portfolioBalance.provider;
  const isNeg = portfolioBalance.dailyFiatAmountCents
    ? portfolioBalance.dailyFiatAmountCents < 0
    : false;
  const color = isNeg ? colors.negative : colors.positive;
  const hasAmount = portfolioBalance.totalAmount > 0;
  const isNFT = portfolioBalance.type === AssetTypeEnum.Nft;

  const isLarge = useIsLargeScreen();

  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          padding: isLarge ? "0rem 1rem" : "0 0.25rem",
          borderRadius: 10,
        }}
        bg={background}
        _hover={{
          backgroundColor: medBackground,
        }}
      >
        <ReactRouterLink
          style={{ flex: 1 }}
          to={assetDetailLink}
          color={colors.black}
        >
          <HStack
            opacity={hasAmount ? 1 : 0.3}
            // borderRadius={7}
            // borderBottom="none !important"
            width="100%"
            padding={isLarge ? "1rem 0.25rem" : "1rem 0rem"}
          >
            <Box
              // style={{ width: isLarge ? 35 : 30 }}
              flexShrink={0}
              marginRight={isLarge ? "0.5rem !important" : "0"}
            >
              <Box
                style={{ position: "relative", display: "flex", flexShrink: 0 }}
              >
                <AssetImage balance={portfolioBalance} />
              </Box>
            </Box>

            <Box
              display="flex"
              style={{
                flex: isLarge ? NAME_FLEX : NAME_FLEX - 1,
              }}
              alignItems="center"
              position="relative"
            >
              <VStack alignItems="flex-start">
                <HStack alignItems="center" role="group">
                  <Text
                    w="100%"
                    fontSize="md"
                    color={header}
                    margin="0 !important"
                    isTruncated
                    fontWeight="semibold"
                    _groupHover={{
                      textDecor: "underline",
                    }}
                  >
                    {truncate(portfolioBalance.name ?? "", {
                      length: isLarge ? 30 : 10,
                    })}{" "}
                    {isNFT && isLarge ? " Collection" : ""}
                  </Text>
                </HStack>

                {/* <AssetDescription breakdown={breakdown} assetById={assetById} /> */}

                <Tooltip
                  label={`${numbro(portfolioBalance.totalAmount ?? 0).format(
                    "0,000.[0000000000000000]"
                  )} ${portfolioBalance.symbol || "Token"}`}
                  openDelay={500}
                >
                  <Text
                    isTruncated
                    fontSize="sm"
                    isNumeric
                    fontWeight="500"
                    color={text}
                    w="100%"
                    whiteSpace="pre-wrap"
                  >
                    {formatNum(
                      portfolioBalance.totalAmount ?? 0,
                      false,
                      "0,0.[0000]"
                    )}{" "}
                    {truncate(portfolioBalance.symbol || "", { length: 10 })}
                  </Text>
                </Tooltip>
              </VStack>
            </Box>

            <Box
              style={{
                flex: VALUE_FLEX,
                maxWidth: 175,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 0,
              }}
            >
              <CurrentValue
                portfolioBalance={portfolioBalance}
                currency={currency}
                isNeg={isNeg}
              />
            </Box>

            <Box
              style={{
                alignItems: "center",
                maxWidth: 175,
                justifyContent: "flex-end",
                display: "flex",
                fontWeight: "500",
                marginLeft: 0,
                flex: VALUE_FLEX,
              }}
            >
              <GainLoss
                portfolioBalance={portfolioBalance}
                currency={currency}
              />
            </Box>
          </HStack>
        </ReactRouterLink>
        {/*<Box
          width="50px"
          marginRight="10px"
          alignItems="flex-end"
          justifyContent="flex-end"
          display="flex"
        >
           <Box
            style={
              {
                //marginLeft: 5
              }
            }
          >
            <MoreAssetInfo
              assetKey={portfolioBalance.assetKey}
              asset={portfolioBalance.asset}
            />
          </Box> 
        </Box>*/}
      </Box>
    </>
  );
};

const AssetImage = ({ balance }: { balance: PortfolioBalanceV2 }) => {
  const isLarge = useIsLargeScreen();

  return (
    <AssetIcon
      textStyle={{ fontSize: 8 }}
      size={isLarge ? 35 : 30}
      style={{ flexShrink: 0 }}
      asset={{
        type: balance.type,
        iconImageUrl: balance.iconImageUrl,
        imageUrl: balance.iconImageUrl,
        symbol: balance.symbol,
      }}
    />
  );
};

const MoreAssetInfo = ({
  asset,
  assetKey,
}: {
  asset: BaseAssetFields;
  assetKey: string;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isOpen, setOpen] = useState(false);
  const contractUrl = asset.blockExplorerUrl;
  const assetInfoUrl = getAssetOnMarketplaceOrCoingecko(asset);
  const toast = useMyToast();
  const [setSpam] = useMutation(api.assets.setAssetSpam);
  // const [updateAsset] = useMutation(api.assets.update);
  const [markWorthless] = useMutation(api.assets.markWorthless);

  const _copyAssetId = () => {
    navigator.clipboard.writeText(asset?.id || "");
    toast.show({ status: "info", message: "Copied asset ID!" });
  };

  const _setAssetAsSpam = async () => {
    try {
      await setSpam({
        variables: { assetId: asset.id, isSpam: true },
        refetchQueries: [api.portfolio.getPortfolioAssets],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as spam!",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const _markWorthless = async () => {
    try {
      await markWorthless({
        variables: {
          assetKey: assetKey,
          isWorthless: !asset.isWorthless,
          clientId,
        },
        refetchQueries: [
          api.portfolio.getPortfolioAssets,
          api.portfolio.getChart,
          api.portfolio.getPortfolioValue,
          api.clients.assets,
        ],
      });
      toast.show({
        status: "success",
        message: "Successfully marked as worthless.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message:
          (err as any)?.message ||
          "Something went wrong, please try again later!",
      });
    }
  };

  const isLarge = useIsLargeScreen();

  if (asset.type === AssetTypeEnum.FiatCurrency) {
    return null;
  }

  return (
    <ActionSheet
      popover={{
        placement: "bottom-end",
        trigger: isLarge ? "hover" : "click",
      }}
      content={{ width: 250 }}
      commands={[
        {
          label: "View in Awaken",
          iconImageSrc: SmallLogo,
          iconImageStyle: { borderRadius: 5 },
          // do the link by the name / symbol bc we group assets across chains in the UI for the asset row
          link: getLink(clientId || "", {
            assetSymbolOrName:
              asset.type === AssetTypeEnum.Nft
                ? asset.name
                : asset.symbol || "",
          }),
        },
        assetInfoUrl
          ? {
              label: "View on " + assetInfoUrl.name,
              // block font awesome icon
              iconName: "fa-sharp fa-external-link-alt",
              link: assetInfoUrl?.url || "",
              iconImageSrc: assetInfoUrl?.imageUrl || "",
            }
          : null,
        contractUrl
          ? {
              label: "Open in block explorer",
              // network of nodes font awesome incon
              iconName: "fa-sharp fa-network-wired",
              link: contractUrl,
            }
          : null,
        {
          label: "Copy asset ID",
          hasDivider: true,
          iconName: "fa-sharp fa-copy",
          onClick: _copyAssetId,
        },
        {
          label: asset.isWorthless
            ? "Mark as not worthless"
            : "Mark as worthless",
          iconName: "fa-sharp fa-empty-set",
          infoMessage:
            "This is useful for tokens that may have been migrated to a new contract. This will only affect your portfolio, and we will show this asset as worth $0 and adjust the historical value to be $0 in the chart.",
          onClick: _markWorthless,
        },
        {
          label: "Mark as spam",
          iconName: "fa-sharp fa-trash",
          iconColor: colors.red50,
          onClick: _setAssetAsSpam,
        },
      ].filter(hasValue)}
    >
      <Touchable
        // open font awesome icon
        iconName="fa-sharp fa-ellipsis-v"
        style={{
          fontSize: 14,
          cursor: "pointer",
        }}
      />
    </ActionSheet>
  );
};

const AssetWarningInfo = ({
  asset,
  warning,
}: {
  asset: BaseAssetFields;
  warning: AssetWarning;
}) => {
  const { clientId } = useParams<{ clientId: string }>();
  const [isOpen, setOpen] = useState(false);
  const assetLabelLink = `/clients/${clientId}/transactions?assetIds=${asset.id}&labeled=false&includeSpam=true`;

  if (!warning) {
    return null;
  }

  return (
    <Popover
      trigger="hover"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      placement="bottom"
      openDelay={0}
    >
      <PopoverTrigger>
        <div style={{ display: "inline-block", margin: "0 15px" }}>
          <StatusTag
            type="warning"
            //   label="Warning"
            onClick={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
            }}
            iconStyle={{ color: colors.yellow40, fontSize: 12 }}
            boxProps={{
              style: {
                cursor: "pointer",
                padding: "4px 8px",
                border: "1px solid " + colors.yellow40,
              },
            }}
            iconName="fa-sharp fa-flag"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent padding="0.5rem 1rem">
        <HStack
          style={{
            padding: "0.25rem",
            width: "100%",
            whiteSpace: "normal",
          }}
        >
          {warning.type === "unlabeled" ? (
            <span>
              {warning.message}
              <hr style={{ margin: "0.5rem 0" }} />
              To get an accurate portfolio, please{" "}
              <Link
                style={{
                  textDecoration: "underline",
                  color: colors.primary,
                  fontWeight: "bold",
                }}
                href={assetLabelLink}
              >
                label the transactions
              </Link>{" "}
              🙏
            </span>
          ) : (
            <span>
              This asset balance has an issue, please contact support and we'll
              help you out!
            </span>
          )}
        </HStack>
      </PopoverContent>
    </Popover>
  );
};

const GainLoss = ({
  portfolioBalance,
  currency,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
}) => {
  const isLarge = useIsLargeScreen();

  if (
    !portfolioBalance.dailyFiatAmountCents ||
    isNaN(portfolioBalance.dailyFiatAmountCents) ||
    !portfolioBalance.hasPrice
  ) {
    return <HStack alignItems="center">—</HStack>;
  }

  const isNeg = portfolioBalance.dailyFiatAmountCents < 0;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <HStack
      alignItems="center"
      style={{ width: "100%", justifyContent: "flex-end" }}
    >
      {isLarge && (
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{
            fontSize: 16,
            color,
          }}
        />
      )}

      <VStack
        alignItems="flex-end"
        style={{
          fontWeight: "500",
          color,
          width: "100%",
          maxWidth: 100,
        }}
      >
        <Text fontWeight="700" fontSize="md" color={color} marginBottom="5px">
          {D(
            Math.abs(portfolioBalance.dailyFiatAmountCents),
            currency
          ).toFormat()}{" "}
        </Text>
        <Text fontSize="md" marginTop="0 !important" style={{ color }}>
          {/* {isNeg ? "-" : ""} */}
          {portfolioBalance.dailyPercentageFormatted}
        </Text>
      </VStack>
    </HStack>
  );
};

const CurrentValue = ({
  portfolioBalance,
  currency,
  isNeg,
}: {
  portfolioBalance: PortfolioBalanceV2;
  currency: CurrencyCodeEnum;
  isNeg: boolean;
}) => {
  const showUnpriced = false;
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();
  const { header, text } = useTheme();

  const [reportUnpricedAsset] = useMutation(api.assets.reportUnpricedAsset);

  const _reportAsset = async () => {
    // try {
    //   await reportUnpricedAsset({
    //     variables: {
    //       assetId: portfolioBalance.asset.id,
    //       chain: portfolioBalance.asset.provider,
    //       contractAddress: portfolioBalance.asset.contractAddress,
    //     },
    //   });
    //   toast.show({
    //     status: "success",
    //     message: "Successfully reported asset!",
    //   });
    // } catch (err) {
    //   toast.show({
    //     status: "error",
    //     message: "Failed to report asset!",
    //   });
    // }
  };

  const color = isNeg ? colors.negative : colors.positive;
  const tokenName = getGenericTokenName(
    {
      asset: { type: portfolioBalance.type || "" },
      amount: portfolioBalance.totalAmount,
    },
    true
  );

  const isNFT = false;

  return (
    <Box>
      <Text
        fontSize="md"
        isNumeric
        style={{
          fontWeight: "500",
        }}
        marginBottom="5px"
        color={header}
      >
        {D(portfolioBalance.totalFiatAmountCents || 0, currency).toFormat()}
      </Text>
      {showUnpriced ? (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Text
              fontSize="md"
              marginTop={0}
              // fontStyle="italic"
              style={{ color: text }}
            >
              Unpriced <i className="fa-sharp fa-question" />
            </Text>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <Box
              style={{
                padding: "1rem",
              }}
            >
              <Text
                style={{
                  whiteSpace: "normal",
                  color: text,
                  fontWeight: "normal",
                }}
                fontSize="md"
              >
                This asset doesn't have a price. Think this is a mistake?{" "}
                <a
                  onClick={_reportAsset}
                  style={{
                    display: "inline",
                    fontWeight: "bold",
                    color: colors.primary,
                  }}
                >
                  Click to report
                </a>{" "}
                and we'll look into it and add pricing data if we find it!
              </Text>
            </Box>
          </PopoverContent>
        </Popover>
      ) : (
        <Text
          fontSize="md"
          marginTop={0}
          isTruncated
          // fontStyle="italic"
          style={{ color: text }}
        >
          {D(portfolioBalance.assetPriceCents || 0, currency).toFormat()}/
          {isNFT
            ? singular(tokenName)
            : portfolioBalance?.symbol?.toUpperCase() || singular(tokenName)}
        </Text>
      )}
    </Box>
  );
};
