import { gql } from "@apollo/client";
import { Maybe } from "src/core";
import {
  Account,
  Client,
  LedgerEntry,
  Transaction,
  RawTransactionAsset,
  Transfer,
  LedgerAccount,
  LedgerReference,
  User,
  Asset,
  ClientPermission,
  PendingUser,
  ReportAccountInfo,
  Job,
  LedgerEntryLink,
  LedgerEntryLinkWithCapacity,
  LedgerEntryWithCapacity,
  Rule,
  Fee,
  Pack,
  DefaultRule,
  Report,
  LedgerTransactionReviewStatusEnum,
  RecalculateSummary,
  RecalculateSummaryBreakdown,
  RecalculateSummarySnapshot,
  RecalculateSummarySnapshotTxn,
  RecalculateSnapshotDiffTxn,
  RecalculateSnapshotDiffTxnPair,
  SpamAsset,
  BookkeepingQuote,
} from "./generated/types";
import { RecalculateSummarySnapshotTransfer } from "./generated/types";
import { RecalculateSummarySnapshotFee } from "./generated/types";

export type BaseClientFields = Pick<
  Client,
  | "__typename"
  | "id"
  | "name"
  | "email"
  | "isLocked"
  | "createdAt"
  | "referredByCode"
  | "referredByName"
  | "referralCode"
  | "costBasisAlgorithm"
  | "availableCreditCents"
  | "profilePicture"
  | "timezone"
  | "accountantNotes"
  | "accountantStatus"
  | "createdById"
  | "status"
  | "recalculateEta"
  | "country"
  | "currency"
  | "lastSyncedAt"
  | "canContinuouslySync"
  | "shouldRecalculate"
  | "referredDomainUrl"
  | "defaultToFloor"
  | "paypalUsername"
  | "referralPercentage"
  | "coinbaseWalletInfo"
  | "hasClaimedMagicEden"
>;

export const BaseClientFields = gql`
  fragment BaseClientFields on Client {
    id
    name
    email
    isLocked
    createdAt
    referredByCode
    referredByName
    referralCode
    costBasisAlgorithm
    availableCreditCents
    profilePicture
    timezone
    accountantNotes
    accountantStatus
    createdById
    status
    recalculateEta
    country
    currency
    lastSyncedAt
    canContinuouslySync
    shouldRecalculate
    referredDomainUrl
    defaultToFloor
    paypalUsername
    referralPercentage
    hasClaimedMagicEden
    coinbaseWalletInfo {
      addresses {
        BTC
        DOGE
        ETH
        LTC
        SOL
      }
      coinbaseID
      confirmed
      url
    }
  }
`;

export type BaseClientPermissionFields = Pick<
  ClientPermission,
  "__typename" | "id" | "clientId" | "pendingUserId" | "userId" | "createdAt"
>;

export const BaseClientPermissionFields = gql`
  fragment BaseClientPermissionFields on ClientPermission {
    id
    clientId
    pendingUserId
    userId
    createdAt
    client {
      ...BaseClientFields
    }
    user {
      ...BaseUserFields
    }
    pendingUser {
      ...BasePendingUserFields
    }
  }
`;

export const BaseRuleFields = gql`
  fragment BaseRuleFields on Rule {
    id
    provider
    type
    label
    clientId
    constraints
    exampleTxnHash
    createdByAwaken
    createdAt
    updatedAt
  }
`;

export type BaseRuleFields = Pick<
  Rule,
  | "__typename"
  | "id"
  | "provider"
  | "type"
  | "label"
  | "constraints"
  | "clientId"
  | "exampleTxnHash"
  | "createdAt"
  | "updatedAt"
  | "createdByAwaken"
>;

export const BaseDefaultRuleFields = gql`
  fragment BaseDefaultRuleFields on DefaultRule {
    id
    provider
    type
    label
    constraints
    exampleTxnHash
    createdAt
    updatedAt
  }
`;

export type BaseDefaultRuleFields = Pick<
  DefaultRule,
  | "__typename"
  | "id"
  | "provider"
  | "type"
  | "label"
  | "constraints"
  | "createdAt"
  | "updatedAt"
>;

export type BaseJobFields = Pick<
  Job,
  | "__typename"
  | "jobId"
  | "status"
  | "createdAt"
  | "completedAt"
  | "type"
  | "logFileObjectKey"
  | "restoreFileObjectKey"
  | "logFileSignedUrl"
  | "restoreFileSignedUrl"
  | "failures"
  | "finishEta"
  | "inngestRunId"
>;

export const BaseJobFields = gql`
  fragment BaseJobFields on Job {
    jobId
    status
    createdAt
    type
    completedAt
    logFileObjectKey
    restoreFileObjectKey
    logFileSignedUrl
    restoreFileSignedUrl
    failures
    finishEta
    inngestRunId
  }
`;

export type BaseRecalculateSummarySnapshotFeeFields = Pick<
  RecalculateSummarySnapshotFee,
  "dedupeUniqueId" | "amount" | "assetId" | "assetSymbolOrName"
>;

export const BaseRecalculateSummarySnapshotFeeFields = gql`
  fragment BaseRecalculateSummarySnapshotFeeFields on RecalculateSummarySnapshotFee {
    dedupeUniqueId
    amount
    assetId
    assetSymbolOrName
  }
`;

export type BaseRecalculateSummarySnapshotTransferFields = Pick<
  RecalculateSummarySnapshotTransfer,
  | "dedupeUniqueId"
  | "amount"
  | "assetId"
  | "assetSymbolOrName"
  | "overrideFiatValue"
  | "overrideBasisFiatValue"
  | "isUserSet"
  | "isUserSetBasisFiatValue"
>;

export const BaseRecalculateSummarySnapshotTransferFields = gql`
  fragment BaseRecalculateSummarySnapshotTransferFields on RecalculateSummarySnapshotTransfer {
    dedupeUniqueId
    amount
    assetId
    assetSymbolOrName
    overrideFiatValue
    overrideBasisFiatValue
    isUserSet
    isUserSetBasisFiatValue
  }
`;

export type BaseRecalculateSummarySnapshotTxnFields = Pick<
  RecalculateSummarySnapshotTxn,
  | "idempotency"
  | "ruleUsedType"
  | "lastModifiedById"
  | "autoReviewReason"
  | "ruleUsedId"
  | "notes"
  | "label"
  | "txnHash"
  | "title"
  | "provider"
  | "incomeSum"
  | "capGainsSumSigned"
  | "deductibleExpensesSum"
  | "createdAt"
  | "reviewStatus"
  | "fees"
  | "transfers"
>;

export const BaseRecalculateSummarySnapshotTxnFields = gql`
  ${BaseRecalculateSummarySnapshotTransferFields}
  ${BaseRecalculateSummarySnapshotFeeFields}
  fragment BaseRecalculateSummarySnapshotTxnFields on RecalculateSummarySnapshotTxn {
    idempotency
    ruleUsedType
    lastModifiedById
    autoReviewReason
    ruleUsedId
    notes
    label
    txnHash
    title
    provider
    incomeSum
    capGainsSumSigned
    deductibleExpensesSum
    createdAt
    reviewStatus
    fees {
      ...BaseRecalculateSummarySnapshotFeeFields
    }
    transfers {
      ...BaseRecalculateSummarySnapshotTransferFields
    }
  }
`;

export type BaseRecalculateSummarySnapshotFields = Pick<
  RecalculateSummarySnapshot,
  "schema" | "transactions"
>;

export const BaseRecalculateSummarySnapshotFields = gql`
  ${BaseRecalculateSummarySnapshotTxnFields}
  fragment BaseRecalculateSummarySnapshotFields on RecalculateSummarySnapshot {
    schema
    transactions {
      ...BaseRecalculateSummarySnapshotTxnFields
    }
  }
`;

export type BaseRecalculateSummaryBreakdownFields = Pick<
  RecalculateSummaryBreakdown,
  | "netIncomeCents"
  | "capGainsTotalCents"
  | "futuresNetCents"
  | "startDate"
  | "endDate"
>;

export const BaseRecalculateSummaryBreakdownFields = gql`
  fragment BaseRecalculateSummaryBreakdownFields on RecalculateSummaryBreakdown {
    netIncomeCents
    capGainsTotalCents
    futuresNetCents
    startDate
    endDate
  }
`;

export type BaseRecalculateSummaryFields = Pick<
  RecalculateSummary,
  | "id"
  | "clientId"
  | "jobId"
  | "usedSpeedup"
  | "snapshot"
  | "breakdowns"
  | "createdAt"
  | "updatedAt"
>;

export const BaseRecalculateSummaryFields = gql`
  ${BaseRecalculateSummarySnapshotFields}
  ${BaseRecalculateSummaryBreakdownFields}
  fragment BaseRecalculateSummaryFields on RecalculateSummary {
    id
    clientId
    usedSpeedup
    snapshot {
      ...BaseRecalculateSummarySnapshotFields
    }
    breakdowns {
      ...BaseRecalculateSummaryBreakdownFields
    }
    createdAt
  }
`;

export type BaseRecalculateSnapshotDiffTxnFields = Pick<
  RecalculateSnapshotDiffTxn,
  | "idempotency"
  | "ruleUsedType"
  | "lastModifiedById"
  | "autoReviewReason"
  | "ruleUsedId"
  | "notes"
  | "label"
  | "txnHash"
  | "title"
  | "provider"
  | "incomeSum"
  | "capGainsSumSigned"
  | "deductibleExpensesSum"
  | "createdAt"
  | "reviewStatus"
  | "fees"
  | "transfers"
  | "lastModifiedByName"
  | "capPriority"
>;

export const BaseRecalculateSnapshotDiffTxnFields = gql`
  ${BaseRecalculateSummarySnapshotTransferFields}
  ${BaseRecalculateSummarySnapshotFeeFields}
  fragment BaseRecalculateSnapshotDiffTxnFields on RecalculateSnapshotDiffTxn {
    idempotency
    ruleUsedType
    lastModifiedById
    autoReviewReason
    ruleUsedId
    notes
    label
    txnHash
    title
    provider
    incomeSum
    capGainsSumSigned
    deductibleExpensesSum
    createdAt
    reviewStatus
    lastModifiedByName
    capPriority
    fees {
      ...BaseRecalculateSummarySnapshotFeeFields
    }
    transfers {
      ...BaseRecalculateSummarySnapshotTransferFields
    }
  }
`;

export type BaseRecalculateSnapshotDiffTxnPairFields = Pick<
  RecalculateSnapshotDiffTxnPair,
  "idempotency" | "createdAt" | "oldTxn" | "newTxn"
>;

export const BaseRecalculateSnapshotDiffTxnPairFields = gql`
  ${BaseRecalculateSnapshotDiffTxnFields}
  fragment BaseRecalculateSnapshotDiffTxnPairFields on RecalculateSnapshotDiffTxnPair {
    idempotency
    createdAt
    oldTxn {
      ...BaseRecalculateSnapshotDiffTxnFields
    }
    newTxn {
      ...BaseRecalculateSnapshotDiffTxnFields
    }
  }
`;

export type BaseAccountFields = Pick<
  Account,
  | "__typename"
  | "id"
  | "name"
  | "walletAddress"
  | "description"
  | "iconImageUrl"
  | "isActive"
  | "source"
  | "status"
  | "type"
  | "referenceId"
  | "importType"
  | "provider"
  | "numberOfTransactions"
  | "numberOfImportedTransactions"
  | "createdAt"
  | "updatedAt"
  | "errorMessage"
  | "blockExplorerUrl"
  | "blockExplorerName"
  | "hatchfiId"
  | "fileObjectKey"
  | "startDate"
  | "endDate"
  | "hasDoneInitialSync"
  | "integrationStatus"
  | "shouldRecommendSyncing"
  | "lastSyncedAt"
  | "integrationOutOfDateMessage"
  | "etaImportFinishedAt"
  | "includeOrdinals"
  | "accountGroupId"
>;

export const BaseAccountFields = gql`
  fragment BaseAccountFields on Account {
    id
    name
    walletAddress
    description
    iconImageUrl
    isActive
    source
    status
    type
    referenceId
    importType
    provider
    numberOfTransactions
    numberOfImportedTransactions
    createdAt
    updatedAt
    errorMessage
    blockExplorerUrl
    blockExplorerName
    hatchfiId
    fileObjectKey
    startDate
    endDate
    hasDoneInitialSync
    integrationStatus
    shouldRecommendSyncing
    lastSyncedAt
    integrationOutOfDateMessage
    etaImportFinishedAt
    includeOrdinals
    accountGroupId
  }
`;

// removed current job
export type BaseAccountWithCurrentJobFields = BaseAccountFields & {
  currentJob: null;
};

export const BaseAccountWithCurrentJobFields = gql`
  ${BaseAccountFields}
  fragment BaseAccountFields on Account {
    ...BaseAccountFields
  }
`;

export type BaseLedgerAccountFields = Pick<
  LedgerAccount,
  | "__typename"
  | "id"
  | "name"
  | "level"
  | "path"
  | "classification"
  | "type"
  | "subType"
  | "isArchived"
>;

export const BaseLedgerAccountFields = gql`
  fragment BaseLedgerAccountFields on LedgerAccount {
    id
    name
    level
    path
    classification
    type
    subType
    isArchived
  }
`;

export type BaseLedgerAccountWithChildrenFields = BaseLedgerAccountFields & {
  children: BaseLedgerAccountWithChildrenFields[];
};

export const BaseLedgerAccountWithChildrenFields = gql`
  ${BaseLedgerAccountFields}
  fragment BaseLedgerAccountWithChildrenFields on LedgerAccount {
    ...BaseLedgerAccountFields
    children {
      ...BaseLedgerAccountFields
      children {
        ...BaseLedgerAccountFields
        children {
          ...BaseLedgerAccountFields
          children {
            ...BaseLedgerAccountFields
            children {
              ...BaseLedgerAccountFields
              children {
                ...BaseLedgerAccountFields
                children {
                  ...BaseLedgerAccountFields
                  children {
                    ...BaseLedgerAccountFields
                    children {
                      ...BaseLedgerAccountFields
                      children {
                        ...BaseLedgerAccountFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type BaseRawTransactionAssetFields = Pick<
  RawTransactionAsset,
  | "__typename"
  | "identifier"
  | "category"
  | "contractAddress"
  | "decimals"
  | "logoUri"
  | "imageUri"
  | "metadataUri"
  | "name"
  | "standard"
  | "symbol"
  | "tokenId"
  | "collectionName"
>;

export const BaseRawTransactionAssetFields = gql`
  fragment BaseRawTransactionAssetFields on RawTransactionAsset {
    identifier
    category
    contractAddress
    decimals
    logoUri
    imageUri
    metadataUri
    name
    standard
    symbol
    tokenId
    collectionName
  }
`;

// in a txn fee or transfer
export type BaseLedgerTransactionEmbeddedLedgerEntryFields = Pick<
  LedgerEntry,
  "id" | "amount" | "fiatAmount"
> & {
  ledgerAccount: BaseLedgerAccountFields;
};

// in a txn fee or transfer
export const BaseLedgerTransactionEmbeddedLedgerEntryFields = gql`
  ${BaseLedgerAccountFields}
  fragment BaseLedgerTransactionEmbeddedLedgerEntryFields on LedgerEntry {
    id
    amount
    fiatAmount
    ledgerAccount {
      ...BaseLedgerAccountFields
    }
  }
`;

export type BaseTransferFields = Pick<
  Transfer,
  | "__typename"
  | "fromAddress"
  | "fromAccountId"
  | "toAddress"
  | "toAccountId"
  | "value"
  | "type"
  | "fromMetadata"
  | "toMetadata"
  | "id"
  | "originalFromAddress"
  | "originalToAddress"
  | "assetId"
  | "estFiatValue"
  | "fromType"
  | "originalAssetId"
  | "price"
  | "toType"
  | "originalFiatValue"
  | "rawAsset"
  // financial fields
  | "assetCreditId"
  | "assetDebitId"
  | "fiatAmountCents"
  | "proceeds"
  | "basis"
  | "profit"
  | "income"
  | "transferCategory"
  | "isMissingBasis"
  | "isUserSet" // fiat value
  | "isUserSetBasisFiatValue" // fiat value
  | "overrideBasisFiatValue" // fiat value
  | "assetDebitFiatAmount"
  | "assetCreditFiatAmount"
  | "fiatCurrency"
  | "isCreatedByUser"
> & {
  toAccount: BaseAccountFields;
  fromAccount: BaseAccountFields;
  fullAsset: BaseAssetFields;
  entries: BaseLedgerTransactionEmbeddedLedgerEntryFields[];
  links: any[];
};

export const BaseTransferFields = gql`
  ${BaseAccountFields}
  ${BaseLedgerAccountFields}
  ${BaseRawTransactionAssetFields}
  ${BaseLedgerTransactionEmbeddedLedgerEntryFields}
  fragment BaseTransferFields on Transfer {
    id
    fromAddress
    fromAccountId
    toAddress
    toAccountId
    toAccount {
      ...BaseAccountFields
    }
    fromAccount {
      ...BaseAccountFields
    }
    fromMetadata {
      accountId
      isAccount
      name
      description
    }
    toMetadata {
      accountId
      isAccount
      name
      description
    }
    value
    type
    originalFromAddress
    originalToAddress
    assetId
    rawAsset {
      ...BaseRawTransactionAssetFields
    }
    fullAsset {
      ...BaseAssetFields
    }
    estFiatValue
    fromType
    originalAssetId
    price
    toType
    originalFiatValue
    entries {
      ...BaseLedgerTransactionEmbeddedLedgerEntryFields
    }
    # TODO:
    links {
      id
    }
    assetCreditId
    assetDebitId
    fiatAmountCents
    assetDebitFiatAmount
    assetCreditFiatAmount
    proceeds
    basis
    profit
    income
    isMissingBasis
    transferCategory
    isUserSet
    isUserSetBasisFiatValue
    overrideBasisFiatValue
    fiatCurrency
    isCreatedByUser
  }
`;

export const BaseAssetFields = gql`
  fragment BaseAssetFields on Asset {
    id
    name
    type
    provider
    coinGeckoTokenId
    symbol
    imageUrl
    createdAt
    identifier
    standard
    metadataUrl
    iconImageUrl
    collectionName
    contractAddress
    tokenId
    description
    blockExplorerUrl
    isWorthless
    isSpam
  }
`;

export type BaseFeeFields = Pick<
  Fee,
  | "__typename"
  | "id"
  | "amount"
  | "paidTxnFee"
  | "assetId"
  | "isWithheldFee"
  | "fromReferenceId"
  | "originalAssetId"
  | "originalFiatValue"
  | "originalFromReferenceId"
  | "payerAccountId"
  // financial fields
  | "assetCreditId"
  | "fiatAmountCents"
  | "proceeds"
  | "basis"
  | "profit"
  | "income"
  | "isMissingBasis"
> & {
  payerAccount: BaseAccountFields;
  fullAsset: BaseAssetFields;
  entries: BaseLedgerTransactionEmbeddedLedgerEntryFields[];
  links: any[];
  rawAsset: BaseRawTransactionAssetFields;
};

export const BaseFeeFields = gql`
  ${BaseAccountFields}
  ${BaseAssetFields}
  ${BaseLedgerTransactionEmbeddedLedgerEntryFields}
  ${BaseRawTransactionAssetFields}
  fragment BaseFeeFields on Fee {
    id
    amount
    paidTxnFee
    assetId
    isWithheldFee
    fromReferenceId
    originalAssetId
    originalFiatValue
    originalFromReferenceId
    payerAccountId
    payerAccount {
      ...BaseAccountFields
    }
    fullAsset {
      ...BaseAssetFields
    }
    rawAsset {
      ...BaseRawTransactionAssetFields
    }
    entries {
      ...BaseLedgerTransactionEmbeddedLedgerEntryFields
    }
    # TODO:
    links {
      id
    }
    assetCreditId
    fiatAmountCents
    proceeds
    basis
    profit
    income
    isMissingBasis
  }
`;

export type BaseUserFields = Pick<
  User,
  | "__typename"
  | "id"
  | "email"
  | "avatarImageUrl"
  | "name"
  | "phoneNumber"
  | "isSuperuser"
  | "createdAt"
  | "updatedAt"
  | "referredByCode"
  | "referredNumberOfFreeTxns"
  | "referredCreditCents"
  | "role"
  | "hasPortfolioEnabled"
  | "intercomUserHash"
  | "hasTwoFactorAuth"
> & {
  activeClient: Pick<Client, "id">;
};

export const BaseUserFields = gql`
  fragment BaseUserFields on User {
    id
    email
    avatarImageUrl
    name
    isSuperuser
    phoneNumber
    activeClient {
      id
    }
    createdAt
    updatedAt
    referredByCode
    referredNumberOfFreeTxns
    referredCreditCents
    role
    hasPortfolioEnabled
    intercomUserHash
    hasTwoFactorAuth
  }
`;

export type BasePendingUserFields = Pick<
  PendingUser,
  "__typename" | "id" | "email" | "createdAt" | "updatedAt"
>;

export const BasePendingUserFields = gql`
  fragment BasePendingUserFields on PendingUser {
    id
    email
    createdAt
    updatedAt
  }
`;

export type BaseAssetFields = Pick<
  Asset,
  | "__typename"
  | "id"
  | "name"
  | "type"
  | "coinGeckoTokenId"
  | "symbol"
  | "imageUrl"
  | "metadataUrl"
  | "provider"
  | "iconImageUrl"
  | "collectionName"
  | "contractAddress"
  | "tokenId"
  | "description"
  | "createdAt"
  | "identifier"
  | "standard"
  | "blockExplorerUrl"
  | "isWorthless"
  | "isSpam"
>;

export type BaseLedgerEntryFields = Pick<
  LedgerEntry,
  | "__typename"
  | "id"
  | "notes"
  | "amount"
  | "direction"
  | "fiatAmount"
  | "fiatCurrency"
  | "classification"
  | "createdAt"
  | "addedAt"
  | "account"
  | "relatedTransferId"
  | "relatedFeeId"
> & {
  ledgerAccount: Maybe<BaseLedgerAccountFields>;
  account: Maybe<BaseLedgerAccountFields>;
  asset: Maybe<BaseAssetFields>;
  transaction: {
    id: string;
    txnHash: Maybe<string>;
    title: Maybe<string>;
    reviewStatus: LedgerTransactionReviewStatusEnum;
  };
};

export const BaseLedgerEntryFields = gql`
  ${BaseLedgerAccountFields}
  ${BaseAssetFields}
  ${BaseAccountFields}
  fragment BaseLedgerEntryFields on LedgerEntry {
    id
    amount
    direction
    fiatAmount
    fiatCurrency
    notes
    createdAt
    addedAt
    classification
    account {
      ...BaseAccountFields
    }
    transaction {
      id
      txnHash
      title
      createdAt
      reviewStatus
    }
    ledgerAccount {
      ...BaseLedgerAccountFields
    }
    asset {
      ...BaseAssetFields
    }
    relatedTransferId
    relatedFeeId
  }
`;

export type BaseFullTransactionFields = Pick<
  Transaction,
  | "__typename"
  | "id"
  | "autoReviewReason"
  | "title"
  | "notes"
  | "txnHash"
  | "type"
  | "provider"
  | "cryptocurrency"
  | "reviewStatus"
  | "clientId"
  | "priceCents"
  | "createdAt"
  | "incomeSum"
  | "assets"
  | "manual"
  | "status"
  | "isBalanced"
  | "labelUsed"
  | "blockExplorerUrl"
  | "blockExplorerName"
  | "isImporting"
  | "isDirty"
  | "isMissingBasis"
  | "needsReview"
  | "probablyWalletTransfer"
  | "formattedFunctionName"
  | "isDirty"
  | "fiatCurrency"
  | "constraints"
  | "processingType"
  | "ruleUsedId"
  | "hasHiddenTransfers"
  | "isHidden"
  | "sourceAccountId"
  | "overrideIncomeCents"
> & {
  ruleUsed: BaseRuleFields;
  transfers: BaseTransferFields[];
  fees: BaseFeeFields[];
};

export const BaseFullTransactionFields = gql`
  ${BaseTransferFields}
  ${BaseFeeFields}
  ${BaseRuleFields}
  fragment BaseFullTransactionFields on Transaction {
    id
    autoReviewReason
    title
    notes
    txnHash
    type
    provider
    cryptocurrency
    reviewStatus
    clientId
    priceCents
    manual
    status
    isBalanced
    incomeSum
    isImporting
    isHidden
    sourceAccountId
    overrideIncomeCents
    needsReview {
      needsRecalculate
      errors
      transferErrors {
        transferId
        error
      }
    }
    formattedFunctionName
    processingType
    transfers {
      ...BaseTransferFields
    }
    fees {
      ...BaseFeeFields
    }
    labelUsed
    ruleUsedId
    ruleUsed {
      ...BaseRuleFields
    }
    isDirty
    isMissingBasis
    constraints
    createdAt
    blockExplorerUrl
    blockExplorerName
    probablyWalletTransfer
    hasHiddenTransfers
    fiatCurrency
  }
`;

export type AccountWithOnlyDescription = {
  description: string;
};

export type BaseSimpleTransactionFields = Pick<
  Transaction,
  | "__typename"
  | "id"
  | "title"
  | "description"
  | "notes"
  | "createdAt"
  | "capPriority"
  | "incomeSum"
  | "reviewStatus"
  | "isImporting"
  | "provider"
  | "isMissingBasis"
  | "capGainsSum"
  | "capGainsSumSigned"
  | "fiatCurrency"
  | "processingType"
  | "isDirty"
  | "needsReview"
  | "ruleUsedId"
  | "labelUsed"
  | "blockExplorerUrl"
> & {
  transfers: {
    toAccount: AccountWithOnlyDescription;
    fromAccount: AccountWithOnlyDescription;
  }[];
  fees: { payerAccount: AccountWithOnlyDescription }[];
};

// export const NeedsReviewFields = gql`
//   fragment NeedsReviewFields on Transaction {
//     needsReview {
//       needsRecalculate
//       errors
//       transferErrors
//     }
//   }
// `;

export const BaseSimpleTransactionFields = gql`
  fragment BaseSimpleTransactionFields on Transaction {
    id
    title
    description
    notes
    blockExplorerUrl
    capPriority
    labelUsed
    reviewStatus
    isImporting
    provider
    incomeSum
    isMissingBasis
    processingType
    isDirty
    ruleUsedId
    needsReview {
      needsRecalculate
      errors
      transferErrors {
        transferId
        error
      }
    }
    transfers {
      fromAccount {
        description
      }
      toAccount {
        description
      }
    }
    fees {
      payerAccount {
        description
      }
    }
    createdAt
    capGainsSum
    capGainsSumSigned
    fiatCurrency
  }
`;

export type BaseLedgerReferenceFields = Pick<
  LedgerReference,
  "__typename" | "id" | "createdAt"
> & {
  from: BaseLedgerEntryFields;
  to: BaseLedgerEntryFields;
};

export const BaseLedgerReferenceFields = gql`
  ${BaseLedgerEntryFields}
  fragment BaseLedgerReferenceFields on LedgerReference {
    id
    from {
      ...BaseLedgerEntryFields
    }
    to {
      ...BaseLedgerEntryFields
    }
    createdAt
  }
`;

export type BaseLedgerEntryLinkFields = Pick<
  LedgerEntryLink,
  | "__typename"
  | "id"
  | "createdAt"
  | "fiatAmountUsed"
  | "cryptoAmountUsed"
  | "type"
  | "from"
  | "to"
> & {
  transaction: Pick<Transaction, "id" | "title" | "createdAt">;
};

export const BaseLedgerEntryLinkFields = gql`
  ${BaseLedgerEntryFields}
  fragment BaseLedgerEntryLinkFields on LedgerEntryLink {
    id
    createdAt
    fiatAmountUsed
    type
    transaction {
      id
      title
      createdAt
    }
    from {
      ...BaseLedgerEntryFields
    }
    to {
      ...BaseLedgerEntryFields
    }
  }
`;

export type BaseLedgerEntryLinkWithCapacityFields = Pick<
  LedgerEntryLinkWithCapacity,
  | "__typename"
  | "id"
  | "createdAt"
  | "cryptoAmountAvailable"
  | "cryptoAmountUsed"
  | "cryptoTotalAmount"
  | "fiatAmountAvailable"
  | "fiatAmountUsed"
  | "fiatAmountTotal"
  | "type"
  | "from"
  | "to"
  | "fiatCurrency"
> & {
  transaction?: Maybe<
    Pick<
      Transaction,
      | "id"
      | "title"
      | "createdAt"
      | "reviewStatus"
      | "capPriority"
      | "needsReview"
      | "txnHash"
    >
  >;
};

export const BaseLedgerEntryLinkWithCapacityFields = gql`
  ${BaseLedgerEntryFields}
  ${BaseLedgerEntryLinkFields}
  fragment BaseLedgerEntryLinkWithCapacityFields on LedgerEntryLinkWithCapacity {
    id
    createdAt
    type
    cryptoAmountAvailable
    cryptoAmountUsed
    cryptoTotalAmount
    fiatAmountAvailable
    fiatAmountUsed
    fiatAmountTotal
    fiatCurrency
    type
    transaction {
      id
      title
      createdAt
      reviewStatus
      capPriority
      txnHash
      needsReview {
        needsRecalculate
        errors
        transferErrors {
          transferId
          error
        }
      }
    }
    from {
      ...BaseLedgerEntryFields
    }
    to {
      ...BaseLedgerEntryFields
    }
  }
`;

type BaseLedgerEntryLinkWithCapacityAndLinksFields =
  BaseLedgerEntryLinkWithCapacityFields & {
    links: BaseLedgerEntryLinkWithCapacityFields[];
  };

export const BaseLedgerEntryLinkWithCapacityAndLinksFields = gql`
  ${BaseLedgerEntryFields}
  ${BaseLedgerEntryLinkWithCapacityFields}
  fragment BaseLedgerEntryLinkWithCapacityAndLinksFields on LedgerEntryLinkWithCapacity {
    ...BaseLedgerEntryLinkWithCapacityFields
    links {
      ...BaseLedgerEntryLinkWithCapacityFields
    }
  }
`;

export type BaseLedgerEntryWithCapacityFields = Pick<
  LedgerEntryWithCapacity,
  | "fiatAmountAvailable"
  | "cryptoAmountAvailable"
  | "cryptoAmountUsed"
  | "cryptoTotalAmount"
  | "fiatAmountTotal"
> & {
  links: BaseLedgerEntryLinkFields[];
  entry: BaseLedgerEntryFields;
};

export const BaseLedgerEntryWithCapacityFields = gql`
  ${BaseLedgerEntryFields}
  fragment BaseLedgerEntryWithCapacityFields on LedgerEntryWithCapacity {
    entry {
      ...BaseLedgerEntryFields
    }
    fiatAmountAvailable
    fiatAmountTotal
    cryptoAmountUsed
    cryptoAmountAvailable
    cryptoTotalAmount
  }
`;

export type BaseReportAccountInfoFields = Pick<
  ReportAccountInfo,
  | "account"
  | "numberOfChildren"
  | "numberOfEntries"
  | "accountBalance"
  | "childrenBalance"
  | "totalBalance"
  | "classification"
>;

export const BaseReportAccountInfoFields = gql`
  ${BaseLedgerAccountFields}
  fragment BaseReportAccountInfoFields on ReportAccountInfo {
    account {
      ...BaseLedgerAccountFields
    }
    numberOfChildren
    numberOfEntries
    accountBalance
    childrenBalance
    totalBalance
    classification
  }
`;

export type BaseReportAccountInfoWithChildrenFields =
  BaseReportAccountInfoFields & {
    children: BaseReportAccountInfoFields[];
  };

export const BaseReportAccountInfoWithChildrenFields = gql`
  ${BaseReportAccountInfoFields}
  fragment BaseReportAccountInfoWithChildrenFields on ReportAccountInfo {
    ...BaseReportAccountInfoFields
    children {
      ...BaseReportAccountInfoFields
      children {
        ...BaseReportAccountInfoFields
        children {
          ...BaseReportAccountInfoFields
          children {
            ...BaseReportAccountInfoFields
            children {
              ...BaseReportAccountInfoFields
              children {
                ...BaseReportAccountInfoFields
                children {
                  ...BaseReportAccountInfoFields
                  children {
                    ...BaseReportAccountInfoFields
                    children {
                      ...BaseReportAccountInfoFields
                      children {
                        ...BaseReportAccountInfoFields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BaseFilterFormCPAFields = gql`
  fragment BaseFilterFormCPAFields on FilterFormCPA {
    id
    isAcceptingIndividuals
    isAcceptingBusinesses
    isAcceptingEth
    isAcceptingEthL2
    isAcceptingOtherChains
    numTxnsLowerBound
    numTxnsUpperBound
    imgUrl
    name
    email
    twitter
    linkedin
    headline
    description
    confirmed
    createdAt
    updatedAt
  }
`;

export type BasePackFields = Pick<Pack, "id">;

export const BasePackFields = gql`
  fragment BasePackFields on Pack {
    id
  }
`;

export type BaseSpamAssetFields = Pick<
  SpamAsset,
  "contractAddress" | "provider" | "exampleTxnHash" | "assetName" | "isSpam"
>;

export const BaseSpamAssetFields = gql`
  fragment BaseSpamAssetFields on SpamAsset {
    contractAddress
    provider
    exampleTxnHash
    assetName
    isSpam
  }
`;

export type BaseReportFields = Pick<
  Report,
  | "id"
  | "fileObjectKey"
  | "title"
  | "createdAt"
  | "startDate"
  | "endDate"
  | "type"
  | "costBasisAlgorithm"
>;

export const BaseReportFields = gql`
  fragment BaseReportFields on Report {
    id
    fileObjectKey
    title
    createdAt
    type
    startDate
    endDate
    costBasisAlgorithm
  }
`;

export type BaseBookkeepingQuoteFields = Pick<
  BookkeepingQuote,
  | "id"
  | "createdAt"
  | "acceptedAt"
  | "clientId"
  | "description"
  | "taxYearsDescription"
  | "monthlyPriceCents"
  | "hasPaid"
  | "partnerEmail"
  | "quoteFileUrl"
  | "rejectedAt"
  | "requestedAt"
  | "sentAt"
  | "status"
  | "updatedAt"
  | "__typename"
  | "upfrontPriceCents"
  | "requestYear"
  | "isBookkeeper" // the requesting user
  | "clientName"
  | "clientEmail"
  | "stripeBookkeepingCheckoutSessionUrl"
>;

export const BaseBookkeepingQuoteFields = gql`
  fragment BaseBookkeepingQuoteFields on BookkeepingQuote {
    id
    createdAt
    acceptedAt
    clientId
    description
    taxYearsDescription
    monthlyPriceCents
    hasPaid
    partnerEmail
    quoteFileUrl
    rejectedAt
    requestedAt
    sentAt
    status
    updatedAt
    upfrontPriceCents
    requestYear
    isBookkeeper
    clientName
    clientEmail
    stripeBookkeepingCheckoutSessionUrl
  }
`;
