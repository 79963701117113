import {
  Text,
  Heading,
  Box,
  HStack,
  Flex,
  VStack,
  Button,
} from "@chakra-ui/react";
import WhiteBox from "src/components/styled/WhiteBox";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { useNavigate, useParams } from "react-router-dom";
import { useClientById, useMe, useMyToast } from "src/hooks";
import {
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { api } from "src/api";
import { colors, other } from "src/theme";
import Loading from "src/views/Loading";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MutationRefreshPortfolioArgs, Query } from "src/api/generated/types";
import _, { isNil } from "lodash";
import { NetWorth } from "./Networth";
import Assets from "./components/Assets";
import { Warning } from "src/components/Warning";
import { compose } from "lodash/fp";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { useNFTPortfolio } from "./useNFTPortfolio";
import { usePortfolio } from "./usePortfolio";
import { useInterval } from "src/hooks/common";
import BigNumber from "bignumber.js";
import { PortfolioContext } from "./context";
import { useTheme } from "src/hooks/useTheme";

const REFRESH_INTERVAL = 15 * 1000;

function PortfolioV2() {
  const apolloClient = useApolloClient();
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClientById(clientId);
  const activeClientId = client?.id || "";

  const { background, theme } = useTheme();

  const [refreshPortfolioBalances] = useMutation(api.portfolio.refreshBalances);
  const [isLoadingInitialCoins, setIsLoadingInitialCoins] =
    React.useState(true);
  const [isLoadingInitialNFTs, setIsLoadingInitialNFTs] = React.useState(true);

  const [activeTab, _setActiveTab] = React.useState<"coins" | "defi" | "nfts">(
    "coins"
  );
  const [isRefreshing, setIsRefreshing] = useState(false);

  const setActiveTab = useCallback((tab: "coins" | "defi" | "nfts") => {
    _setActiveTab(tab);
  }, []);

  const {
    coinsAndDefiData,
    coinsAndDefiError,
    coinsAndDefiNetworkStatus,
    refetchCoinsAndDefiPortfolio,
    balances,
    defiPositions,
    totalDailyPercentageFormatted,
    totalDailyPercentage,
    totalDefiCents,
    totalWalletCents,
    coinTotalValueCents,
    dailyFiatAmountCents,
  } = usePortfolio({ activeClientId, setIsLoadingInitialCoins });

  const {
    refetchNFTPortfolio,
    nftData,
    nftError,
    nftNetworkStatus,
    nftLoading,
    nftTotalValueCents,
  } = useNFTPortfolio({
    activeClientId,
    setIsLoadingInitialNFTs,
  });

  // just NFTs separate from the rest of the portfolio for now
  // bc it is a bit slower
  // const {
  //   data: nftData,
  //   error: nftError,
  //   refetch: _refetchNFTPortfolio,
  // } = useQuery<Pick<Query, "getPortfolioV2">>(api.portfolio.getPortfolioV2, {
  //   skip: !activeClientId,
  //   variables: nftPortfolioVariables,
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted(data) {
  //     setIsLoadingInitial(false);
  //   },
  // });

  const _refetchFullPortfolio = useCallback(async () => {
    void refetchCoinsAndDefiPortfolio();
    void refetchNFTPortfolio();
  }, [refetchNFTPortfolio, refetchCoinsAndDefiPortfolio]);

  const _hardRefresh = async () => {
    if (!activeClientId) return;

    console.log(`[hard refreshing portfolio for ${activeClientId}]`);

    // don't allow using the balance cache
    const refreshVariables: MutationRefreshPortfolioArgs = {
      clientId: activeClientId,
      canUseBalanceCache: false,
      canUsePriceCache: true,
    };

    await refreshPortfolioBalances({
      variables: refreshVariables,
    });
  };

  const _softRefresh = async () => {
    if (!activeClientId) return;

    console.log(
      `[refreshing portfolio for ${activeClientId} ${new Date().toISOString()}]`
    );

    try {
      await _refetchFullPortfolio();
    } catch (err) {
      // FIXME:
    }
  };

  // refreshes portfolio right away and then continuously
  useInterval(() => _softRefresh(), REFRESH_INTERVAL);

  // refresh every 60 seconds
  useEffect(() => {
    if (!activeClientId) return;
    _refetchFullPortfolio();
  }, [activeClientId]);

  const nfts = useMemo(
    () => nftData?.getPortfolioV2?.nfts || [],
    [nftData?.getPortfolioV2?.nfts]
  );

  const totalBalanceCents = useMemo(() => {
    return new BigNumber(coinsAndDefiData?.getPortfolioV2?.valueCents ?? 0)
      .plus(nftData?.getPortfolioV2?.nftTotalValueCents ?? 0)
      .dp(0)
      .toNumber();
  }, [
    coinsAndDefiData?.getPortfolioV2?.valueCents,
    nftData?.getPortfolioV2?.nftTotalValueCents,
  ]);

  // detect how far have scrolled down the page
  // const scrollY = new Animated.Value(0);

  const value = useMemo(
    (): PortfolioContext => ({
      balances: balances,
      totalDailyPercentageFormatted,
      defiPositions: defiPositions,
      totalNftCents: nftTotalValueCents,
      coinTotalValueCents,
      nfts: nfts,
      totalDailyFiatAmount: dailyFiatAmountCents,
      totalDailyPercentage,
      totalBalanceCents: totalBalanceCents,
      totalDefiCents: totalDefiCents,
      totalWalletsCents: totalWalletCents,
      isLoadingCoins:
        (coinsAndDefiNetworkStatus === NetworkStatus.loading &&
          !coinsAndDefiData) ||
        (coinsAndDefiNetworkStatus === NetworkStatus.refetch &&
          !coinsAndDefiData),
      isLoadingNFTs:
        (nftNetworkStatus === NetworkStatus.loading && !nftData) ||
        (nftNetworkStatus === NetworkStatus.refetch && !nftData),
    }),
    [
      balances,
      totalDailyPercentageFormatted,
      defiPositions,
      nftTotalValueCents,
      coinTotalValueCents,
      nfts,
      dailyFiatAmountCents,
      totalDailyPercentage,
      totalBalanceCents,
      totalDefiCents,
      totalWalletCents,
      isLoadingInitialCoins,
      isLoadingInitialNFTs,
      nftNetworkStatus,
      coinsAndDefiNetworkStatus,
      nftLoading,
      nftData,
      coinsAndDefiData,
    ]
  );

  const dailyChange = value.totalDailyFiatAmount;

  const isLarge = useIsLargeScreen();
  const color = useMemo(() => {
    return isNil(dailyChange) || dailyChange === 0
      ? theme === "light"
        ? colors.lightBlue100
        : colors.lightBlue10
      : dailyChange > 0
      ? theme === "light"
        ? colors.green95
        : colors.green10
      : theme === "light"
      ? colors.orange95
      : colors.orange10;
  }, [dailyChange, theme]);

  return (
    <PortfolioContext.Provider value={value}>
      <Box
        style={{
          padding: isLarge ? "2rem 2.5rem" : "4rem 0rem",
          height: "100%",
          // gradient first 10% of page is color and rest is white
          background: `linear-gradient(180deg, ${background} 0%, ${background} 10%)`,
        }}
      >
        <div style={{ width: isLarge ? "100%" : "100%", margin: "auto" }}>
          <NetWorth clientId={clientId!} />
        </div>
      </Box>
    </PortfolioContext.Provider>
  );
}

export default PortfolioV2;
