import { createContext } from "react";
import {
  PortfolioBalanceV2,
  PortfolioCollection,
  PortfolioDefiPosition,
  PortfolioNft,
} from "src/api/generated/types";
import { Maybe } from "src/core";

export type PortfolioContext = {
  balances: PortfolioBalanceV2[];
  defiPositions: PortfolioDefiPosition[];
  nfts: PortfolioNft[];
  totalDefiCents: Maybe<number>;
  totalWalletsCents: Maybe<number>;
  totalNftCents: Maybe<number>;
  coinTotalValueCents: Maybe<number>; // includes defi + wallets
  totalBalanceCents: Maybe<number>;
  totalDailyPercentage: Maybe<number>;
  totalDailyPercentageFormatted: Maybe<string>;
  totalDailyFiatAmount: Maybe<number>;
  isLoadingCoins: boolean;
  isLoadingNFTs: boolean;
};

export const PortfolioContext = createContext<PortfolioContext>({
  balances: [],
  defiPositions: [],
  nfts: [],
  totalBalanceCents: 0,
  totalDefiCents: 0,
  totalNftCents: 0,
  totalWalletsCents: 0,
  coinTotalValueCents: 0,
  totalDailyPercentage: null,
  totalDailyPercentageFormatted: null,
  totalDailyFiatAmount: null,
  isLoadingCoins: false,
  isLoadingNFTs: false,
});
