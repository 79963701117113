import { isNil } from "lodash";
import React, { useContext, useMemo } from "react";
import { PortfolioContext } from "../context";
import AnimatedNumber from "src/components/styled/AnimatedNumber";
import { colors } from "src/theme";
import { D } from "src/utils/helpers";
import { Button } from "src/components";
import { APP_STORE_LINK } from "src/config";
import { trackEvent } from "src/utils/analytics";
import { Link, useParams } from "react-router-dom";
import { useMyToast } from "src/hooks";
import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { Text } from "@chakra-ui/react";
import {
  GetReferralCommissionsResponse,
  Query,
  QueryGetReferralCommissionsArgs,
} from "src/api/generated/types";
import { Maybe } from "src/core";
import { api } from "src/api";
import { useQuery } from "@apollo/client";
import { useTheme } from "src/hooks/useTheme";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";

export const Summary = React.memo(() => {
  const { clientId } = useParams<{ clientId: string }>();
  const {
    totalBalanceCents,
    isLoadingCoins: _isLoadingCoins,
    isLoadingNFTs,
  } = useContext(PortfolioContext);
  const totalValue = totalBalanceCents ?? 0;
  const hasTotalValue = !isNil(totalValue);
  const toast = useMyToast();
  const isLarge = useIsLargeScreen();
  const { theme, header, background } = useTheme();

  const _onClick = async () => {
    try {
      trackEvent("Portfolio Early Access Requested", {
        clientId,
        page: "portfolio_v2",
      });

      window.open(APP_STORE_LINK, "_blank");

      // await requestBeta();

      // toast.show({
      //   status: "success",
      //   message:
      //     "Successfully submitted early access request! You should have an email in your inbox soon.",
      // });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const loading = _isLoadingCoins;

  return (
    <div
      style={{
        marginBottom: 0,
        flexDirection: "row",
        display: "flex",
        width: "100%",
        alignItems: "center",
        background: "transparent",
      }}
    >
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          {/* <Text
            style={{
              fontSize: 24,
              color: colors.black,
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            Portfolio
          </Text> */}
          {loading ? (
            <div
              style={{
                height: 100,
              }}
            />
          ) : (
            <div
              style={{
                minHeight: 100,
                paddingTop: 15,
                alignItems: "center",
              }}
            >
              {/* <FreeCreditBadge /> */}

              {hasTotalValue && (
                <AnimatedNumber
                  style={{
                    color: header,
                    fontSize: 42,
                    fontFamily: "Mona Sans",
                    fontWeight: "800",
                    fontStretch: "extra-expanded",
                  }}
                  valueCents={totalValue ?? 0}
                />
              )}
              {/* <Text style={styles.totalValue}>{D(totalValue).toFormat()}</Text> */}
              {hasTotalValue && <OverallGainLoss />}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          textAlign: "right",
        }}
      >
        {/* <h3
          style={{
            fontFamily: "Mona Sans",
            fontSize: 16,
            color: colors.black,
            marginTop: 5,
            marginBottom: 10,
          }}
        >
          Want to check your portfolio on the go?
        </h3> */}

        {isLarge && (
          <>
            {/* <Button
              variant="primary"
              bgColor={header}
              color={background}
              style={{
                fontWeight: "700",
                marginRight: 10,
                // border: `2px solid ${colors.black}`,
              }}
              _hover={{
                bgColor: header,
                color: background,
              }}
              onClick={_onClick}
            >
              <i className="fab fa-apple" style={{ marginRight: 10 }} />
              Download App
            </Button> */}

            {/* <Link to={`/clients/${clientId}/referrals`}>
              <Button
                variant="primary"
                bgColor={colors.positive}
                color={colors.white}
                style={{
                  fontWeight: "700",
                  border: `2px solid ${colors.positive}`,
                }}
                _hover={{
                  bgColor: colors.positive,
                  color: colors.white,
                }}
              >
                <i className="fa-sharp fa-share" style={{ marginRight: 10 }} /> Refer
                Friends
              </Button>
            </Link> */}
          </>
        )}
      </div>
    </div>
  );
});

export const FreeCreditBadge = () => {
  const { clientId } = useParams();
  const { theme, header } = useTheme();
  const { data, loading } = useQuery<Pick<Query, "getSubscriptions">>(
    api.subscriptions.list,
    {
      variables: { clientId },
      skip: !clientId,
    }
  );

  const subscriptions = data?.getSubscriptions ?? [];
  const showBadge = !!data && subscriptions.length === 0 && !loading;
  const dispatch = useDispatch();

  if (!showBadge) {
    return null;
  }

  return (
    <div
      onClick={() => dispatch(show("ReferralModal"))}
      style={{
        padding: "10px 20px",
        borderRadius: 100,
        marginBottom: 15,
        backgroundColor: theme === "light" ? colors.green100 : colors.green10,
        border: `1px solid ${colors.green50}`,
        display: "inline-block",
        color: colors.black,
        cursor: "pointer",
        fontStretch: "extra-expanded",
      }}
    >
      <Text fontSize="sm" color={header}>
        Want to earn <b>$10</b> of credit?
      </Text>
    </div>
  );
};

const OverallGainLoss = React.memo(() => {
  const { totalDailyFiatAmount, totalDailyPercentageFormatted } =
    useContext(PortfolioContext);

  const { text, theme } = useTheme();
  const dailyGainLoss = totalDailyFiatAmount ?? 0;
  const isNeg = dailyGainLoss < 0 ? true : false;
  const color = isNeg ? colors.negative : colors.positive;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        background: "transparent",
      }}
    >
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          display: "flex",
          marginTop: 5,
          alignItems: "center",
        }}
      >
        {/*  */}
        <i
          className={isNeg ? "fa-sharp fa-caret-down" : "fa-sharp fa-caret-up"}
          style={{ color, marginRight: 5, fontSize: 14 }}
          color={color}
        />
        <h3
          style={{
            color,
            fontSize: 16,
            fontFamily: "Mona Sans",
            fontWeight: "700",
            marginRight: 5,
          }}
        >
          {D(Math.abs(dailyGainLoss)).toFormat()}
        </h3>
        <div
          style={{
            margin: "0 5px",
            borderRadius: 7,
          }}
        >
          <h6
            style={{
              color,
              fontSize: 16,
              fontFamily: "Mona Sans",
              fontWeight: "700",
            }}
          >
            ({totalDailyPercentageFormatted})
          </h6>
        </div>
        <h6
          style={{
            fontSize: 16,
            marginLeft: 5,
            fontFamily: "Mona Sans",
            fontWeight: "normal",
            color: text,
          }}
        >
          Today
        </h6>
      </div>
    </div>
  );
});
