import {
  FetchPolicy,
  FetchResult,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { useCallback, useEffect } from "react";
import {
  api,
  BaseLedgerEntryFullFields,
  BaseTransactionFullFields,
} from "src/api";
import { BaseFullTransactionFields } from "src/api/fragments";
import {
  MutationUpdateTransactionArgs,
  UpdateTransactionResponse,
} from "src/api/generated/types";
import { Maybe } from "src/core";

export type UseTransactionByIdReturn = {
  ledgerEntries: BaseLedgerEntryFullFields[];
  transaction: Maybe<BaseTransactionFullFields>;
  isLoadingTransaction: boolean;
  isLoadingLedgerEntries: boolean;
  getLedgerEntries: () => Promise<void>;
};

type GetTxn = {
  resolver: { getTransaction?: BaseTransactionFullFields };
  variables: { transactionId: string };
};

type FetchEntries = {
  resolver: { getTransactionLedgerEntries?: BaseLedgerEntryFullFields[] };
  variables: { transactionId: string };
};

export const useTransactionById = (
  transactionId: Maybe<string>,
  fetchPolicy: FetchPolicy = "no-cache"
): UseTransactionByIdReturn => {
  const [
    fetchFullTransaction,
    { data: fullTxnData, loading: isLoadingTransaction },
  ] = useLazyQuery<GetTxn["resolver"], GetTxn["variables"]>(
    api.transactions.retrieve,
    {
      fetchPolicy,
    }
  );

  const [
    fetchLedgerEntries,
    { data: ledgerEntryData, loading: isLoadingLedgerEntries },
  ] = useLazyQuery<FetchEntries["resolver"], FetchEntries["variables"]>(
    api.transactions.ledgerEntries,
    {
      fetchPolicy: "no-cache",
    }
  );

  const _fetchFullTxn = useCallback(async () => {
    if (!transactionId) {
      return;
    }

    await fetchFullTransaction({
      variables: {
        transactionId,
      },
    });
  }, [transactionId, fetchFullTransaction]);

  const _fetchLedgerEntries = useCallback(async () => {
    if (!transactionId) {
      return;
    }

    await fetchLedgerEntries({
      variables: {
        transactionId,
      },
    });
  }, [transactionId, fetchLedgerEntries]);

  useEffect(() => void _fetchFullTxn(), [_fetchFullTxn]);

  const ledgerEntries = ledgerEntryData?.getTransactionLedgerEntries || [];
  const transaction = fullTxnData?.getTransaction || null;

  return {
    ledgerEntries,
    transaction,
    isLoadingTransaction: isLoadingTransaction,
    isLoadingLedgerEntries,
    getLedgerEntries: _fetchLedgerEntries,
  };
};
