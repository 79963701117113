import { useIsLargeScreen } from "src/hooks/useScreenSize";
import { colors } from "src/theme";
import Logo from "src/assets/awaken/logos/logo-name.png";
import WhiteLogo from "src/assets/awaken/logos/white-logo.png";

import styled from "styled-components";
import { Touchable } from "./Touchable";
import { Button, useDisclosure } from "@chakra-ui/react";
import { setDrawerIsOpen } from "src/redux/reducers/active";
import { compose } from "lodash/fp";
import { useDispatch } from "react-redux";
import { getMetadataInfo } from "src/utils/whitelabel";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "src/hooks/useTheme";

const HeaderContainer = styled.nav`
  width: 100%;
  z-index: 1000;
  padding: 1rem 0.75rem;
  //   background-color: ${colors.white};
  position: fixed;
  top: 0;
  background-position-y: 0;
  transition: all 0.2s ease-in-out 0s;
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
`;

export const MobileHeader = () => {
  const isLarge = useIsLargeScreen();
  const dispatch = useDispatch();
  const setDrawerOpen = compose(dispatch, setDrawerIsOpen);
  const metadata = getMetadataInfo();
  const [search] = useSearchParams();
  const hideHeader = search.get("hideHeader") === "true";
  const theme = useTheme();

  if (isLarge || hideHeader) return null;

  return (
    <HeaderContainer
      style={{
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${theme.border}`,
      }}
    >
      <div style={{ flex: 1 }}>
        <img
          src={metadata?.logo || (theme.theme === "dark" ? WhiteLogo : Logo)}
          style={{ height: 30, width: "auto" }}
        />
      </div>

      <Button
        bg={"transparent" + " !important"}
        onClick={() => setDrawerOpen(true)}
        size="sm"
      >
        <i style={{ color: theme.header }} className="fa-sharp fa-bars"></i>
      </Button>
    </HeaderContainer>
  );
};
