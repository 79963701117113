import BigNumber from "bignumber.js";
import DineroFactory, { Currency } from "dinero.js";
import numeral from "numeral";
import get from "lodash/get";
import set from "lodash/set";
import BytesHelper from "bytes";
import isString from "lodash/isString";
import pluralize from "pluralize";
import { Maybe } from "src/core";
import { isNil } from "lodash/fp";
import { NetworkStatus } from "@apollo/client";
import { EventHandler } from "react";
import { DateTime } from "luxon";
import { CurrencyCodeEnum } from "src/api/generated/types";
import numbro from "numbro";

export function floor(n: BigNumber, decimals: number): BigNumber {
  return n.decimalPlaces(decimals, BigNumber.ROUND_FLOOR);
}

export const abrNumber = (n: number) => {
  if (n < 1e3) return n.toFixed(0);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export function D(
  cents: Maybe<number>,
  currency?: Currency
): DineroFactory.Dinero {
  return DineroFactory({
    amount: Math.round(cents || 0),
    currency: currency || "USD",
  });
}

export const fromDollars = (
  d: number,
  currency: Currency
): DineroFactory.Dinero => D(Math.round(d * 100), currency);

export const GRADIENT_COLORS = [
  `linear-gradient(45deg, #00b09b, #96c93d)`,
  `linear-gradient(45deg, #fc4a1a, #c471ed, #f64f59)`,
  `linear-gradient(45deg, #8E2DE2, #4A00E0)`,
  `linear-gradient(45deg, #00C9FF, #92FE9D)`,
  `linear-gradient(45deg, #C33764, #1D2671)`,
  `linear-gradient(45deg, #f4c4f3, #fc67fa)`,
  `linear-gradient(45deg, #B24592, #F15F79)`,
];

export default class Helpers {
  static shortId(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static plural(s: string | null): string {
    if (!s) {
      return "";
    }

    return pluralize(s);
  }
  static singular(s: string | null): string {
    if (!s) {
      return "";
    }

    return pluralize.singular(s);
  }
  static formatEIN(s: string | null): string {
    if (!s) {
      return "";
    }
    if (typeof s !== "string") return "";

    if (s.length <= 2) {
      return s;
    }

    return s.slice(0, 2) + "-" + s.slice(2);
  }
  static valueToLabel(s: string | null): string {
    if (!s) {
      return "";
    }
    if (typeof s !== "string") return "";
    return s.replace(/_/g, " ").split(" ").map(this.capitalize).join(" ");
  }
  static capitalize(s: string | null): string {
    if (!s) {
      return "";
    }
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  static formatProvider(s: string | null): string {
    if (!s) {
      return "";
    }
    if (typeof s !== "string") return "";
    const pieces = s
      .split("_")
      .map((c) => (c.toLowerCase() === "us" ? "US" : Helpers.capitalize(c)));

    return pieces.join(" ");
  }
  static capitalizeAllWords(s: string | null): string {
    if (!s) {
      return "";
    }
    if (typeof s !== "string") return "";
    return s.split(" ").map(this.capitalize).join(" ");
  }
  static bytesToReadableString(bytes: number, decimalPlaces = 1): string {
    if (!bytes) {
      return (0).toFixed(decimalPlaces);
    }
    const bytesString = BytesHelper(bytes, {
      decimalPlaces,
    });
    return bytesString && isString(bytesString)
      ? bytesString.toLowerCase()
      : "N/A";
  }
  static nullify(object: any, path: any) {
    const value = get(object, path);
    // If path is undefined, "", or null,
    // set that path on the object to null
    if (!value) {
      set(object, path, null);
    }
  }
  static sortByDescending(a: any, b: any, field = "createdAt"): number {
    return get(a, field) > get(b, field) ? -1 : 1;
  }
  static sortByAscending(a: any, b: any, field = "createdAt"): number {
    return get(a, field) < get(b, field) ? -1 : 1;
  }
  static hashFromString(str: string): number {
    if (!str) {
      return 0;
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }
  static _intToRGB(i: number): string {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  }
  static stringToColor(str: string) {
    return this._intToRGB(this.hashFromString(str));
  }

  static shortenNumWords(
    text: string,
    numberOfWords: number,
    endingString = "."
  ) {
    if (!text) {
      return "";
    }
    const words = text.split(" ");
    return (
      words.slice(0, numberOfWords).join(" ") +
      (numberOfWords < words.length ? endingString : "")
    );
  }

  static shorten(text: string, length: number, characters = ".") {
    if (!text) return "";
    if (text.length > length) {
      // Get first x
      const sliceOfText = text.slice(0, length);
      // trim the text and add characters to it
      text = sliceOfText.trim() + characters;
    }
    return text;
  }

  /**
   * Converts a list to a dictionary
   * @param {Any[]} list A list of items
   * @param {String} uniqueKey A unique key in the items
   */
  static listToDictionary(list: any[], uniqueKey = "_id") {
    const dict: any = {};
    if (!list) return dict;
    for (const item of list) {
      const key = item[uniqueKey];
      if (key) {
        dict[key] = item;
      }
    }
    return dict;
  }
  /**
   * Converts a dictionary to a list
   * @param {Object} dict A dictionary
   */
  static dictionaryToList(dict: Record<string, any>) {
    if (!dict) {
      return [];
    }
    return Object.values(dict);
  }
  /**
   * Returns the dollar with a decimal rounded 2 places.
   *
   * @param {Number} amount The amount in dollars
   * @return {Number} The dollar amount, ie. 4.58
   */
  static toDollar(amount: number): string {
    if (!amount) return "0.00";
    return numbro(amount).format("0.00");
  }

  /**
   * Returns the dollar with a decimal rounded 2 places and a $ on the front.
   *
   * @param {Number} amount The amount in dollars
   * @return {String} The dollar amount, ie. $4.58
   */
  static numberWithCommas(amount: number): string {
    if (!amount) {
      return "0";
    }
    return numbro(amount).format("0,0");
  }

  /**
   * Returns the dollar with a decimal rounded 2 places and a $ on the front.
   *
   * @param {Number} amount The amount in dollars
   * @return {String} The dollar amount, ie. $4.58
   */
  static toDollarString(
    cents?: Maybe<number>,
    currency: Currency = "USD"
  ): string {
    return DineroFactory({ amount: cents || 0, currency }).toFormat();
  }

  /**
   * Returns the percent as a decimal.
   *
   * @param {Number} amount The amount of percent as a whole number
   * @param {Number} numberOfPlaces The amount of places to round
   * @return {Number} The decimal, ie. 0.4565
   */
  static percentToDecimal(amount: number, numberOfPlaces = 6): number {
    numberOfPlaces = 10 ** numberOfPlaces;
    return (amount * 0.01 * numberOfPlaces) / numberOfPlaces;
  }

  /**
   * Returns the decimal as a percent.
   *
   * @param {Number} amount The amount of percent as a decimal 0.4608
   * @param {Number} numberOfPlaces The amount of places to round
   * @return {Number} The decimal, ie. 0.4608
   */
  static decimalToPercent(amount: number, numberOfPlaces = 4): number {
    numberOfPlaces = 10 ** numberOfPlaces;
    return (amount * 100 * numberOfPlaces) / numberOfPlaces;
  }
}

export const cleanObj = (obj: Record<string, any>) => {
  const newObj: Record<string, any> = {};
  Object.entries(obj).forEach(([key, val]) =>
    isNil(obj[key]) ? null : (newObj[key] = val)
  );
  return newObj;
};

export const isLoadingGQL = (networkStatus: NetworkStatus) => {
  return (
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.loading
  );
};

export function isElementInViewport(el: any) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export const allowEnterSubmit: React.KeyboardEventHandler<HTMLFormElement> = (
  e
) => {
  if (e.code === "Enter") {
    return;
  }
  e.preventDefault();
};

const units: Intl.RelativeTimeFormatUnit[] = [
  "year",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
];

export const timeAgo = (dateTime: DateTime) => {
  const _dayNum = DateTime.utc().diff(dateTime, "days").days;

  const daysPassed = Math.floor(_dayNum);
  const hoursPassed = daysPassed * 24;
  const hourNum = Math.floor(
    DateTime.utc().diff(dateTime, "hours").hours - hoursPassed
  );

  if (daysPassed <= 0 && hourNum <= 0) {
    return null;
  }

  if (daysPassed > 0 && hourNum <= 0) {
    return `${daysPassed.toFixed(0)} days ago`;
  }

  if (daysPassed <= 0 && hourNum > 0) {
    return `${hourNum.toFixed(0)} hours ago`;
  }

  return `${daysPassed.toFixed(0)} days ${hourNum.toFixed(0)} hours ago`;
};

// forces a switch to exhaustively handle every case
export const guardSwitch = (val: never): never => {
  throw new Error(`error for value ${val}`);
};

// FIXME: also change this on the backend
export const getCurrencySymbol = (currency: CurrencyCodeEnum) => {
  switch (currency) {
    case CurrencyCodeEnum.Usd:
      return "$";
    case CurrencyCodeEnum.Eur:
      return "€";
    case CurrencyCodeEnum.Gbp:
      return "£";
    case CurrencyCodeEnum.Aud:
      return "A$";
    case CurrencyCodeEnum.Cad:
      return "CA$";
    // case CurrencyCodeEnum.Jpy:
    //   return "¥";
    // case CurrencyCodeEnum.Inr:
    //   return "₹";
    default:
      guardSwitch(currency);
      return "$";
  }
};

export const CURRENCY_REGEX = /[$€£¥CA$A$]/g;

// export const isFiatCurrency = (symbol: string) => {
//   return !!Object.values(CurrencyCodeEnum).find(
//     (s) => s === symbol.toUpperCase()
//   );
// };

export const getEtaMessage = (
  _baseMessage: string | null,
  _finishEta: Maybe<Date>
): string | null => {
  let baseMessage = _baseMessage;

  if (!_finishEta) {
    return baseMessage;
  }

  if (!baseMessage) {
    baseMessage = "";
  } else {
    baseMessage = baseMessage + " ";
  }

  const finishEta = DateTime.fromJSDate(new Date(_finishEta)).toUTC();

  // if now is after the recalculate, don't show the eta anymore
  if (DateTime.utc() > finishEta) {
    return baseMessage;
  }

  const diff = finishEta.toUTC().diff(DateTime.utc());

  const hours = diff.as("hours");
  const minutes = diff.as("minutes");
  const seconds = diff.as("seconds");

  if (hours > 1) {
    const leftOverMinutes = minutes % 60;
    const hoursText = hours - leftOverMinutes / 60;

    return `${baseMessage}${hoursText.toFixed(
      0
    )} hours, ${leftOverMinutes.toFixed(0)} mins`;
  }

  if (minutes > 1) {
    const leftOverSeconds = seconds % 60;
    const minutesText = minutes - leftOverSeconds / 60;

    return `${baseMessage}${minutesText.toFixed(
      0
    )} mins, ${leftOverSeconds.toFixed(0)} secs`;
  }

  return `${baseMessage}${seconds.toFixed(0)} secs`;
};

export const formatNum = (n: number, isDollar = false, format?: string) => {
  if (n < 1e3) {
    return numbro(n).format(format || (isDollar ? "0,000.00" : "0.[00]"));
  }
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};
