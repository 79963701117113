import { BaseTransferFields } from "src/api/fragments";
import { Transfer } from "src/api/generated/types";
import { Maybe } from "src/core";

export type TransferType = "sent" | "received" | "internal" | "none";

export type TransferWithExtraInfo = BaseTransferFields & {
  index: number;
  transferType: Maybe<TransferType>;
};

export type TransferBreakdownInfo = {
  transfers: TransferWithExtraInfo[];
  name: string;
  type: Maybe<TransferType>;
  isInternal: boolean;
  infoMessage?: string;
};

export type TransferBreakdown = {
  sent: TransferBreakdownInfo;
  none: TransferBreakdownInfo;
  received: TransferBreakdownInfo;
  internal: TransferBreakdownInfo;
};

export const useTransfers = (
  transfers: BaseTransferFields[]
): TransferBreakdown => {
  return transfers.reduce<TransferBreakdown>(
    (a, t, i) => {
      if (
        t.transferCategory === "internal" ||
        t.transferCategory === "split_internal"
      ) {
        a.internal.transfers.push({ ...t, index: i, transferType: "internal" });
        return a;
      }

      if (
        t.transferCategory === "received" ||
        t.transferCategory === "received_from_virtual"
      ) {
        a.received.transfers.push({ ...t, index: i, transferType: "received" });
        return a;
      }

      if (
        t.transferCategory === "sent" ||
        t.transferCategory === "sent_to_virtual"
      ) {
        a.sent.transfers.push({ ...t, index: i, transferType: "sent" });
        return a;
      }

      a.none.transfers.push({ ...t, index: i, transferType: "none" });

      return a;
    },
    {
      sent: {
        transfers: [] as TransferWithExtraInfo[],
        isInternal: false,
        name: "Sent Assets",
        type: "sent",
      },
      none: {
        transfers: [] as TransferWithExtraInfo[],
        isInternal: false,
        name: "Editing",
        type: "none",
      },
      received: {
        transfers: [] as TransferWithExtraInfo[],
        isInternal: false,
        name: "Received Assets",
        type: "received",
      },
      internal: {
        transfers: [] as TransferWithExtraInfo[],
        isInternal: true,
        name: "Internal Transfers",
        type: "internal",
        infoMessage:
          "These are transfers between your own wallets, exchanges, and staking/lending positions. Non-taxable.",
      },
    }
  );
};
