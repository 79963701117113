import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { BaseClientFields, BaseFullTransactionFields } from "src/api/fragments";
import { Maybe } from "src/core";
import { ActiveState, AuthStatus, ReduxState, UserState } from "../types";

// initial state
const initialState: ActiveState = {
  client: null,
  highlightedTransactionId: null,
  drawerIsOpen: false,
};

// actions

export const setActiveClient =
  createAction<Maybe<BaseClientFields>>("SET_ACTIVE_CLIENT");

export const setHighlightedTransactionId = createAction<Maybe<string>>(
  "SET_HIGHLIGHTED_TRANSACTION_ID"
);

export const setDrawerIsOpen = createAction<boolean>("SET_DRAWER_IS_OPEN");

// reducer
export const activeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setActiveClient, (state, action) => {
      state.client = action.payload;
    })
    .addCase(setHighlightedTransactionId, (state, action) => {
      state.highlightedTransactionId = action.payload;
    })
    .addCase(setDrawerIsOpen, (state, action) => {
      state.drawerIsOpen = action.payload;
    });
});

// selectors
export const getActive = (state: ReduxState): ActiveState => state.active;

export const getActiveClient = createSelector(
  getActive,
  (active) => active.client
);

export const getActiveHighlightedTransactionId = createSelector(
  getActive,
  (active) => active.highlightedTransactionId
);

export const getDrawerIsOpen = createSelector(
  getActive,
  (active) => active.drawerIsOpen
);
